import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Modal,
  Checkbox,
} from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Redirect } from "react-router-dom";

const { Title } = Typography;

const RegistrationForm = () => {
  const [loading, setLoading] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [redirectToEmail, setRedirectToEmail] = useState(false);

  var [input, setInput] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    referralName: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setInput({ ...input, password: newPassword });
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setInput({ ...input, confirmPassword: newConfirmPassword });
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referralName = params.get("ref_by") || "";

  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const allCheckboxesChecked = checkboxes.every((checked) => checked);

    if (!allCheckboxesChecked) {
      // Display error message or prevent form submission
      toast.error("Please fulfill all password requirements.");
      return;
    }
    setLoading(true);
    setIsConfirmPopupOpen(false);
    const lowerCaseUsername = input.username.toLowerCase();
    console.log(lowerCaseUsername);
    var { referralName, name, username, email, password, confirmPassword } =
      input;
    const formData = new FormData();
    formData.append("Referral_Username", referralName.toLowerCase());
    formData.append("Name", name.toLowerCase());
    formData.append("Username", username.toLowerCase());
    formData.append("Email", email.toLowerCase());
    formData.append("Password", password);
    formData.append("Confirm_Password", confirmPassword);
    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      url: process.env.REACT_APP_API_PATH + "register",

      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res?.Data) {
          // setInput({ ...input, username: lowerCaseUsername });

          setInput(res);
          toast(res?.Data);

          // window.location.href = "/email";
          navigate(`/email?email=${email}&username=${username}`);
        } else {
          toast(res.Error);
        }
      })
      .catch((err) => {
        throw err;
      });
    setLoading(false);
  };

  async function handleusername(e) {
    console.log(e.target.name);
    if (e.target.name == "username") {
      const username = e.target.value.trim().toLowerCase();
      setInput({ ...input, username });

      if (username === "") {
        setUsername("");
        return;
      }

      try {
        const response = await getusername(username);
        setUsername(response.Data);
      } catch (error) {
        throw error;
      }
    }
  }

  async function getusername(username) {
    const formData = new FormData();
    formData.append("Username", username);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_PATH + "check_username",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      toast(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  const [password, setPassword] = useState("");

  useEffect(() => {
    if (referralName) {
      const lowercaseReferralName = referralName.toLowerCase();
      setInput({ ...input, referralName: lowercaseReferralName });
    }
  }, [referralName]);

  useEffect(() => {
    const requirementsMet = [
      password.length >= 8,
      /[a-z]/.test(password),
      /[A-Z]/.test(password),
      /\d/.test(password),
      /[@$!%*?&]/.test(password),
    ];

    setCheckboxes(requirementsMet);
  }, [password]);

  const checkPasswordRequirements = () => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const requirementsMet = [
      password.length >= 8,
      /[a-z]/.test(password),
      /[A-Z]/.test(password),
      /\d/.test(password),
      /[@$!%*?&]/.test(password),
    ];

    return regex.test(password) && requirementsMet.every((req) => req);
  };

  const [checkboxes, setCheckboxes] = useState([
    false, // 8 characters
    false, // 1 lowercase letter
    false, // 1 uppercase letter
    false, // 1 number
    false, // 1 special character
  ]);

  return (
    <>
      <div className="w-screen h-screen flex items-center justify-center bg-gradient">
        <div className="max-w-xl bg-white rounded-lg shadow-lg p-6 w-full bg-wht form-container ">
          <div className="w-8/12 mx-auto">
            {/* <Row className="justify-content-end align-items-center"> */}
            {/* <Col xs={24} md={16}>
          <div className="container111">
            <div class="logo">
              <img src="" alt="Logo" />
            </div>
          </div>
        </Col> */}
            {/* <Col xs={24} md={8}> */}
            <div className="login-register">
              <div className="container111">
                <div></div>
                <Form
                  name="registrationForm"
                  style={{ maxWidth: 500, margin: "0 auto" }}
                >
                  <Title
                    level={2}
                    className="text-center"
                    style={{ color: "#000" }}
                  >
                    Register
                  </Title>
                  <Typography label={3} style={{ color: "#000" }}>
                    NAME :
                  </Typography>
                  <Form.Item
                    name="name"
                    rules={[
                      { required: true, message: "Please enter your name" },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="Name"
                      style={{ width: "100%" }}
                      value={input.name}
                      onChange={(e) =>
                        setInput({ ...input, name: e.target.value })
                      }
                    />
                  </Form.Item>

                  <Typography label={3} style={{ color: "#000" }}>
                    USERNAME :
                  </Typography>
                  <Form.Item
                    name="username"
                    rules={[
                      { required: true, message: "Please enter your username" },
                    ]}
                    className="mb-0"
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="User Name"
                      style={{ width: "100%" }}
                      value={input.username}
                      onChange={(e) => handleusername(e)}
                      name="username"
                    />
                    <span
                      className={
                        username == "Username available!"
                          ? "text-white"
                          : "text-white"
                      }
                    >
                      {input.username}&nbsp;
                      {username}
                    </span>
                  </Form.Item>
                  <Typography label={3} style={{ color: "#000" }}>
                    EMAIL :
                  </Typography>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email" },
                    ]}
                  >
                    <Input
                      prefix={<MailOutlined />}
                      placeholder="Email"
                      style={{ width: "100%" }}
                      value={input.email}
                      onChange={(e) =>
                        setInput({ ...input, email: e.target.value })
                      }
                    />
                  </Form.Item>
                  <Typography label={3} style={{ color: "#000" }}>
                    PASSWORD :
                  </Typography>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: "Please enter your password" },
                      () => ({
                        validator(_, value) {
                          if (checkPasswordRequirements()) {
                            return Promise.resolve();
                          }
                          // return Promise.reject(
                          //   "The password must be at least 8 characters long and contain 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character."
                          // );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      prefix={<LockOutlined />}
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                    />

                    <div className="checkboxess d-flex flex-wrap justify-content-between">
                      {checkboxes.map((checked, index) => (
                        <div key={index}>
                          <Row>
                            <Col xl={24}>
                              <Checkbox
                                className=""
                                checked={checked}
                                disabled
                              />
                              <span style={{ marginLeft: 8, color: "#000" }}>
                                {index === 0 && "8 characters long"}
                                {index === 1 && "1 lowercase letter"}
                                {index === 3 && "1 number digits"}
                                {index === 2 && "1 uppercase letter"}
                                {index === 4 && "1 special character"}
                              </span>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  </Form.Item>
                  <Typography label={3} style={{ color: "#000" }}>
                    CONFIRM PASSWORD :
                  </Typography>
                  <Form.Item
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject("The passwords do not match");
                        },
                      }),
                    ]}
                    validateStatus={passwordMatch ? "" : "error"}
                    help={passwordMatch ? "" : "The passwords do not match"}
                  >
                    <Input.Password
                      prefix={<LockOutlined />}
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                  </Form.Item>

                  {/* <Typography label={3} style={{ color: "#000" }}>
                  REFERRAL NAME :
                </Typography>
                <Form.Item name="referralName" initialValue={referralName}>
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Referral Name"
                    style={{ width: "100%" }}
                    defaultValue={input.referralName || referralName}
                    onChange={(e) =>
                      setInput({ ...input, referralName: e.target.value })
                    }
                    readOnly={referralName !== ""}
                    name="referralName"
                  />
                  <span
                    className={
                      referralName == "ReferralName available!"
                        ? "text-white"
                        : "text-white"
                    }
                  >
                    {input.referralName}&nbsp;
                    {username}
                  </span>
                </Form.Item> */}

                  <Form.Item>
                    <Button
                      className="bg-second text-center w-full mt-5 text-xl h-full"
                      htmlType="submit"
                      block
                      //   className="bg-black text-white mt-4"
                      loading={loading}
                      onClick={handleSubmit}
                    >
                      Register
                    </Button>
                    <div className="flex items-center flex-col">
                      <span className="text-center text-white d-grid mt-2">
                        Already User?
                      </span>

                      <a
                        color="white"
                        className="mt-1 text-white align-items-center justify-content-center d-flex underline"
                        href="/"
                      >
                        login now!
                      </a>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
            {/* </Col> */}
            {/* </Row> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;
