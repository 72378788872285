import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import BasicBot from "./pages/BasicBot";
import SelectBot from "./pages/SelectBot";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Order from "./pages/Order";
import Report from "./pages/Report";
import ProtectedRoute from "./pages/ProtectedRoute";
import Calculator from "./Components/Calculator";
import Addapi from "./pages/Addapi";
import Wrapper from "./Components/Layout/Wrapper";
import SpotBasicbot from "./pages/SpotBasicbot";
import FutureBasicBot from "./pages/FutureBasicBot";

function App () {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        <Route path="/main" element={<ProtectedRoute Component={Wrapper} />}>
          <Route
            exact
            index
             element={<ProtectedRoute Component={SelectBot} />}
          />
          <Route
            path="/main/basic-bot/"
            exact
             element={<ProtectedRoute Component={BasicBot} />}
          />
          <Route
            path="/main/basic-bot/SPOT"
            exact
             element={<ProtectedRoute Component={SpotBasicbot} />}
          />
           <Route
            path="/main/basic-bot/FUTURE"
            exact
             element={<ProtectedRoute Component={FutureBasicBot} />}
          />
          <Route
            path="/main/basic-bot/:coinparams/:tradetypeparams"
            exact
             element={<ProtectedRoute Component={BasicBot} />}
          />
        
          <Route
            path="/main/addapi"
            exact
            element={<ProtectedRoute Component={Addapi} />}
          />
          <Route
            path="/main/order"
            exact
            element={<ProtectedRoute Component={Order} />}
          />
          <Route
            path="/main/report"
            exact
            element={<ProtectedRoute Component={Report} />}
          />
          <Route
            path="/main/calculatornew"
            exact
            element={<ProtectedRoute Component={Calculator} />}
          />
          <Route
            path="/main/calculator"
            exact
          
          />
          <Route
            path="/main/form"
            exact
           
          />

          <Route
            path="/main/form1"
            exact
            
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
