import React, { useEffect, useState } from "react";
import { Button, Tabs } from 'antd';
import Orderopen from "./Orderopen";
import Orderopenspot from "./Orderopenspot";
import Openposition from "./Openposition";
import Openpositionspot from "./Openpositionspot";
function ApplyForm() {
  const [activeTab, setActiveTab] = useState('1'); 
  const [filter, setFilter] = useState('Spot'); 
  const items = [
    {
      key: '1',
      className: 'text-white p-0 m-0',
      label: `Order History`,
      childrenSpot: <Orderopenspot />,
      childrenFuture: <Orderopen />,
    },
    {
      key: '2',
      className: 'text-white',
      label: `Open Position`,
      childrenSpot: <Openpositionspot />,
      childrenFuture: <Openposition />,
    },
  ];
  const onChange = (key) => {
    setActiveTab(key);
  };
  const renderComponent = () => {
    const activeItem = items.find((item) => item.key === activeTab);
    if (filter === 'Spot') {
      return activeItem?.childrenSpot || null;
    } else if (filter === 'Future') {
      return activeItem?.childrenFuture || null;
    }
  };
  return (
    <div className="">
      <div className="mx-10 text-white">
      <div className="flex justify-center items-center">
      <button
        className={`mr-2 mt-2 px-4 py-1 rounded-full w-28 shadow-md drop-shadow-xl ${filter === 'Spot' ? 'bg-[white] text-[#ddb100]' : 'bg-[#ddb100] text-white'}`}
        onClick={() => setFilter('Spot')}
      >
        Spot
      </button>
      <button
       className={`mr-2 mt-2 px-4 py-1 rounded-full w-28 shadow-md drop-shadow-xl ${filter === 'Future' ? 'bg-[white] text-[#ddb100]' : 'bg-[#ddb100] text-white'}`}
        onClick={() => setFilter('Future')}
      >
        Future
      </button>
    </div>
        <Tabs className="text-white" defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
      {renderComponent()} 
    </div>
  );
}
export default ApplyForm;
