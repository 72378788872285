import React, { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import Logout from '../../pages/Logout'
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Tag } from 'antd';

function Wrapper() {
    const [hamburgerview, sethamburgerview] = useState("hidden")
    const handlehamburger = () => {
        sethamburgerview("block")
    }
    let location = useLocation();

    React.useEffect(() => {
        // Google Analytics
      console.log(location.pathname)
    }, [location]);
    return (
        <div>
            <header>
                <nav class="border-gray-200   bg-gray-50 dark:bg-gray-800 dark:border-gray-700 ">
                    <div class="max-w-screen-xl flex  flex-wrap items-center justify-between mx-auto p-4">
                        <Link to="/main" class="flex items-center">
                            {/* <img src="#" class="h-8 mr-3" /> */}
                            <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white ">Fingenius<Tag className='mx-2 mb-1' color="yellow">Experiment</Tag></span>
                        </Link>
                        <button data-collapse-toggle="navbar-solid-bg" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-solid-bg" aria-expanded="false">
                            <span class="sr-only">Open main menu</span>
                            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                        </button>
                        <div class="hidden w-full md:block md:w-auto" id="navbar-solid-bg">
                            <ul class="flex flex-col font-medium mt-4 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
                                <li>
                                    <Link to="/main" class={location.pathname === "/main"?"text-[#e2b200]":"text-[#000]"} aria-current="page">Home</Link>
                                </li>
                                <li>
                                    <Link to="/main/addapi" class={location.pathname === "/main/addapi"?"text-[#e2b200]":"text-[#000]"} aria-current="page">Add API</Link>
                                </li>
                                <li>
                                    <Link to="/main/basic-bot/SPOT" class={location.pathname === "/main/basic-bot/SPOT"?"text-[#e2b200]":"text-[#000]"}>Active BOT SPOT</Link>
                                </li>
                                <li>
                                    <Link to="/main/basic-bot/FUTURE" class={location.pathname === "/main/basic-bot/FUTURE"?"text-[#e2b200]":"text-[#000]"}>Active BOT FUTURE</Link>
                                </li>
                                <li>
                                    <Link to="/main/order" class={location.pathname === "/main/order"?"text-[#e2b200]":"text-[#000]"}>Transaction</Link>
                                </li>
                                <li>
                                    <Link to="/main/report" class={location.pathname === "/main/report"?"text-[#e2b200]":"text-[#000]"}>Report</Link>
                                </li>
                                <li>
                                    <Logout />
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
            <div className='bg-[#212121] h-auto min-h-screen'>
                <div className='container mx-auto'>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Wrapper
