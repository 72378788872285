import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from 'antd';

function Orderopenspot() {
  const [data, setData] = useState([]);
  const username = localStorage.getItem('username');

  const [selectedBotID, setSelectedBotID] = useState('');
  const [selectedCoin, setSelectedCoin] = useState('');
  const [selectedAPI, setSelectedAPI] = useState('');
  const [selectedTradeDirection, setSelectedTradeDirection] = useState('');
  const [selectedSide, setSelectedSide] = useState('');

  const getLiveBot = () => {
    const postdata = { "username": username };
    axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: process.env.REACT_APP_API_PATH_BOT + 'order_book',
      data: postdata,
    }).then(async function (response) {
      const res = await response.data.Data;
      console.log(res)
      const filteredData = res.filter((transaction) => transaction.Exchange === "BINANCESPOT");
    console.log(filteredData);

    const sortedData = filteredData.sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime));
    
    setData(sortedData);
    }).catch((err) => {
    });
  };

  const uniqueBotIDs = [...new Set(data.map(item => item.VarBotID))];
  const uniqueCoins = [...new Set(data.map(item => item.Symbol))];
  const uniqueAPIs = [...new Set(data.map(item => item.API_Name))];
  const uniqueTradeDirections = [...new Set(data.map(item => item.Trade_Direction))];
  const uniqueSides = [...new Set(data.map(item => item.Side))];

  const filteredDataSource = data.filter(item =>
    (!selectedBotID || item.VarBotID === selectedBotID) &&
    (!selectedCoin || item.Symbol === selectedCoin) &&
    (!selectedAPI || item.API_Name === selectedAPI) &&
    (!selectedTradeDirection || item.Trade_Direction === selectedTradeDirection) &&
    (!selectedSide || item.Side === selectedSide)
  );

  const dataSource = filteredDataSource.map((d, index) => {
    const date = new Date(d?.DateTime);
    return {
      key: index,
      VarBotID: d.VarBotID,
      DateTime: date.toLocaleString(),
      Symbol: d.Symbol,
      API_Name: d.API_Name,
      Trade_Direction: d.Trade_Direction,
      Price: d.Price,
      Quantity: d.Quantity,
      Side: d.Side,
    };
  });


  const columns = [
    {
      title: 'Bot ID',
      dataIndex: 'VarBotID',
      key: 'VarBotID',
      filters: uniqueBotIDs.map(id => ({ text: id, value: id })),
      onFilter: (value, record) => record?.VarBotID.includes(value),
      filterMode: 'tree',
      filterSearch: false,
      className: 'text-center'
    },
    {
      title: 'Date',
      dataIndex: 'DateTime',
      key: 'DateTime',
      className: 'text-center',

      render: (text) => text,
    },
    {
      title: 'Coin',
      dataIndex: 'Symbol',
      filters: uniqueCoins.map(coin => ({ text: coin, value: coin })),
      onFilter: (value, record) => record?.Symbol.includes(value),
      key: 'Symbol',
      className: 'text-center'
    },
    {
      title: 'API Name',
      dataIndex: 'API_Name',
      key: 'API_Name',
      filters: uniqueAPIs.map(apiname => ({ text: apiname, value: apiname })),
      onFilter: (value, record) => record?.API_Name.includes(value),
      className: 'text-center'
    },
    // {
    //   title: 'Trade Direction',
    //   dataIndex: 'Trade_Direction',
    //   key: 'Trade_Direction',
    //   filters: uniqueTradeDirections.map(direction => ({ text: direction, value: direction })),
    //   onFilter: (value, record) => record?.Trade_Direction.includes(value),
    //   className: 'text-center'
    // },
    {
      title: 'Price',
      dataIndex: 'Price',
      key: 'Price',
      className: 'text-center'
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
      className: 'text-center',
    },
    {
      title: 'Side',
      dataIndex: 'Side',
      key: 'Side',
      filters: uniqueSides.map(side => ({ text: side, value: side })),
      onFilter: (value, record) => record?.Side.includes(value),
      className: 'text-center',
    },
  ];

  // ... Rest of your code ...
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  useEffect(() => {
    getLiveBot();
  }, []);
  return (
    <div>

      <Table
        size="small"
        dataSource={dataSource}
        columns={columns}
        className="text-[#fff]"
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ["30", "50", "100"],
        }}
        onChange={onChange}
      />
    </div>
  );
}

export default Orderopenspot;
