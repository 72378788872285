import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
  Tooltip,
  message,
} from "antd";

import Switch from "react-switch";

function SpotBasicbot() {
  const [form1] = Form.useForm();
  let username = localStorage.getItem('username')
  const { symbol } = useParams();
  const [formData, setFormData] = useState({});
  const { Option } = Select;
  const FormItem = Form.Item;
  const [coins, setCoins] = useState([]);
  const [coinnn, setCoinnn] = useState("");
  const [activtabledata, setactivtabledata] = useState([]);
  const [open, setOpen] = useState(false);
  const [updatedata, setupdatedata] = useState([]);
  const [apikey, setapikey] = useState();
  const [api_secret, setapi_secret] = useState();
  const [selectedSymbol, setSelectedSymbol] = useState('');
  const [selectedBotID, setSelectedBotID] = useState('');
  const [BOV, setBOV] = useState(10);
  const [OC, setOC] = useState(10);
  const [OVM, setOVM] = useState(1);
  const [lev, setlev] = useState(10);
  const [OTO, setOTO] = useState('MARKET');
  const [OTF, setOTF] = useState('MARKET');
  const [totalinvestment, settotalinvestment] = useState(100);
  const [selectedbotcoin, setselectedbotcoin] = useState('');
  const [selectedCoin, setSelectedCoin] = useState('');
  const [selectedAPI, setSelectedAPI] = useState('');
  const [selectedTradeDirection, setSelectedTradeDirection] = useState('');
  const [selectedSide, setSelectedSide] = useState('');
  const { coinparams } = useParams();
  const [totalinvestmentupdated, settotalinvestmentupdated] = useState(100);
  const [currentupdaterecord, setcurrentupdaterecord] = useState()

  const { tradetypeparams } = useParams();
  const update = {
    BotID: "",
    API_Name: "",
    Coin: "",
    BaseOrderVolume: "",
    SubsequentOrderVolume: "",
    OrderVolumeMultiplier: "",
    Status: "",
    OrderType: "",
    Exchange: "",
    OrderCount: "",
    Leverage: "",
    TelegramChatID: "",
  };


  const handleupdatechange = (key, value) => {
    setcurrentupdaterecord((prevRecord) => {
      const updatedValues = { ...prevRecord.data };
      updatedValues[key] = value;

      let totalinv = 0;

      if (key === "BaseOrderVolume") {
        setBOV(value);
        totalinv = calculate(updatedValues.OrderCount, updatedValues.OrderVolumeMultiplier, value)


      } else if (key == "OrderCount") {
        setOC(value)
        // for (let i = 0; i < value; i++) {
        //   totalinv = totalinv + updatedValues.BaseOrderVolume * mul
        //   mul = mul * updatedValues.OrderVolumeMultiplier
        // }
        totalinv = calculate(value, updatedValues.OrderVolumeMultiplier, updatedValues.BaseOrderVolume)

      }
      else if (key == "OrderVolumeMultiplier") {
        setOVM(value)
        // for (let i = 0; i < updatedValues.OrderCount; i++) {
        //   totalinv = totalinv + updatedValues.BaseOrderVolume * mul
        //   mul = mul * value
        // }
        totalinv = calculate(updatedValues.OrderCount, value, updatedValues.BaseOrderVolume)

      }
      settotalinvestmentupdated(totalinv)

      return {
        ...prevRecord,
        data: updatedValues,
      };
    });
  };

  const showDrawer = (data) => {
    console.log(data)
    var totalinvdrawer = 0
    var mulindrawer = 1
    console.log(data)
    setcurrentupdaterecord(data)
    form1.setFieldsValue({
      BotID: data.data.BotID,
      API_Name: data.data.API_Name,
      Symbol: data.data.Symbol,
      BaseOrderVolume: data.data.BaseOrderVolume,
      OrderVolumeMultiplier: data.data.OrderVolumeMultiplier,
      OrderTypeOpen: data.data.OrderTypeOpen,
      OrderTypeClose: data.data.OrderTypeClose,
      TelegramChatID: data.data.TelegramChatID,
      trade_type: data.data.Exchange,
      Leverage: data.data.Leverage,
      userid: data.data.UserID,
      OrderCount: data.data.OrderCount,
    });
    for (let i = 0; i < data.data.OrderCount; i++) {
      totalinvdrawer = totalinvdrawer + data.data.BaseOrderVolume * mulindrawer;
      mulindrawer = mulindrawer * data.data.OrderVolumeMultiplier;
    }
    settotalinvestmentupdated(totalinvdrawer)
    setBOV(data.data.BaseOrderVolume)
    setOC(data.data.OrderCount)
    setOVM(data.data.OrderVolumeMultiplier)
    setlev(data.data.Leverage)
    setOpen(true);
  };

  const handleDelete = (record) => {
    setupdatedata(record);
    let postData = { username: username, bot_id: record.BotID, coin: record.Symbol, api_name: record.API_Name, exchange: "BINANCESPOT" };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_BOT + "stop",
      data: postData,
    })
      .then(async function (response) {
        const res = await response.data;
        console.log(res)
        if (res.Success) {
          getallrole();
          message.success(res.Success)

        } else if (res.errors) {
          console.log(res.errors.non_field_errors);
        }
      })
      .catch((err) => {
        console.log(err.response.data.Error);
        message.error(err.response.data.Error);
        getallrole();
      });
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleSubmit = (values) => {
    values.username = username;
    values.base = BOV ? BOV : "";
    values.exchange = 'BINANCESPOT';
    values.basel = 0;
    values.bases = 0;
    values.order_count = OC ? OC : "";
    values.order_countl = 0;
    values.order_counts = 0;
    values.vol_mul = OVM ? OVM : "";
    values.vol_mull = 0;
    values.vol_muls = 0;
    values.lev = lev ? lev : "";
    values.order_type_open = OTO ? OTO : "";
    values.order_type_close = OTF ? OTF : "";
    values.order_type_openl = "";
    values.order_type_closel = "";
    values.order_type_opens = "";
    values.order_type_closes = "";
    values.bot_id = selectedBotID;
    values.coin = selectedbotcoin ? selectedbotcoin : "";
    console.log("values");
    console.log(values);
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_BOT + "create",
      data: values,
    })
      .then(async function (response) {
        const res = await response.data;
        console.log(res.Success)
        if (res) {
          message.success(res.Success);
          alert(res.Success)
          console.log(res)

          getallrole();
        } else if (res.errors) {
          alert(res.errors);
        }
      })
      .catch((err) => {
        message.error(err.response.data.Error)
        alert(err.response.data.Error)
        console.log(err.response.data.Error)
      });
  };
  const handleupdateSubmit = (values) => {
    console.log(values, 'valuessss')
    console.log(currentupdaterecord, 'currentupdaterecord')
    // const postdata = {
    //   username: username,
    //   exchange: "BINANCESPOT",
    //   api_name: currentupdaterecord.data.API_Name,
    //   base: currentupdaterecord.data.BaseOrderVolume,
    //   basel: 0,
    //   bases: 0,
    //   order_count: currentupdaterecord.data.OrderCount,
    //   order_countl: 0,
    //   order_counts: 0,
    //   vol_mul: currentupdaterecord.data.OrderVolumeMultiplier,
    //   vol_mull: 0,
    //   vol_muls: 0,
    //   lev: 0,
    //   coin: currentupdaterecord.data.Symbol,
    //   order_type_open: currentupdaterecord.data.OrderTypeOpen,
    //   order_type_close: currentupdaterecord.data.OrderTypeClose,
    //   order_type_openl: '',
    //   order_type_closel: '',
    //   order_type_opens: '',
    //   order_type_closes: '',
    //   telegram_id: values.TelegramChatID,
    //   bot_id: currentupdaterecord.data.BotID,
    // }
    const postdata = {
      username: username,
      exchange: "BINANCESPOT",
      api_name: values.API_Name,
      base: values.BaseOrderVolume,
      basel: 0,
      bases: 0,
      order_count: values.OrderCount,
      order_countl: 0,
      order_counts: 0,
      vol_mul: values.OrderVolumeMultiplier,
      vol_mull: 0,
      vol_muls: 0,
      lev: 0,
      coin: values.Symbol,
      order_type_open: values.OrderTypeOpen,
      order_type_close: values.OrderTypeClose,
      order_type_openl: '',
      order_type_closel: '',
      order_type_opens: '',
      order_type_closes: '',
      telegram_id: values.TelegramChatID,
      bot_id: values.BotID,
    }
    console.log(postdata)


    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_BOT + "edit",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response.data;
        console.log(response)
        if (res.Success) {
          // alert("api updated successfully");
          onClose()
          getallrole();
          message.success(res.Success)
          alert(res.Success)
        } else if (res.errors) {
          alert(res.errors);
        }
      })
      .catch((err) => {
        message.error(err.response.data.Error);
        alert(err.response.data.Error);
        console.log(err)
        getallrole();
      });
  };
  const getallrole = (e) => {

    let username = localStorage.getItem("username")
    let postData = { username: username }
    axios
      .post(process.env.REACT_APP_API_PATH_BOT + "bot_list", postData)
      .then((res) => {
        console.log(res.data.Data)
        setactivtabledata(res.data.Data);
      })
      .catch((err) => {
        alert(err.response.data);
      });
  };
  const [APIlist, setAPIlist] = useState([]);
  const getapiname = () => {

    let username = localStorage.getItem("username")
    let postData = { username: username }
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "api_list",
      data: postData,
    })
      .then(async function (response) {
        const res = await response.data.Data;
        const filteredRecords = res.filter(record => record.Exchange === "BINANCESPOT");
        if (res) {
          setAPIlist(filteredRecords);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };
  const handleChangeapi = (values) => {
    let userid = localStorage.getItem("userID");
    let postData = { user_id: userid };
    axios
      .post(process.env.REACT_APP_API_PATH + "getapiname", postData)
      .then((res) => {

        const selectedApi = res.data.data.find(
          (api) => api.API_Name === values
        );
        if (selectedApi) {
          setapikey(selectedApi.Key);
          setapi_secret(selectedApi.Secret);
          message.success(`${values} API selected`);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response);
        }
      });
  };
  const onSearchapi = (value) => {
  };
  const handlechangeOTO = (value) => {
    console.log(value)
    setOTO(value)
  };
  const handlechangeOTC = (value) => {
    console.log(value)
    setOTF(value)
  };
  const optionsapi = Array.isArray(APIlist) && APIlist.length > 0
    ? APIlist.map((d) => ({
      value: d.API_Name,
      label: d.API_Name,
    }))
    : [{
      value: "noapi",
      label: typeof APIlist === 'string' ? APIlist : "No API Added Yet",
    }];


  const getallcoin = (e) => {
    axios
      .post(process.env.REACT_APP_API_PATH_BOT + "view_bot")
      .then((res) => {
        setCoins(res.data.Data);
        if (res.data.Data) {
          const matchingCoins = res.data.Data.filter((d) => d.VarBotID === coinparams ? coinparams : "")
          console.log(matchingCoins)
          setSelectedBotID(matchingCoins[0].VarBotID)
          setselectedbotcoin(matchingCoins[0].Symbol)
          setSelectedSymbol(matchingCoins[0].Name ? matchingCoins[0].Name : "");

        }
      })
      .catch((err) => {
        console.log(err)
      });
  };
  const tooltipContent = (
    <div>
      <p>1.Open Telegram, search "userinfobot" or "@userinfobot," click Start for user details.</p>
      <p>2.Copy Userid, Update here.</p>
      <p>3.Open Telegram, search "FutureAPBot" or "@FutureAPBot," click Start to Activate FutureAPBot.</p>
    </div>
  );
  const optionscoins = coins.map((coin) => {
    return {
      value: coin.Symbol,
      label: coin.Symbol,
    };
  });
  const botIDOptions = coins
    .filter((coin) => coin.Symbol === selectedSymbol)
    .map((coin) => {
      return {
        value: coin.VarBotID,
        label: coin.VarBotID,
      };
    });

  const onswitchChange = (record, checked) => {
    console.log(record.Status)
    let username = localStorage.getItem("username");
    if (record.Status === "PAUSED") {
      let postData = {
        username: username,
        bot_id: record.BotID,
        coin: record.Symbol,
        api_name: record.API_Name,
        exchange: 'BINANCESPOT'
      };
      axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH_BOT + "start",
        data: postData,
      })
        .then(async function (response) {
          const res = await response.data;
          console.log(res)
          message.success(res.Success)
          // toast.success(res.data);
          getallrole();
        })
        .catch((err) => {
          message.error(err.response.data.Error);
          getallrole();
        });
    }
    if (record.Status === "ACTIVE") {
      let postData = {
        username: username,
        bot_id: record.BotID,
        coin: record.Symbol,
        api_name: record.API_Name,
        exchange: 'BINANCESPOT'
      };
      axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH_BOT + "pause",
        data: postData,
      })
        .then(async function (response) {
          const res = await response.data;
          console.log(res)
          message.success(res.Success)

          getallrole();
        })
        .catch((err) => {
          console.log(err)
          message.error(err.response.data.Error);
          getallrole();
        });
    }

  };
  const uniqueBotIDs = [...new Set(activtabledata.map(item => item.VarBotID))];
  const uniqueCoins = [...new Set(activtabledata.map(item => item.Coin))];
  const uniqueAPIs = [...new Set(activtabledata.map(item => item.API_Name))];
  const uniqueTradeDirections = [...new Set(activtabledata.map(item => item.Trade_Direction))];
  const uniqueSides = [...new Set(activtabledata.map(item => item.Side))];

  const filteredDataSource = activtabledata.filter(item =>
    (!selectedBotID || item.VarBotID === selectedBotID) &&
    (!selectedCoin || item.Symbol === selectedCoin) &&
    (!selectedAPI || item.API_Name === selectedAPI) &&
    (!selectedTradeDirection || item.Trade_Direction === selectedTradeDirection) &&
    (!selectedSide || item.Side === selectedSide)
  );
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  useEffect(() => {
    getallrole();
    getallcoin();
    getapiname();
    // handleSymbolChange()
  }, []);

  const columns = [
    {
      title: <Tooltip title="BotID">BID</Tooltip>,
      width: 150,
      dataIndex: "BotID",
      className: "text-center",
      key: "BotID",
      filters: uniqueBotIDs.map(id => ({ text: id, value: id })),
      onFilter: (value, record) => record.BotID.includes(value),
      filterMode: 'tree',
      filterSearch: false,
    },
    {
      title: <Tooltip title="API Name">A.N</Tooltip>,
      width: 150,
      dataIndex: "API_Name",
      key: "API_Name",
      className: "text-center",
      filters: uniqueAPIs.map(apiname => ({ text: apiname, value: apiname })),
      onFilter: (value, record) => record.API_Name.includes(value),
      filterMode: 'tree',
      filterSearch: false,
    },
    {
      title: <Tooltip title="Coin">Coin</Tooltip>,
      width: 150,
      dataIndex: "Symbol",
      key: "Symbol",
      filters: uniqueCoins.map(coin => ({ text: coin, value: coin })),
      onFilter: (value, record) => record.Coin.includes(value),
      className: "text-center",
    },
    {
      title: <Tooltip title="Status">Status</Tooltip>,
      width: 150,
      dataIndex: "Status",
      key: "Status",
      className: "text-center",
    },
    {
      title: <Tooltip title="Open Order Type">OOT</Tooltip>,
      width: 150,
      dataIndex: "OrderTypeOpen",
      key: "OrderTypeOpen",
      className: "text-center",
    },
    {
      title: <Tooltip title="Close Order Type">COT</Tooltip>,
      width: 150,
      dataIndex: "OrderTypeClose",
      key: "OrderTypeClose",
      className: "text-center",
    },
    {
      title: <Tooltip title="Exchange">Exchange</Tooltip>,
      width: 150,
      dataIndex: "Exchange",
      key: "Exchange",
      className: "text-center",
    },

    {
      title: <Tooltip title="OrderVolumeMultiplier">OVM</Tooltip>,
      width: 150,
      dataIndex: "OrderVolumeMultiplier",
      key: "OrderVolumeMultiplier",
      className: "text-center",
    },

    {
      title: <Tooltip title="Base Order Volume">BOV</Tooltip>,
      width: 150,
      dataIndex: "BaseOrderVolume",
      key: "BaseOrderVolume",
      className: "text-center",
    },
    {
      title: <Tooltip title="OrderCount">OrderCount</Tooltip>,
      width: 150,
      dataIndex: "OrderCount",
      key: "OrderCount",
      className: "text-center",
    },

    {
      title: <Tooltip title="Telegram Chat ID">TCID</Tooltip>,
      width: 150,
      dataIndex: "TelegramChatID",
      key: "TelegramChatID",
      className: "text-center",
    },
    {
      title: "Action",
      dataIndex: "Edit",
      width: 150,
      className: "text-center",
      render: (text, record) => (
        <>
          <div className="justify-center items-center flex">
            <Button
              className="border-none mb-0"
              onClick={() => showDrawer({ data: record })}
            >
              <EditOutlined size={18} />
            </Button>
            <Button
              className="border-none mb-0"
              onClick={() => handleDelete(record)}
            >
              <DeleteOutlined size={18} />
            </Button>
            <Switch
              checkedChildren="Start"
              unCheckedChildren="Pause"
              className="bg-[#e7b30a] "
              checked={record.Status === "ACTIVE" ? true : false}
              onChange={(checked) => onswitchChange(record, checked)}
            />
          </div>
        </>
      ),
    },
  ];

  const dataSource = activtabledata
    .filter(d => d.Exchange === "BINANCESPOT") // Filter data by Exchange value
    .map((d, index) => {
      return {
        key: index,
        BotID: d.VarBotID,
        API_Name: d.API_Name,
        Symbol: d.Symbol,
        BaseOrderVolume: d.BaseOrderVolume,
        OrderVolumeMultiplier: d.VolumeMultiplier,
        Status: d.Status,
        OrderTypeOpen: d.OrderTypeOpen,
        OrderTypeClose: d.OrderTypeClose,
        Exchange: d.Exchange,
        OrderCount: d.OrderCount,
        TelegramChatID: d.ChatID,

      };
    });
  var calculate = (OC, OVM, BOV) => {
    var totalinv = 0
    var mul = 1
    console.log(OC, OVM, BOV, 'OC,OVM,BOV')
    for (let i = 0; i < OC; i++) {
      totalinv = totalinv + BOV * mul
      mul = mul * OVM
      console.log(totalinv)
    }
    return totalinv;
  }
  const handleMainValues = (key, value) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
    if (key == "totalinvestment") {
      settotalinvestment(value)
      setBOV(value * 10 / 100)
      setOC(10)
      setOVM(1)
    }
    var totalinvestment = 0

    if (key === "order_count") {
      setOC(value)
      totalinvestment = calculate(value, OVM, BOV)
    }
    else if (key === "vol_mul") {
      setOVM(value)
      totalinvestment = calculate(OC, value, BOV)
    }
    else if (key === "base") {
      setBOV(value)
      totalinvestment = calculate(OC, OVM, value)
    }
    settotalinvestment(totalinvestment)
  };
  return (
    <>
      <div className="py-4 h-screen ">
        <Form onFinish={handleSubmit}>
          <div className="">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-5 border-solid border px-2 rounded-md mx-1 border-[#d9d9d9] pb-4 bg-second items-center">
              <div>
                <div className="flex justify-center">
                  <label className="block text-slate-50 mt-1" htmlFor="name">
                    API NAME(A.N)
                  </label>
                  <Tooltip title="API NAME(A.N)">
                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer " />
                  </Tooltip>
                </div>
                <FormItem name="api_name" className="w-full" rules={[
                  {
                    required: true,
                    message: 'Please select an API name!',
                  },
                ]}>
                  <Select
                    onChange={handleChangeapi}
                    showSearch
                    onSearch={onSearchapi}
                    className="rounded-md   w-full"
                    id="option"
                    name="option"
                    options={optionsapi ? optionsapi : ""}
                  ></Select>
                </FormItem>
              </div>
              <div>
                <div className="flex justify-center">
                  <label className="block text-slate-50 mt-1" htmlFor="name">
                    Coins Name
                  </label>
                  <Tooltip title="Coins">
                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer " />
                  </Tooltip>
                </div>
                <FormItem name="coin"
                  rules={[
                    {
                      required: !selectedbotcoin,
                      message: 'Please select an coin!',
                    },
                  ]}
                >
                  <p hidden>{selectedbotcoin}</p>
                  <Input
                    className="bg-white"
                    type="text"
                    required
                    value={selectedbotcoin ? selectedbotcoin : ""}
                    placeholder="Select Bot"
                    disabled={selectedbotcoin ? false : true}
                    onChange={(value) => handleupdatechange("abc", value)}
                  />
                </FormItem>
              </div>
              <div>
                <div className="flex justify-center">
                  <label className="block text-slate-50 mt-1" htmlFor="name">
                    Coins
                  </label>
                  <Tooltip title="Coins">
                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer " />
                  </Tooltip>
                </div>
                <FormItem name="coin"
                  rules={[
                    {
                      required: !selectedSymbol,
                      message: 'Please select an coin!',
                    },
                  ]}
                >
                  <p hidden>{selectedSymbol}</p>
                  <Input
                    className="bg-white"
                    type="text"
                    required
                    value={selectedSymbol ? selectedSymbol : ""}
                    placeholder="Select Bot"
                    disabled={selectedSymbol ? false : true}
                    onChange={(value) => handleupdatechange("abc", value)}
                  />
                </FormItem>
              </div>
              <div>
                <div className="flex justify-center">
                  <label className="block text-slate-50 mt-1" htmlFor="name">
                    Trade Type
                  </label>
                  <Tooltip title="Coins">
                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer " />
                  </Tooltip>
                </div>
                <FormItem name="TradeType"
                  rules={[
                    {
                      required: !tradetypeparams,
                      message: 'Please select Trade type',
                    },
                  ]}
                >
                  <p hidden>{tradetypeparams}</p>
                  <Input
                    className="bg-white"
                    type="text"
                    required
                    value={tradetypeparams ? tradetypeparams : ""}
                    placeholder="Select Bot"
                    disabled={tradetypeparams ? false : true}
                    onChange={(value) => handleupdatechange("abc", value)}
                  />
                </FormItem>
              </div>
              <div hidden>
                <div className="flex justify-center">
                  <label className="block text-slate-50 mt-1" htmlFor="name">
                    Bot ID
                  </label>
                  <Tooltip title="prompt text">
                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                  </Tooltip>

                </div>
                <FormItem name="bot_id">
                  <p hidden>{selectedBotID}</p>
                  <Select
                    name="bot_id"
                    className="p-1 rounded-md mb-0 w-full"
                    value={selectedBotID}
                    disabled={!selectedSymbol}
                    defaultValue={selectedBotID}
                    options={botIDOptions}
                  ></Select>
                </FormItem>
              </div>
            </div>
            <div className="grid lg:grid-cols-7 md:grid-cols-2 grid-cols-1 gap-4 my-2 px-4 justify-between items-center">
              <div>

                <div className="flex justify-center">
                  <label className="block text-slate-50 text-[#e7b30a] mt-1" htmlFor="name">
                    Bot Alloted Fund
                  </label>
                  <Tooltip title="size of the First trade">
                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                  </Tooltip>
                </div>
                <FormItem name="totalinvestment" >
                  <p hidden>{totalinvestment ? totalinvestment : "0.00"}</p>
                  <InputNumber
                    className="w-full"
                    placeholder="Enter Base Order Volume"
                    onChange={(value) => handleMainValues('totalinvestment', value)}
                    required
                    value={totalinvestment ? totalinvestment : "0.00"}
                    type="number"
                  />
                </FormItem>
              </div>
              <div>
                <div className="flex justify-center">
                  <label className="block text-slate-50 text-[#e7b30a] mt-1" htmlFor="name">
                    Base Order Volume ($) (BOV)
                  </label>
                  <Tooltip title="size of the First trade">
                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                  </Tooltip>
                </div>
                <FormItem name="base" >
                  <p hidden>{BOV ? BOV : ""}</p>
                  <InputNumber
                    className="w-full"
                    placeholder="Enter Base Order Volume"
                    onChange={(value) => handleMainValues('base', value)}
                    required
                    value={BOV ? BOV : ""}
                    type="number"
                  />
                </FormItem>
              </div>
              <div>
                <div className="flex justify-center">
                  <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                    Order Count(OC)
                  </label>
                  <Tooltip title="No. subsequent order">
                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                  </Tooltip>
                </div>
                <FormItem name="order_count">
                  <p hidden>{OC}</p>
                  <InputNumber
                    className="w-full"
                    onChange={(value) => handleMainValues('order_count', value)}
                    value={OC}
                    placeholder="Enter your order count"
                    required
                    type="number"
                  />
                </FormItem>
              </div>
              <div>
                <div>
                  <div className="flex justify-center">
                    <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                      Telegram Chat ID (TCID)
                    </label>
                    <Tooltip title={tooltipContent}>
                      <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                    </Tooltip>
                  </div>
                </div>
                <FormItem name="telegram_id">
                  <Input
                    className=""
                    type="text"
                    placeholder="Enter Your Telegram Chat ID"
                    required
                  />
                </FormItem>
              </div>
              <div>
                <div>
                  <div className="flex justify-center">
                    <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                      Order Volume Multiplier
                    </label>
                    <Tooltip title={"Investment(x) multiplier of Each subsequent order.(OVM)"}>
                      <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                    </Tooltip>
                  </div>
                </div>
                <FormItem name="vol_mul">
                  <p hidden>{OVM ? OVM : ""}</p>
                  <InputNumber
                    className="w-full"
                    placeholder="Enter Your Order Volume Multiplier"
                    required
                    type="number"
                    onChange={(value) => handleMainValues('vol_mul', value)}
                    value={OVM ? OVM : ""}
                  />
                </FormItem>
              </div>
              <div>
                <div>
                  <div className="flex justify-center">
                    <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                      Open Order Type(OT)
                    </label>
                    <Tooltip title="Order Type">
                      <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                    </Tooltip>
                  </div>
                </div>
                <FormItem name="order_type" >
                  <Select defaultValue="MARKET" onChange={handlechangeOTO} className="rounded-md   w-[100%] " required>
                    <Option >--Select Order Type--</Option>
                    <Option value="MARKET">MARKET</Option>
                    <Option value="LIMIT">LIMIT</Option>
                  </Select>
                </FormItem>
              </div>

              <div>
                <div>
                  <div className="flex justify-center">
                    <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                      Close Order Type(OT)
                    </label>
                    <Tooltip title="Order Type">
                      <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                    </Tooltip>
                  </div>
                </div>
                <FormItem name="order_type" >
                  <Select defaultValue="MARKET" onChange={handlechangeOTC} className="rounded-md   w-[100%] " required>
                    <Option >--Select Order Type--</Option>
                    <Option value="MARKET">MARKET</Option>
                    <Option value="LIMIT">LIMIT</Option>
                  </Select>
                </FormItem>
              </div>
              {/* <div>
                <div className="flex justify-center">
                  <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                    Leverage
                  </label>
                  <Tooltip title="amplify potential returns or losses">
                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                  </Tooltip>
                </div>
                <FormItem name="lev">
                <p hidden>{lev?lev:""}</p>
                  <InputNumber
                    className="w-full"
                    placeholder="Enter Your Leverage"
                    required
                    type="number"
                    value={lev?lev:""}
                    onChange={(value) => handleMainValues('lev', value)}
                  />
                </FormItem>
              </div> */}
            </div>
            <div className="w-full px-2 flex items-center justify-center">
              <input
                type="submit"
                className="px-8 py-2 rounded-lg border-2 w-max cursor-pointer font_primary  hover:border-second font_secondary text-second hover:text-second bg-main hover:bg-[white] focus:outline-0"
              />
            </div>
          </div>
        </Form>
        <div className="mx-1 mt-3">
          <div >
            <Table
              size="small"
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: 1500 }}
              pagination={{
                defaultPageSize: 20,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30"],
              }}
              onChange={onChange}
            />
            <Drawer
              title="Basic Drawer"
              placement="right"
              onClose={onClose}
              open={open}
            >
              <Form form={form1} onFinish={handleupdateSubmit}>
                <div className="">
                  <div>
                    <label className="block text-slate-50" htmlFor="mobile">
                      API NAME(A.N)
                    </label>
                    <FormItem name="API_Name">
                      <Input
                        className="mb-0 w-full"
                        name="API_Name"
                        placeholder="Enter your api name"
                        onChange={(value) => handleupdatechange("abc", value)}
                        required
                        disabled
                      />
                    </FormItem>
                  </div>
                  <div>
                  </div>
                  <div>
                    <label className="block text-slate-50">Coins</label>
                    <FormItem name="Symbol">
                      <Input

                        className="p-1 rounded-md  mb-0  w-full"
                        onChange={(value) => handleupdatechange("abc", value)}
                        required
                        disabled
                      ></Input>
                    </FormItem>
                  </div>
                  <div>
                    <label className="block text-slate-50 mt-4">
                      BotID
                    </label>
                    <FormItem name="BotID">
                      <Input
                        className=""
                        type="text"
                        disabled
                        placeholder="Enter Your Telegram BotID"
                        required
                        onChange={(value) => handleupdatechange("abc", value)}
                      />
                    </FormItem>
                    <label className="block text-slate-50 mt-4" htmlFor="option">
                      Trade Type
                    </label>
                    <FormItem name="trade_type">
                      <Input
                        className="bg-white"
                        type="text"
                        required
                        placeholder="Select Bot"
                        disabled={true}
                        onChange={(value) => handleupdatechange("abc", value)}
                      />
                    </FormItem>
                    <label className="block text-slate-50 mt-4" htmlFor="option">
                      Open Order Type
                    </label>
                    <FormItem name="OrderTypeOpen">
                      <Select
                        className="rounded-md  w-[100%] "
                        name="option"
                        required
                      >
                        <option value="">--Select Order Type--</option>
                        <option value="MARKET">MARKET</option>
                        <option value="LIMIT">LIMIT</option>
                      </Select>
                    </FormItem>
                    <label className="block text-slate-50 mt-4" htmlFor="option">
                      Close Order Type
                    </label>
                    <FormItem name="OrderTypeClose">
                      <Select
                        className="rounded-md  w-[100%] "
                        name="option"
                        required
                      >
                        <option value="">--Select Order Type--</option>
                        <option value="MARKET">MARKET</option>
                        <option value="LIMIT">LIMIT</option>
                      </Select>
                    </FormItem>
                    <label className="block text-slate-50  mt-4" htmlFor="option">
                      OrderCount
                    </label>
                    <FormItem name="OrderCount">
                      <InputNumber onChange={(value) => handleupdatechange("OrderCount", value)} className="w-full" />
                    </FormItem>
                    <label className="block text-slate-50 mt-4  ">
                      Base Order Volume ($) (BOV)
                    </label>
                    <FormItem name="BaseOrderVolume">
                      <InputNumber
                        className="w-full"
                        placeholder="Enter Base Order Volume"
                        required
                        type="number"
                        onChange={(value) => handleupdatechange("BaseOrderVolume", value)}
                      />
                    </FormItem>
                  </div>
                  <div>
                    <label className="block text-slate-50  mt-4">
                      Order Volume Multiplier (OVM)
                    </label>
                    <FormItem name="OrderVolumeMultiplier">
                      <InputNumber
                        className="w-full"
                        placeholder="Enter Your Order Volume Multiplier"
                        onChange={(value) => handleupdatechange("OrderVolumeMultiplier", value)}
                        required
                        type="number"
                      />
                    </FormItem>
                  </div>
                  <div>
                    <label className="block text-slate-50 mt-4">
                      Telegram Chat ID(TCID)
                    </label>
                    <FormItem name="TelegramChatID">
                      <Input
                        className=""
                        type="text"
                        placeholder="Enter Your Telegram Chat ID"
                        required
                        onChange={(value) => handleupdatechange("TelegramChatID", value)}
                      />
                    </FormItem>
                  </div>
                  <div>
                    <div className="flex justify-center">
                      <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                        Total Investment Needed
                      </label>
                    </div>
                  
                    <FormItem name="totalinvestment" >
                      <p hidden>{totalinvestmentupdated ? totalinvestmentupdated : "0.00"}</p>
                      <InputNumber
                        className="w-full"
                        placeholder="Enter Base Order Volume"
                        onChange={(value) => handleupdatechange('totalinvestment', value)}
                        required
                        value={totalinvestmentupdated ? totalinvestmentupdated : "0.00"}
                        type="number"
                      />
                    </FormItem>
                  </div>
                </div>
                <div className="w-full px-2 flex items-center justify-center">
                  <input
                    type="submit"
                    className="px-8 py-2 rounded-lg border-2 w-max cursor-pointer font_primary  hover:border-second font_secondary text-second hover:text-second bg-main hover:bg-[white] focus:outline-0"
                  />
                </div>
              </Form>
            </Drawer>
          </div>
        </div>
      </div>
    </>
  );
}
export default SpotBasicbot;
