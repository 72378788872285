import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { FiSettings } from "react-icons/fi";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import Switch from "react-switch";

import {
    Button,
    Drawer,
    Form,
    Input,
    InputNumber,
    Select,
    Table,
    Tooltip,
    message,
} from "antd";
function FutureBasicBot() {
    const handlechnagevalues = (OC, BOV) => {
        let totalinv = 0;
        let mul = 0
        for (let i = 0; i < OC; i++) {
            totalinv = totalinv + BOV * mul
            mul = mul * OVMF
        }
        return totalinv
    };
    const [form1] = Form.useForm();
    const { symbol } = useParams();
    const [formData, setFormData] = useState({});
    const { Option } = Select;
    const FormItem = Form.Item;
    const [Symbols, setSymbols] = useState([]);
    const [Symbolnn, setSymbolnn] = useState("");
    const [activtabledata, setactivtabledata] = useState([]);
    const [open, setOpen] = useState(false);
    const [updatedata, setupdatedata] = useState([]);
    const [apikey, setapikey] = useState();
    const [api_secret, setapi_secret] = useState();
    const [selectedSymbol, setSelectedSymbol] = useState('');
    const [selectedBotID, setSelectedBotID] = useState('');
    const [BOV, setBOV] = useState(2);
    const [BOVF, setBOVF] = useState(2);
    const [OC, setOC] = useState(25);
    const [OCF, setOCF] = useState(25);
    const [OVM, setOVM] = useState(1);
    const [OVMF, setOVMF] = useState(1);
    const [vominvS, setvominvS] = useState(50);
    const [vominvF, setvominvF] = useState(50);
    const [lev, setlev] = useState(10);
    const [OTOM, setOTOM] = useState('MARKET');
    const [OTCM, setOTCM] = useState('MARKET');
    const [OTSO, setOTSO] = useState('MARKET');
    const [OTSC, setOTSC] = useState('MARKET');
    const [OTFO, setOTFO] = useState('MARKET');
    const [OTFC, setOTFC] = useState('MARKET');
    const [totalinvestment, settotalinvestment] = useState(100);
    const [totalinvestmentupdated, settotalinvestmentupdated] = useState(100);
    const [totalinvestmentBOVL, settotalinvestmentBOVL] = useState(50);
    const [totalinvestmentBOVS, settotalinvestmentBOVS] = useState(50);
    const [selectedbotSymbol, setselectedbotSymbol] = useState('');
    const [ocfinv, setocfinv] = useState(handlechnagevalues(BOVF, OCF));
    const [ocsinv, setocsinv] = useState(handlechnagevalues(BOV, OC));
    const [currentupdaterecord, setcurrentupdaterecord] = useState()
    var username = localStorage.getItem("username")
    // const [ocsinv, setocsinv] = useState(OC*BOV);
    const { coinparams } = useParams();
    const { tradetypeparams } = useParams();
    const update = {
        BotID: "",
        API_Name: "",
        Symbol: "",
        BaseOrderVolume: "",
        SubsequentOrderVolume: "",
        OrderVolumeMultiplier: "",
        Status: "",
        OrderType: "",
        Exchange: "",
        OrderCount: "",
        Leverage: "",
        TelegramChatID: "",
    };
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

    const toggleAdvancedSettings = () => {
        setShowAdvancedSettings(!showAdvancedSettings);
    };


    const handleupdatechange = (key, value) => {
        setcurrentupdaterecord((prevRecord) => {
            const updatedValues = { ...prevRecord.data };
            updatedValues[key] = value;

            let totalinv = 0;
            let totalinvF = 0;

            if (key === "BaseOrderVolumeL") {
                setBOV(value);
                totalinv = calculate(updatedValues.OrderCountL, updatedValues.OrderVolumeMultiplierL, value)
                totalinvF = calculate(updatedValues.OrderCountS, updatedValues.OrderVolumeMultiplierS, updatedValues.BaseOrderVolumeS)
            } else if (key === "BaseOrderVolumeS") {
                setBOVF(value);
                totalinvF = calculate(updatedValues.OrderCountS, updatedValues.OrderVolumeMultiplierS, value)
                totalinv = calculate(updatedValues.OrderCountL, updatedValues.OrderVolumeMultiplierL, updatedValues.BaseOrderVolumeL)

            } else if (key == "OrderCountS") {
                setOC(value)
                totalinvF = calculate(value, updatedValues.OrderVolumeMultiplierS, updatedValues.BaseOrderVolumeS)
                totalinv = calculate(updatedValues.OrderCountL, updatedValues.OrderVolumeMultiplierL, updatedValues.BaseOrderVolumeL)

            } else if (key == "OrderCountL") {
                setOC(value)
                totalinvF = calculate(updatedValues.OrderCountS, updatedValues.OrderVolumeMultiplierS, updatedValues.BaseOrderVolumeS)
                totalinv = calculate(value, updatedValues.OrderVolumeMultiplierL, updatedValues.BaseOrderVolumeL)

            }
            else if (key == "OrderVolumeMultiplierL") {
                setOVM(value)
                totalinvF = calculate(updatedValues.OrderCountS, updatedValues.OrderVolumeMultiplierS, updatedValues.BaseOrderVolumeS)
                totalinv = calculate(value, updatedValues.OrderVolumeMultiplierL, updatedValues.BaseOrderVolumeL)

            }
            else if (key == "OrderVolumeMultiplierS") {
                setOVM(value)
                totalinvF = calculate(updatedValues.OrderCountS, value, updatedValues.BaseOrderVolumeS)
                totalinv = calculate(updatedValues.OrderCountL, updatedValues.OrderVolumeMultiplierL, updatedValues.BaseOrderVolumeL)

            }
            const totalInvestment = totalinv + totalinvF;

            settotalinvestmentupdated(totalInvestment);

            return {
                ...prevRecord,
                data: updatedValues,
            };
        });
    };

console.log(currentupdaterecord)


    const showDrawer = (data) => {
        console.log(data,'data')
        var totalinvdrawerL = 0
        var totalinvdrawerS = 0
        var mulindrawerL = 1
        var mulindrawerS = 1

        setcurrentupdaterecord(data)
        
        form1.setFieldsValue({
            BotID: data.data.BotID,
            API_Name: data.data.API_Name,
            Symbol: data.data.Symbol,
            BaseOrderVolume: data.data.BaseOrderVolume,
            BaseOrderVolumeL: data.data.BaseOrderVolumeL,
            BaseOrderVolumeS: data.data.BaseOrderVolumeS,
            OrderVolumeMultiplier: data.data.OrderVolumeMultiplier,
            OrderVolumeMultiplierL: data.data.OrderVolumeMultiplierL,
            OrderVolumeMultiplierS: data.data.OrderVolumeMultiplierS,
            OrderTypeOpenL: data.data.OrderTypeOpenL,
            OrderTypeOpenS: data.data.OrderTypeOpenS,
            OrderTypeCloseL: data.data.OrderTypeCloseL,
            OrderTypeCloseS: data.data.OrderTypeCloseS,
            TelegramChatID: data.data.TelegramChatID,
            trade_type: data.data.Exchange,
            // trade_type: data.data.TradeType,
            Leverage: data.data.Leverage,
            userid: data.data.UserID,
            OrderCount: data.data.OrderCount,
            OrderCountL: data.data.OrderCountL,
            OrderCountS: data.data.OrderCountS,

        });


        for (let i = 0; i < data.data.OrderCountL; i++) {
            totalinvdrawerL = totalinvdrawerL + data.data.BaseOrderVolumeL * mulindrawerL;
            mulindrawerL = mulindrawerL * data.data.OrderVolumeMultiplierL;
        }
        for (let i = 0; i < data.data.OrderCountS; i++) {
            totalinvdrawerS = totalinvdrawerS + data.data.BaseOrderVolumeS * mulindrawerS;
            mulindrawerS = mulindrawerS * data.data.OrderVolumeMultiplierS;
        }
        settotalinvestmentupdated(totalinvdrawerL + totalinvdrawerS)

        setBOV(data.data.BaseOrderVolume)
        setOC(data.data.OrderCount)
        setOVM(data.data.OrderVolumeMultiplier)
        setlev(data.data.Leverage)

        setOpen(true);
    }

    const handleDelete = (record) => {
        setupdatedata(record);
        let postData = { username: username, bot_id: record.BotID, coin: record.Symbol, api_name: record.API_Name, exchange: 'BINANCEFUTURES' };
        axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API_PATH_BOT + "stop",
            data: postData,
        })
            .then(async function (response) {
                const res = await response.data;
                if (res.Success) {
                    getallrole();
                    message.success(res.Success)
                } else if (res.errors) {
                    alert(res.errors.non_field_errors);
                }
            })
            .catch((err) => {
                message.error(err.response.data.Error);
                getallrole();
            });
    };
    const onClose = () => {
        setOpen(false);
    };
    const handleSubmit = (values) => {
        console.log('innnnn')
        values.username = username;
        values.base = 0;
        values.exchange = 'BINANCEFUTURES';
        values.basel = BOV ? BOV : "";
        values.bases = BOVF ? BOVF : "";
        values.order_count = 0;
        values.order_countl = OC ? OC : "";
        values.order_counts = OCF ? OCF : "";
        values.vol_mul = 0;
        values.vol_mull = OVM ? OVM : "";
        values.vol_muls = OVMF ? OVMF : "";
        values.lev = lev ? lev : "";
        values.order_type_open = "";
        values.order_type_close = "";
        values.order_type_openl = OTFO ? OTFO : "";
        values.order_type_closel = OTFC ? OTFC : "";
        values.order_type_opens = OTSO ? OTSO : "";
        values.order_type_closes = OTSC ? OTSC : "";
        values.bot_id = selectedBotID;
        values.coin = selectedbotSymbol ? selectedbotSymbol : "";
        console.log(values, 'values')
        axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API_PATH_BOT + "create",
            data: values,
        })
            .then(async function (response) {
                const res = await response.data;
                if (res) {
                    message.success(res.Success);
                    alert(res.Success)
                    getallrole();
                } else if (res.errors) {
                    alert(res.errors);
                }
            })
            .catch((err) => {
                message.error(err.response.data.Error)
                alert(err.response.data.Error)
            });
    };
    const handleupdateSubmit = (values) => {
        console.log("innnnnnn",values)
        values.username = username
        // const postdata = {
        //     username: username,
        //     exchange: "BINANCEFUTURES",
        //     api_name: currentupdaterecord.data.API_Name,
        //     base: 0,
        //     basel: currentupdaterecord.data.BaseOrderVolumeL,
        //     bases: currentupdaterecord.data.BaseOrderVolumeS,
        //     order_count: 0,
        //     order_countl: currentupdaterecord.data.OrderCountL,
        //     order_counts: currentupdaterecord.data.OrderCountS,
        //     vol_mul: 0,
        //     vol_mull: currentupdaterecord.data.OrderVolumeMultiplierL,
        //     vol_muls: currentupdaterecord.data.OrderVolumeMultiplierS,
        //     lev: currentupdaterecord.data.Leverage,
        //     coin: currentupdaterecord.data.Symbol,
        //     order_type_open: '',
        //     order_type_close: "",
        //     order_type_openl: currentupdaterecord.data.OrderTypeCloseL,
        //     order_type_closel: currentupdaterecord.data.OrderTypeOpenL,
        //     order_type_opens: currentupdaterecord.data.OrderTypeCloseL,
        //     order_type_closes: currentupdaterecord.data.OrderTypeOpenS,
        //     telegram_id: currentupdaterecord.data.TelegramChatID,
        //     bot_id: currentupdaterecord.data.BotID,
        // }
        const postdata = {
            username: username,
            exchange: "BINANCEFUTURES",
            api_name: values.API_Name,
            base: 0,
            basel: values.BaseOrderVolumeL,
            bases: values.BaseOrderVolumeS,
            order_count: 0,
            order_countl: values.OrderCountL,
            order_counts: values.OrderCountS,
            vol_mul: 0,
            vol_mull: values.OrderVolumeMultiplierL,
            vol_muls: values.OrderVolumeMultiplierS,
            lev: values.Leverage,
            coin: values.Symbol,
            order_type_open: '',
            order_type_close: "",
            order_type_openl: values.OrderTypeCloseL,
            order_type_closel: values.OrderTypeOpenL,
            order_type_opens: values.OrderTypeCloseL,
            order_type_closes: values.OrderTypeOpenS,
            telegram_id: values.TelegramChatID,
            bot_id: values.BotID,
        }
        
        axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API_PATH_BOT + "edit",
            data: postdata,
        })
            .then(async function (response) {
                const res = await response.data;
                if (res.Success) {
                    // alert("api updated successfully");
                    onClose()
                    getallrole();
                    message.success(res.Success)
                    alert(res.Success)
                } else if (res.errors) {
                    alert(res.errors);
                }
            })
            .catch((err) => {
                message.error(err.response.data.Error);
                alert(err.response.data.Error);
                getallrole();
            });
    };
    const getallrole = (e) => {

       
        let postData = { username: username }
        axios
            .post(process.env.REACT_APP_API_PATH_BOT + "bot_list", postData)
            .then((res) => {
                setactivtabledata(res.data.Data);
                console.log(res.data.Data)
            })
            .catch((err) => {

                alert(err.response.data);
            });
    };
    const [APIlist, setAPIlist] = useState([]);
    const getapiname = () => {

        let postData = { username: username }

        axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API_PATH + "api_list",
            data: postData,
        })
            .then(async function (response) {
                const res = await response.data.Data;
                const filteredRecords = res.filter(record => record.Exchange === "BINANCEFUTURES");
                if (res) {
                    setAPIlist(filteredRecords);
                }
            })
            .catch((err) => {
                alert(err);
            });
    };
    const handleChangeapi = (values) => {
        let userid = localStorage.getItem("userID");
        let postData = { user_id: userid };
        axios
            .post(process.env.REACT_APP_API_PATH + "getapiname", postData)
            .then((res) => {

                const selectedApi = res.data.data.find(
                    (api) => api.API_Name === values
                );
                if (selectedApi) {
                    setapikey(selectedApi.Key);
                    setapi_secret(selectedApi.Secret);
                    message.success(`${values} API selected`);
                }
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response);
                }
            });
    };

    const onSearchapi = (value) => {
    };
    const handlechangeOTopen = (value) => {
        setOTOM(value)
        setOTSO(value)
        setOTFO(value)
    };
    const handlechangeOTclose = (value) => {
        setOTCM(value)
        setOTSC(value)
        setOTFO(value)
    };
    const handlechangeOTSO = (value) => {
        setOTSO(value)
    };
    const handlechangeOTSC = (value) => {
        setOTSC(value)
    };
    const handlechangeOTFO = (value) => {
        setOTFO(value)
    };
    const handlechangeOTFC = (value) => {
        setOTFC(value)
    };
    const optionsapi = Array.isArray(APIlist) && APIlist.length > 0
        ? APIlist.map((d) => ({
            value: d.API_Name,
            label: d.API_Name,
        }))
        : [{
            value: "noapi",
            label: typeof APIlist === 'string' ? APIlist : "No API Added Yet",
        }];

    const getallSymbol = (e) => {
        axios
            .post(process.env.REACT_APP_API_PATH_BOT + "view_bot")
            .then((res) => {
                setSymbols(res.data.Data);
                if (res.data.Data) {
                    const matchingSymbols = res.data.Data.filter((d) => d.VarBotID === coinparams ? coinparams : "")
                    console.log(matchingSymbols)
                    setSelectedBotID(matchingSymbols[0].VarBotID)
                    setselectedbotSymbol(matchingSymbols[0].Symbol)
                    setSelectedSymbol(matchingSymbols[0].Name ? matchingSymbols[0].Name : "");

                }
            })
            .catch((err) => {
                console.log(err)
            });
    };

    const tooltipContent = (
        <div>
            <p>1.Open Telegram, search "userinfobot" or "@userinfobot," click Start for user details.</p>
            <p>2.Copy Userid, Update here.</p>
            <p>3.Open Telegram, search "FutureAPBot" or "@FutureAPBot," click Start to Activate FutureAPBot.</p>
        </div>
    );
    const optionsSymbols = Symbols.map((Symbol) => {
        return {
            value: Symbol.Symbol,
            label: Symbol.Symbol,
        };
    });
    const botIDOptions = Symbols
        .filter((Symbol) => Symbol.Symbol === selectedSymbol)
        .map((Symbol) => {
            return {
                value: Symbol.VarBotID,
                label: Symbol.VarBotID,
            };
        });
    const onswitchChange = (record, checked) => {
        if (record.Status === "PAUSED") {
            let postData = {
                username: username,
                bot_id: record.BotID,
                coin: record.Symbol,
                api_name: record.API_Name,
                exchange: 'BINANCEFUTURES'
            };
            axios({
                method: "POST",
                headers: { "Content-Type": "application/json" },
                url: process.env.REACT_APP_API_PATH_BOT + "start",
                data: postData,
            })
                .then(async function (response) {
                    const res = await response.data;
                    message.success(res.Success)
                    // toast.success(res.data);
                    getallrole();
                })
                .catch((err) => {
                    message.error(err.response.data.Error);
                    getallrole();
                });
        }
        if (record.Status === "ACTIVE") {
            let postData = {
                username: username,
                bot_id: record.BotID,
                coin: record.Symbol,
                api_name: record.API_Name,
                exchange: 'BINANCEFUTURES'
            };
            axios({
                method: "POST",
                headers: { "Content-Type": "application/json" },
                url: process.env.REACT_APP_API_PATH_BOT + "pause",
                data: postData,
            })
                .then(async function (response) {
                    const res = await response.data;
                    message.success(res.Success)
                    getallrole();
                })
                .catch((err) => {
                    message.error(err.response.data.Error);
                    getallrole();
                });
        }

    };
    const uniqueBotIDs = [...new Set(activtabledata.map(item => item.VarBotID))];
    const uniqueSymbols = [...new Set(activtabledata.map(item => item.Symbol))];
    const uniqueAPIs = [...new Set(activtabledata.map(item => item.API_Name))];
    const onChange = (pagination, filters, sorter, extra) => {
    };
    const columns = [

        {
            title: <Tooltip title="BotID">BID</Tooltip>,
            width: 150,
            dataIndex: "BotID",
            className: "text-center",
            key: "BotID",
            filters: uniqueBotIDs.map(id => ({ text: id, value: id })),
            onFilter: (value, record) => record.BotID.includes(value),
            filterMode: 'tree',
            filterSearch: false,
        },
        {
            title: <Tooltip title="API Name">A.N</Tooltip>,
            width: 150,
            dataIndex: "API_Name",
            key: "API_Name",
            className: "text-center",
            filters: uniqueAPIs.map(apiname => ({ text: apiname, value: apiname })),
            onFilter: (value, record) => record.API_Name.includes(value),
            filterMode: 'tree',
            filterSearch: false,
        },
        {
            title: <Tooltip title="Symbol">Symbol</Tooltip>,
            width: 150,
            dataIndex: "Symbol",
            key: "Symbol",
            filters: uniqueSymbols.map(Symbol => ({ text: Symbol, value: Symbol })),
            onFilter: (value, record) => record.Symbol.includes(value),
            className: "text-center",
        },
        {
            title: <Tooltip title="Status">Status</Tooltip>,
            width: 150,
            dataIndex: "Status",
            key: "Status",
            className: "text-center",
        },
        {
            title: <Tooltip title="Order Type">OTOL</Tooltip>,
            width: 150,
            dataIndex: "OrderTypeOpenL",
            key: "OrderTypeOpenL",
            className: "text-center",
        },
        {
            title: <Tooltip title="Order Type">OTOS</Tooltip>,
            width: 150,
            dataIndex: "OrderTypeOpenS",
            key: "OrderTypeOpenS",
            className: "text-center",
        },
        {
            title: <Tooltip title="Order Type">OTCL</Tooltip>,
            width: 150,
            dataIndex: "OrderTypeCloseL",
            key: "OrderTypeCloseL",
            className: "text-center",
        },
        {
            title: <Tooltip title="Order Type">OTCS</Tooltip>,
            width: 150,
            dataIndex: "OrderTypeCloseS",
            key: "OrderTypeCloseS",
            className: "text-center",
        },
        {
            title: <Tooltip title="Exchange">Exchange</Tooltip>,
            width: 150,
            dataIndex: "Exchange",
            key: "Exchange",
            className: "text-center",
        },
        ,
        {
            title: <Tooltip title="OrderVolumeMultiplierL">OVML</Tooltip>,
            width: 150,
            dataIndex: "OrderVolumeMultiplierL",
            key: "OrderVolumeMultiplierL",
            className: "text-center",
        },
        {
            title: <Tooltip title="OrderVolumeMultiplierS">OVMS</Tooltip>,
            width: 150,
            dataIndex: "OrderVolumeMultiplierS",
            key: "OrderVolumeMultiplierS",
            className: "text-center",
        },
        {
            title: <Tooltip title="Base Order Volume">BOVL</Tooltip>,
            width: 150,
            dataIndex: "BaseOrderVolumeL",
            key: "BaseOrderVolumeL",
            className: "text-center",
        },
        {
            title: <Tooltip title="Base Order Volume">BOVS</Tooltip>,
            width: 150,
            dataIndex: "BaseOrderVolumeS",
            key: "BaseOrderVolumeS",
            className: "text-center",
        },

        {
            title: <Tooltip title="OrderCountLong">OrderCount Long</Tooltip>,
            width: 150,
            dataIndex: "OrderCountL",
            key: "OrderCountL",
            className: "text-center",
        },
        {
            title: <Tooltip title="OrderCount Short">OrderCount Short</Tooltip>,
            width: 150,
            dataIndex: "OrderCountS",
            key: "OrderCountS",
            className: "text-center",
        },
        {
            title: <Tooltip title="Leverage">Leverage</Tooltip>,
            width: 150,
            dataIndex: "Leverage",
            key: "Leverage",
            className: "text-center",
        },
        {
            title: <Tooltip title="Telegram Chat ID">TCID</Tooltip>,
            width: 150,
            dataIndex: "TelegramChatID",
            key: "TelegramChatID",
            className: "text-center",
        },
        {
            title: "Action",
            dataIndex: "Edit",
            width: 150,
            className: "text-center",
            render: (text, record) => (
                <>
                    <div className="justify-center items-center flex">
                        <Button
                            className="border-none mb-0"
                            onClick={() => showDrawer({ data: record })}
                        >
                            <EditOutlined size={18} />
                        </Button>
                        <Button
                            className="border-none mb-0"
                            onClick={() => handleDelete(record)}
                        >
                            <DeleteOutlined size={18} />
                        </Button>
                        <Switch
                            offColor="#e7b30a"
                            checkedChildren="Start"
                            unCheckedChildren="Pause"
                            className="bg-[#e7b30a] "
                            checked={record.Status === "ACTIVE" ? true : false}
                            onChange={(checked) => onswitchChange(record, checked)}
                        />
                    </div>
                </>
            ),
        },
    ];
    const dataSource = activtabledata
        .filter(d => d.Exchange === "BINANCEFUTURES") // Filter data by Exchange value
        .map((d, index) => {
            return {
                key: index,
                BotID: d.VarBotID,
                API_Name: d.API_Name,
                Symbol: d.Symbol,
                BaseOrderVolumeL: d.BaseOrderVolumeL,
                BaseOrderVolumeS: d.BaseOrderVolumeS,
                OrderVolumeMultiplierL: d.VolumeMultiplierL,
                OrderVolumeMultiplierS: d.VolumeMultiplierS,
                Status: d.Status,
                OrderTypeOpenL: d.OrderTypeOpenL,
                OrderTypeOpenS: d.OrderTypeOpenS,
                OrderTypeCloseL: d.OrderTypeCloseL,
                OrderTypeCloseS: d.OrderTypeCloseS,
                Exchange: d.Exchange,
                OrderCountL: d.OrderCountL,
                OrderCountS: d.OrderCountS,
                Leverage: d.Leverage,
                TelegramChatID: d.ChatID,
            };
        });

    var calculate = (OC, OVM, BOV) => {
        var totalinv = 0
        var mul = 1
        for (let i = 0; i < OC; i++) {
            totalinv = totalinv + BOV * mul
            mul = mul * OVM
        }
        return totalinv;
    }
    const handleMainValues = (key, value) => {
        setFormData((prevData) => ({ ...prevData, [key]: value }));
        if (key == "totalinvestment") {
            settotalinvestment(value)
            setBOV(value * 2 / 100)
            setBOVF(value * 2 / 100)
            setOC(25)
            setOCF(25)
            setOVM(1)
            setOVMF(1)
        }
        var totalinv = 0
        var totalinvF = 0
        if (key == "base") {
            setBOV(value)
            totalinv = calculate(OC, OVM, value)
            settotalinvestmentBOVL(totalinv)
        }
        else if (key == "baseF") {
            setBOVF(value)
            totalinv = calculate(OC, OVM, BOV)
            totalinvF = calculate(OCF, OVMF, value)
            settotalinvestmentBOVS(totalinv)
        }
        else if (key == "order_count") {
            setOC(value)
            totalinv = calculate(value, OVM, BOV)
            totalinvF = calculate(OCF, OVMF, value)
            setocsinv(totalinv)
        }
        else if (key == "order_countF") {
            setOCF(value)
            totalinv = calculate(OC, OVM, BOV)
            totalinvF = calculate(value, OVMF, value)
            setocfinv(totalinv)
        }
        else if (key == "vol_mul") {
            setOVM(value)
            totalinv = calculate(OC, value, BOV)
            totalinvF = calculate(OCF, OVMF, value)
            setvominvS(totalinv)
        }
        else if (key == "vol_mulF") {
            setOVMF(value)
            totalinv = calculate(OC, OVM, BOV)
            totalinvF = calculate(OCF, value, BOVF)
            setvominvF(totalinv)
        }
        else if (key == "lev") {
            setlev(value)
        }
        settotalinvestment(totalinv + totalinvF)
    };
    useEffect(() => {
        getallrole();
        getallSymbol();
        getapiname();

    }, []);
    return (
        <>
            <div className="py-4 h-screen ">
                <Form onFinish={handleSubmit}>
                    <div className="">
                        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-5 border-solid border px-2 rounded-md mx-1 border-[#d9d9d9] pb-4 bg-second items-center">
                            <div>
                                <div className="flex justify-center">
                                    <label className="block text-slate-50 mt-1" htmlFor="name">
                                        API NAME(A.N)
                                    </label>
                                    <Tooltip title="API NAME(A.N)">
                                        <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer " />
                                    </Tooltip>
                                </div>
                                <FormItem name="api_name" className="w-full" rules={[
                                    {
                                        required: true,
                                        message: 'Please select an API name!',
                                    },
                                ]}>
                                    <Select
                                        onChange={handleChangeapi}
                                        showSearch
                                        onSearch={onSearchapi}
                                        className="rounded-md   w-full"
                                        id="option"
                                        name="option"
                                        options={optionsapi ? optionsapi : ""}
                                    ></Select>
                                </FormItem>
                            </div>
                            <div>
                                <div className="flex justify-center">
                                    <label className="block text-slate-50 mt-1" htmlFor="name">
                                        Symbols Name
                                    </label>
                                    <Tooltip title="Symbols">
                                        <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer " />
                                    </Tooltip>
                                </div>
                                <FormItem name="Symbol"
                                    rules={[
                                        {
                                            required: !selectedbotSymbol,
                                            message: 'Please select an Symbol!',
                                        },
                                    ]}
                                >
                                    <p hidden>{selectedbotSymbol}</p>
                                    <Input
                                        className="bg-white"
                                        type="text"
                                        required
                                        value={selectedbotSymbol ? selectedbotSymbol : ""}
                                        placeholder="Select Bot"
                                        disabled={selectedbotSymbol ? false : true}
                                        onChange={(value) => handleupdatechange("abc", value)}
                                    />
                                </FormItem>
                            </div>
                            <div>
                                <div className="flex justify-center">
                                    <label className="block text-slate-50 mt-1" htmlFor="name">
                                        BOT NAME
                                    </label>
                                    <Tooltip title="Symbols">
                                        <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer " />
                                    </Tooltip>
                                </div>
                                <FormItem name="Symbol"
                                    rules={[
                                        {
                                            required: !selectedSymbol,
                                            message: 'Please select an Symbol!',
                                        },
                                    ]}
                                >
                                    <p hidden>{selectedSymbol}</p>
                                    <Input
                                        className="bg-white"
                                        type="text"
                                        required
                                        value={selectedSymbol ? selectedSymbol : ""}
                                        placeholder="Select Bot"
                                        disabled={selectedSymbol ? false : true}
                                        onChange={(value) => handleupdatechange("abc", value)}
                                    />
                                </FormItem>
                            </div>
                            <div>
                                <div className="flex justify-center">
                                    <label className="block text-slate-50 mt-1" htmlFor="name">
                                        Trade Type
                                    </label>
                                    <Tooltip title="Symbols">
                                        <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer " />
                                    </Tooltip>
                                </div>
                                <FormItem name=""
                                    rules={[
                                        {
                                            required: !tradetypeparams,
                                            message: 'Please select Trade type',
                                        },
                                    ]}
                                >
                                    <p hidden>{tradetypeparams}</p>
                                    <Input
                                        className="bg-white"
                                        type="text"
                                        required
                                        value={tradetypeparams ? tradetypeparams : ""}
                                        placeholder="Select Bot"
                                        disabled={tradetypeparams ? false : true}
                                        onChange={(value) => handleupdatechange("abc", value)}
                                    />
                                </FormItem>
                            </div>
                            <div hidden>
                                <div className="flex justify-center">
                                    <label className="block text-slate-50 mt-1" htmlFor="name">
                                        Bot ID
                                    </label>
                                    <Tooltip title="prompt text">
                                        <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                    </Tooltip>
                                </div>
                                <FormItem name="bot_id">
                                    <p hidden>{selectedBotID}</p>
                                    <Select
                                        name="bot_id"
                                        className="p-1 rounded-md mb-0 w-full"
                                        value={selectedBotID}
                                        disabled={!selectedSymbol}
                                        defaultValue={selectedBotID}
                                        options={botIDOptions}
                                    ></Select>
                                </FormItem>
                            </div>
                        </div>
                        <p className="text-[#fff] font-bold text-center text-lg">Calculation</p>
                        <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-4 px-4 justify-between items-center">
                            <div>
                                <div className="flex justify-center">
                                    <label className="block text-slate-50 text-[#e7b30a] mt-1" htmlFor="name">
                                        Bot Alloted Fund
                                    </label>
                                    <Tooltip title="size of the First trade">
                                        <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                    </Tooltip>
                                </div>
                                <FormItem name="totalinvestment" >
                                    <p hidden>{totalinvestment ? totalinvestment : "0.00"}</p>
                                    <InputNumber
                                        className="w-full"
                                        placeholder="Enter Base Order Volume"
                                        onChange={(value) => handleMainValues('totalinvestment', value)}
                                        required
                                        value={totalinvestment ? totalinvestment : "0.00"}
                                        type="number"
                                    />
                                </FormItem>
                            </div>
                            <div>
                                <div>
                                    <div className="flex justify-center">
                                        <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                                            Telegram Chat ID (TCID)
                                        </label>
                                        <Tooltip title={tooltipContent}>
                                            <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <FormItem name="telegram_id">
                                    <Input
                                        className=""
                                        type="text"
                                        placeholder="Enter Your Telegram Chat ID"
                                        required
                                    />
                                </FormItem>
                            </div>
                            <div>
                                <div>
                                    <div className="flex justify-center">
                                        <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                                            open Order Type(OT)
                                        </label>
                                        <Tooltip title="Order Type">
                                            <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <FormItem name="order_type">
                                    <p hidden>{OTOM}</p>
                                    <Select defaultValue="MARKET" value={OTOM} onChange={handlechangeOTopen} className="rounded-md   w-[100%] " required>
                                        <Option >--Select Order Type--</Option>
                                        <Option value="MARKET">MARKET</Option>
                                        <Option value="LIMIT">LIMIT</Option>
                                    </Select>
                                </FormItem>
                            </div>
                            <div>
                                <div>
                                    <div className="flex justify-center">
                                        <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                                            close Order Type(OT)
                                        </label>
                                        <Tooltip title="Order Type">
                                            <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <FormItem name="order_type" >
                                    <p hidden>{OTCM}</p>
                                    <Select defaultValue="MARKET" value={OTCM} onChange={handlechangeOTclose} className="rounded-md   w-[100%] " required>
                                        <Option >--Select Order Type--</Option>
                                        <Option value="MARKET">MARKET</Option>
                                        <Option value="LIMIT">LIMIT</Option>
                                    </Select>
                                </FormItem>
                            </div>
                            <div>
                                <div className="flex justify-center">
                                    <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                                        Leverage
                                    </label>
                                    <Tooltip title="amplify potential returns or losses">
                                        <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                    </Tooltip>
                                </div>
                                <div className="flex justify-center">
                                    <FormItem name="lev">
                                        <p hidden>{lev ? lev : ""}</p>
                                        <InputNumber
                                            className="w-[260px]"
                                            placeholder="Enter Your Leverage"
                                            required
                                            type="number"
                                            value={lev ? lev : ""}
                                            onChange={(value) => handleMainValues('lev', value)}
                                        />
                                    </FormItem>
                                    <Button type="primary" className="mx-1" onClick={toggleAdvancedSettings} hidden>
                                        <FiSettings className="color-primary" />
                                        {/* {showAdvancedSettings ? 'Hide Advanced Settings' : 'Show Advanced Settings'} */}
                                    </Button>
                                </div>

                            </div>

                        </div>

                        {showAdvancedSettings && (
                            <>
                                <p className="text-[#fff] font-bold text-center text-lg">Long</p>
                                <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-4  px-4 justify-between items-center">
                                    <div>
                                        <div className="flex justify-center">
                                            <label className="block text-slate-50 text-[#e7b30a] mt-1" htmlFor="name">
                                                Base Order Volume ($) (BOV)
                                            </label>
                                            <Tooltip title="size of the First trade">
                                                <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                            </Tooltip>
                                        </div>
                                        <FormItem name="base" >
                                            <p hidden>{BOV ? BOV : ""}</p>
                                            <InputNumber
                                                className="w-full"
                                                placeholder="Enter Base Order Volume"
                                                onChange={(value) => handleMainValues('base', value)}
                                                required
                                                value={BOV ? BOV : ""}
                                                type="number"
                                            />
                                        </FormItem>
                                    </div>
                                    <div>
                                        <div>
                                            <div className="flex justify-center">
                                                <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                                                    open  Order Type(OT)
                                                </label>
                                                <Tooltip title="Order Type">
                                                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <FormItem name="order_type_S" >

                                            <p hidden>{OTSO}</p>
                                            <Select defaultValue="MARKET" value={OTSO} onChange={handlechangeOTSO} className="rounded-md   w-[100%] " required>
                                                <Option >--Select Order Type--</Option>
                                                <Option value="MARKET">MARKET</Option>
                                                <Option value="LIMIT">LIMIT</Option>
                                            </Select>
                                        </FormItem>
                                    </div>
                                    <div>
                                        <div>
                                            <div className="flex justify-center">
                                                <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                                                    close  Order Type(OT)
                                                </label>
                                                <Tooltip title="Order Type">
                                                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <FormItem name="order_type_S" >
                                            <p hidden>{OTSC}</p>
                                            <Select defaultValue="MARKET" value={OTSC} onChange={handlechangeOTSC} className="rounded-md   w-[100%] " required>
                                                <Option >--Select Order Type--</Option>
                                                <Option value="MARKET">MARKET</Option>
                                                <Option value="LIMIT">LIMIT</Option>
                                            </Select>
                                        </FormItem>
                                    </div>
                                    <div>
                                        <div className="flex justify-center">
                                            <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                                                Order Count(OC)
                                            </label>
                                            <Tooltip title="No. subsequent order">
                                                <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                            </Tooltip>
                                        </div>
                                        <FormItem name="order_count">
                                            <p hidden>{OC}</p>
                                            <InputNumber
                                                className="w-full"
                                                onChange={(value) => handleMainValues('order_count', value)}
                                                value={OC}
                                                placeholder="Enter your order count"
                                                required
                                                type="number"
                                            />
                                        </FormItem>
                                    </div>
                                    <div>
                                        <div>
                                            <div className="flex justify-center">
                                                <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                                                    Order Volume Multiplier
                                                </label>
                                                <Tooltip title={"Investment(x) multiplier of Each subsequent order.(OVM)"}>
                                                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <FormItem name="vol_mul">
                                            <p hidden>{OVM ? OVM : ""}</p>
                                            <InputNumber
                                                className="w-full"
                                                placeholder="Enter Your Order Volume Multiplier"
                                                required
                                                type="number"
                                                onChange={(value) => handleMainValues('vol_mul', value)}
                                                value={OVM ? OVM : ""}
                                            />
                                        </FormItem>
                                    </div>
                                </div>
                                <p className="text-[#fff] font-bold text-center text-lg">Short</p>
                                <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-4  px-4 justify-between items-center">
                                    <div>
                                        <div className="flex justify-center">
                                            <label className="block text-slate-50 text-[#e7b30a] mt-1" htmlFor="name">
                                                Base Order Volume ($) (BOV)
                                            </label>
                                            <Tooltip title="size of the First trade">
                                                <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                            </Tooltip>
                                        </div>
                                        <FormItem name="baseF" >
                                            <p hidden>{BOVF ? BOVF : ""}</p>
                                            <InputNumber
                                                className="w-full"
                                                placeholder="Enter Base Order Volume"
                                                onChange={(value) => handleMainValues('baseF', value)}
                                                required
                                                value={BOVF ? BOVF : ""}
                                                type="number"
                                            />
                                        </FormItem>
                                    </div>
                                    <div>
                                        <div>
                                            <div className="flex justify-center">
                                                <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                                                    Open Order Type(OT)
                                                </label>
                                                <Tooltip title="Order Type">
                                                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <FormItem name="order_type_F" >
                                            <p hidden>{OTFO}</p>

                                            <Select defaultValue="MARKET" value={OTFO} onChange={handlechangeOTFO} className="rounded-md   w-[100%] " required>
                                                <Option >--Select Order Type--</Option>
                                                <Option value="MARKET">MARKET</Option>
                                                <Option value="LIMIT">LIMIT</Option>
                                            </Select>
                                        </FormItem>
                                    </div>
                                    <div>
                                        <div>
                                            <div className="flex justify-center">
                                                <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                                                    Close Order Type(OT)
                                                </label>
                                                <Tooltip title="Order Type">
                                                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <FormItem name="order_type_F" >
                                            <p hidden>{OTFC}</p>

                                            <Select defaultValue="MARKET" value={OTFC} onChange={handlechangeOTFC} className="rounded-md   w-[100%] " required>
                                                <Option >--Select Order Type--</Option>
                                                <Option value="MARKET">MARKET</Option>
                                                <Option value="LIMIT">LIMIT</Option>
                                            </Select>
                                        </FormItem>
                                    </div>
                                    <div>
                                        <div className="flex justify-center">
                                            <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                                                Order Count(OC)
                                            </label>
                                            <Tooltip title="No. subsequent order">
                                                <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                            </Tooltip>
                                        </div>
                                        <FormItem name="order_countF">
                                            <p hidden>{OCF}</p>
                                            <InputNumber
                                                className="w-full"
                                                onChange={(value) => handleMainValues('order_countF', value)}
                                                value={OCF ? OCF : ""}
                                                placeholder="Enter your order count"
                                                required
                                                type="number"
                                            />
                                        </FormItem>
                                    </div>
                                    <div>
                                        <div>
                                            <div className="flex justify-center">
                                                <label className="block text-slate-50 mt-1 text-[#e7b30a]" htmlFor="name">
                                                    Order Volume Multiplier
                                                </label>
                                                <Tooltip title={"Investment(x) multiplier of Each subsequent order.(OVM)"}>
                                                    <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <FormItem name="vol_mulF">
                                            <p hidden>{OVMF ? OVMF : ""}</p>
                                            <InputNumber
                                                className="w-full"
                                                placeholder="Enter Your Order Volume Multiplier"
                                                required
                                                type="number"
                                                onChange={(value) => handleMainValues('vol_mulF', value)}
                                                value={OVMF ? OVMF : ""}
                                            />
                                        </FormItem>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="w-full px-2 flex items-center justify-center">
                            <input
                                type="submit"
                                onClick={handleSubmit}
                                className="px-8 py-2 rounded-lg border-2 w-max cursor-pointer font_primary  hover:border-second font_secondary text-second hover:text-second bg-main hover:bg-[white] focus:outline-0"
                            />
                        </div>
                    </div>
                </Form>
                <div className="mx-1 mt-3">
                    <div >
                        <Table
                            size="small"
                            dataSource={dataSource}
                            columns={columns}
                            scroll={{ x: 1500 }}
                            pagination={{
                                defaultPageSize: 20,
                                showSizeChanger: true,
                                pageSizeOptions: ["10", "20", "30"],
                            }}
                            onChange={onChange}
                        />
                        <Drawer
                            title="Basic Drawer"
                            placement="right"
                            onClose={onClose}
                            open={open}
                        >
                            <Form form={form1} onFinish={handleupdateSubmit}>

                                <div className="">
                                    <div>
                                        <label className="block text-slate-50" htmlFor="mobile">
                                            API NAME(A.N)
                                        </label>
                                        <FormItem name="API_Name">
                                            <Input
                                                className="mb-0 w-full"
                                                name="API_Name"
                                                placeholder="Enter your api name"
                                                onChange={(value) => handleupdatechange("abc", value)}
                                                required
                                                disabled
                                            />
                                        </FormItem>
                                    </div>
                                    <div>
                                    </div>
                                    <div>
                                        <label className="block text-slate-50">Symbols</label>
                                        <FormItem name="Symbol">
                                            <Input

                                                className="p-1 rounded-md  mb-0  w-full"
                                                onChange={(value) => handleupdatechange("abc", value)}
                                                required
                                                disabled
                                            ></Input>
                                        </FormItem>
                                    </div>
                                    <div>
                                        <label className="block text-slate-50 mt-4">
                                            BotID
                                        </label>
                                        <FormItem name="BotID">
                                            <Input
                                                className=""
                                                type="text"
                                                disabled
                                                placeholder="Enter Your Telegram BotID"
                                                required
                                                onChange={(value) => handleupdatechange("abc", value)}
                                            />
                                        </FormItem>
                                        <label className="block text-slate-50 mt-4" htmlFor="option">
                                            Trade Type
                                        </label>
                                        <FormItem name="trade_type"
                                            // rules={[
                                            //     {
                                            //         required: !tradetypeparams,
                                            //         message: 'Please select Trade type',
                                            //     },
                                            // ]}
                                        >
                                            <p hidden>{tradetypeparams}</p>
                                            <Input
                                                className="bg-white"
                                                type="text"
                                                required
                                                value='BINANCEFUTURES'
                                                placeholder="Select Bot"
                                                disabled={true}
                                                onChange={(value) => handleupdatechange("abc", value)}
                                            />
                                        </FormItem>
                                        <label className="block text-slate-50 mt-4" htmlFor="option">
                                            Order Type Open Long
                                        </label>
                                        <FormItem name="OrderTypeOpenL">
                                            <Select
                                                className="rounded-md  w-[100%] "
                                                name="option"
                                                required
                                            >
                                                <option value="">--Select Order Type--</option>
                                                <option value="MARKET">MARKET</option>
                                                <option value="LIMIT">LIMIT</option>
                                            </Select>
                                        </FormItem>
                                        <label className="block text-slate-50 mt-4" htmlFor="option">
                                            Order Type Open Short
                                        </label>
                                        <FormItem name="OrderTypeOpenS">
                                            <Select
                                                className="rounded-md  w-[100%] "
                                                name="option"
                                                required
                                            >
                                                <option value="">--Select Order Type--</option>
                                                <option value="MARKET">MARKET</option>
                                                <option value="LIMIT">LIMIT</option>
                                            </Select>
                                        </FormItem>
                                        <label className="block text-slate-50 mt-4" htmlFor="option">
                                            Order Type  Close Long
                                        </label>
                                        <FormItem name="OrderTypeCloseL">
                                            <Select
                                                className="rounded-md  w-[100%] "
                                                name="option"
                                                required
                                            >
                                                <option value="">--Select Order Type--</option>
                                                <option value="MARKET">MARKET</option>
                                                <option value="LIMIT">LIMIT</option>
                                            </Select>
                                        </FormItem>
                                        <label className="block text-slate-50 mt-4" htmlFor="option">
                                            Order Type Close Short
                                        </label>
                                        <FormItem name="OrderTypeCloseS">
                                            <Select
                                                className="rounded-md  w-[100%] "
                                                name="option"
                                                required
                                            >
                                                <option value="">--Select Order Type--</option>
                                                <option value="MARKET">MARKET</option>
                                                <option value="LIMIT">LIMIT</option>
                                            </Select>
                                        </FormItem>
                                        <label className="block text-slate-50  mt-4" htmlFor="option">
                                            OrderCount Long
                                        </label>
                                        <FormItem name="OrderCountL">
                                            <InputNumber onChange={(value) => handleupdatechange("OrderCountL", value)} className="w-full" />
                                        </FormItem>
                                        <label className="block text-slate-50  mt-4" htmlFor="option">
                                            OrderCount Short
                                        </label>
                                        <FormItem name="OrderCountS">
                                            <InputNumber onChange={(value) => handleupdatechange("OrderCountS", value)} className="w-full" />
                                        </FormItem>
                                        <label className="block text-slate-50 mt-4  ">
                                            Base Order Volume Long ($) (BOV)
                                        </label>
                                        <FormItem name="BaseOrderVolumeL">
                                            <InputNumber
                                                className="w-full"
                                                placeholder="Enter Base Order Volume"
                                                required
                                                type="number"
                                                onChange={(value) => handleupdatechange("BaseOrderVolumeL", value)}
                                            />
                                        </FormItem>
                                        <label className="block text-slate-50 mt-4  ">
                                            Base Order Volume Short ($) (BOV)
                                        </label>
                                        <FormItem name="BaseOrderVolumeS">
                                            <InputNumber
                                                className="w-full"
                                                placeholder="Enter Base Order Volume"
                                                required
                                                type="number"
                                                onChange={(value) => handleupdatechange("BaseOrderVolumeS", value)}
                                            />
                                        </FormItem>
                                    </div>
                                    <div>
                                        <label className="block text-slate-50  mt-4">
                                            Order Volume Multiplier Long (OVM)
                                        </label>
                                        <FormItem name="OrderVolumeMultiplierL">
                                            <InputNumber
                                                className="w-full"
                                                placeholder="Enter Your Order Volume Multiplier"
                                                onChange={(value) => handleupdatechange("OrderVolumeMultiplierL", value)}
                                                required
                                                type="number"
                                            />
                                        </FormItem>
                                    </div>
                                    <div>
                                        <label className="block text-slate-50  mt-4">
                                            Order Volume Multiplier Short (OVM)
                                        </label>
                                        <FormItem name="OrderVolumeMultiplierS">
                                            <InputNumber
                                                className="w-full"
                                                placeholder="Enter Your Order Volume Multiplier"
                                                onChange={(value) => handleupdatechange("OrderVolumeMultiplierS", value)}
                                                required
                                                type="number"
                                            />
                                        </FormItem>
                                    </div>
                                    <div>
                                        <label className="block text-slate-50 mt-4">
                                            Telegram Chat ID(TCID)
                                        </label>
                                        <FormItem name="TelegramChatID">
                                            <Input
                                                className=""
                                                type="text"
                                                placeholder="Enter Your Telegram Chat ID"
                                                required
                                                onChange={(value) => handleupdatechange("abc", value)}
                                            />
                                        </FormItem>
                                        <label
                                            className="block text-slate-50 mt-4  "
                                            htmlFor="option"
                                        >
                                            Leverage
                                        </label>
                                        <FormItem name="Leverage">
                                            <InputNumber
                                                className="w-full"
                                                placeholder="Enter Your Order Volume Multiplier"
                                                required
                                                type="number"
                                            />
                                        </FormItem>
                                    </div>
                                    <div>
                                        <div className="flex justify-center">
                                            <label className="block text-slate-50 text-[#e7b30a] mt-1" htmlFor="name">
                                                Bot Alloted Fund
                                            </label>
                                            <Tooltip title="size of the First trade">
                                                <ExclamationCircleOutlined className="mt-2 mx-1 cursor-pointer text-[#e7b30a]" />
                                            </Tooltip>
                                        </div>
                                        <FormItem name="totalinvestment" >
                                            <p hidden>{totalinvestmentupdated ? totalinvestmentupdated : "0.00"}</p>
                                            <InputNumber
                                                className="w-full"
                                                placeholder="Enter Base Order Volume"
                                                onChange={(value) => handleupdatechange('totalinvestment', value)}
                                                required
                                                value={totalinvestmentupdated ? totalinvestmentupdated : "0.00"}
                                                type="number"
                                            />
                                        </FormItem>
                                    </div>
                                </div>
                                <div className="w-full px-2 flex items-center justify-center">
                                    <input
                                        type="submit"
                                        handleSubmit={handleupdateSubmit}
                                        className="px-8 py-2 rounded-lg border-2 w-max cursor-pointer font_primary  hover:border-second font_secondary text-second hover:text-second bg-main hover:bg-[white] focus:outline-0"
                                    />
                                </div>
                            </Form>
                        </Drawer>
                    </div>
                </div>
            </div>
        </>
    );
}
export default FutureBasicBot;
