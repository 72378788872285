import { DeleteOutlined } from '@ant-design/icons';
import Switch from "react-switch";

import { Button, Card, Form, Input, Select, Table, Tooltip, message } from 'antd'
import { InputNumber } from 'antd';

import FormItem from 'antd/es/form/FormItem'
import axios from 'axios';
import BinanceImage from '../img/binance.png';
import PaperTradeImage from '../img/trading.png';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { small } from "../Components/SmallText";
const { Option } = Select;

function Addapi() {
  let username = localStorage.getItem('username')
  const [dataSource, setDataSource] = useState([]);
  const columns = [
    {
      title: 'Created At',
      dataIndex: 'DateTime',
      key: 'DateTime',
    },
    {
      title: 'API Name',
      dataIndex: 'API_Name',
      key: 'API_Name',
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
    },
    {
      title: 'API Key',
      dataIndex: 'keyVal',
      key: 'keyVal',
    },
    {
      title: 'Secret Key',
      dataIndex: 'secretVal',
      key: 'secretVal',
    },
    {
      title: 'Initial Balance',
      dataIndex: 'Added',
      key: 'Added',
    },
    {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'Balance',
    },

    {
      title: 'Bot Alloted Fund ',
      dataIndex: 'Invested',
      key: 'Invested',
    },
    {
      title: 'Exchange ',
      dataIndex: 'Exchange',
      key: 'Exchange',
    },
    {
      title: 'Profit(%)',
      dataIndex: 'profitper',
      key: 'profitper',
    },

    {
      title: "Status",
      dataIndex: "Status",
      className: "text-center",
      render: (text, record) => (
        <>
          <div className='justify-center items-center flex'>
            <div>
              <Switch
                className="bg-second disable-keyboard-focus"
                tabIndex={-1}
                aria-hidden="true"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                checked={record.Status === "ACTIVE" ? true : false}
                onChange={(checked) => onswitchChange(record, checked)}
              />
            </div>
          </div>
        </>)
    },
    {
      title: "Action",
      dataIndex: "Action",
      className: "text-center",
      render: (text, record) => (
        <>
          <div className='justify-center items-center flex'>
            <div>
              <Button className="border-none" onClick={() => handleDelete(record)} >
                <DeleteOutlined size={18} />
              </Button>
            </div>
          </div>
        </>)
    },
  ];
  const handleDelete = (record) => {

    let username = localStorage.getItem("username")
    let postData = { username: username, api_name: record.API_Name, type: record.Type, status: record.Status }
  
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "delete_api",
      data: postData,
    })
      .then(async function (response) {
        const res = await response.data;
        gettabledata()
        if (res) {
          message.success(res.Success);
          gettabledata();
        } else if (res.errors) {
          message.error(res.Error.non_field_errors);
        }
      })
      .catch((err) => {
        message.error(err.response.data.Error);
      });
  };

  const onswitchChange = (record, checked) => {
    // Prevent arrow key events

    let username = localStorage.getItem("username");
    if (record.Status === "INACTIVE") {
      let postData = {
        username: username,
        api_name: record.API_Name,
        type: record.Type,
        status: "ACTIVE"
      };

      axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH + "change_api_status",
        data: postData,
      })
        .then(async function (response) {
          const res = await response.data;
          message.success(res.Data)
          // toast.success(res.data);
          gettabledata();
        })
        .catch((err) => {
          message.error(err.response.data.Error);
          gettabledata();
        });
    }
    if (record.Status === "ACTIVE") {
      let postData = {
        username: username,
        api_name: record.API_Name,
        type: record.Type,
        status: "INACTIVE"
      };

      axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH + "change_api_status",
        data: postData,
      })
        .then(async function (response) {
          const res = await response.data;
          message.success(res.Data)
          gettabledata();
        })
        .catch((err) => {
          message.error(err.response.data.Error);
          gettabledata();
        });
    }
  };
  const gettabledata = () => {

    let postData = { username: username }
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "api_list",
      data: postData,
    })
      .then(async function (response) {
        const res = await response.data;
        const data = res.Data
        if (data) {
          const newDataSource = data.map((item, index) => {
            const profitPercentage = ((item.Balance - item.Added) * 100 / item.Added).toFixed(4);
            const profitColor = profitPercentage >= 0 ? "green" : "red";
            return {
              key: index,
              API_Name: item.API_Name,
              Username: item.Username,
              Type: item.Type,
              Balance: parseFloat(item.Balance).toFixed(2),
              Status: item.Status,
              Added: parseFloat(item.Added).toFixed(2),
              Invested: item.Invested,
              Exchange: item.Exchange,
              DateTime: item.DateTime,
              // profitper: ((item.Balance - item.Added) * 100 / item.Invested).toFixed(4),
              profitper: <span style={{ color: profitColor }}>{profitPercentage}</span>,
              keyVal: <Tooltip title={item.Key}><span>{small(item.Key)}</span></Tooltip>,
              secretVal: <Tooltip title={item.Secret}><span>{small(item.Secret)}</span></Tooltip>,
            };
          });
          setDataSource(newDataSource);
        }
        else if (res.errors) {
        
        }
      })
      .catch((err) => {
      });
  };
  const handleSubmit = (values) => {
    values.username = username
    values.balance = 0
    values.type = "LIVE"
    let postData = { values }
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "create_api",
      data: values,
    })
      .then(async function (response) {
        const res = await response.data;
   
        if (res.Data) {
          message.success(res.Data)
          gettabledata()
          let data = res.Data
          const newDataSource = data.map((item, index) => {
            return {
              key: index,
              apiName: item.API_Name,
              userId: item.UserID,
              keyVal: item.Key,
              secretVal: item.Secret,
            };
          });
          setDataSource(newDataSource);
        } else if (res.errors) {
         
        }
      })
      .catch((err) => {
        message.error(err.response.data.Error);
      });
  };
  const handleSubmitpaper = (values) => {
    values.username = username
    values.key = ""
    values.secret = ""
    values.type = "PAPER"
    let postData = { values }
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "create_api",
      data: values,
    })
      .then(async function (response) {
        const res = await response.data;
        if (res) {
          gettabledata()
          message.success(res.Data)
          alert(res.Data)
          let data = res.data
          const newDataSource = data.map((item, index) => {
            return {
              key: index,
              apiName: item.API_Name,
              userId: item.UserID,
              keyVal: item.Key,
              secretVal: item.Secret,
            };
          });
          setDataSource(newDataSource);
        } else if (res.errors) {
         
        }
      })
      .catch((err) => {
        message.error(err.response.data.Error);
        alert(err.response.data.Error);

      });
  };
  const handlerefresh = () => {
    const uname = localStorage.getItem('username')
    const postdata = {
      username: uname
    }
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "view_bal",
      data: postdata,
    })
      .then(async function (response) {
        gettabledata()
        const res = response.data.Success;
       
      })
      .catch((err) => {

      });
  };


  useEffect(() => {
    gettabledata()
  }, [])



  return (
    <>
      <div className="h-screen">
        <div className=" mt-2 ">

          <div className="grid grid-cols-2 gap-5">
            <Form onFinish={handleSubmit} >

              <div className="mt-5">
                <div className="rounded-lg  text-center bg-third text-[#000]  ">
                  <div className='border border-solid border-b-2 border-t-0 border-r-0 border-l-0'>
                    <div className='flex justify-between  items-center px-3 py-2'>
                      <div className="text-left font-bold text-xl ">
                        Binance Trade
                      </div>
                      <img src={BinanceImage} className='w-10 animate-pulse' />
                    </div>
                  </div>

                  <div className="p-2">
                    <div className="grid grid-cols-4 mt-3">

                      <div>
                        <label className="block text-slate-50" htmlFor="mobile">
                          API Name
                        </label>
                        <FormItem className='mx-1' name="api_name">
                          <Input
                            className="mb-0"
                            type="text"
                            placeholder="Enter your api name"
                            required
                          />
                        </FormItem>
                      </div>
                      <div>
                        <label className="block text-slate-50" htmlFor="name">
                          API Key
                        </label>
                        <FormItem className='mx-1' name="key">
                          <Input
                            className="mb-0"
                            type="text"
                            placeholder="Enter your api key"
                            required
                          />
                        </FormItem>
                      </div>
                      <div>
                        <label className="block text-slate-50" htmlFor="mobile">
                          API Secret Key
                        </label>
                        <FormItem className='mx-1' name="secret">
                          <Input
                            className="mb-0"
                            type="text"
                            placeholder="Enter your api secret"
                            required

                          />
                        </FormItem>
                      </div>
                      <div>
                        <label className="block text-slate-50" htmlFor="mobile">
                          Trade type
                        </label>
                        <FormItem className='mx-1' name="exchange">
                          <Select
                            placeholder="Select a option and change input text above"
                            // onChange={onGenderChange}
                            allowClear
                          >
                            <Option value="BINANCESPOT">SPOT</Option>
                            <Option value="BINANCEFUTURES">FUTURES</Option>
                          </Select>
                        </FormItem>
                      </div>
                    </div>
                    <div className="flex justify-center mt-1">
                      <FormItem >
                        <Button htmlType="submit" className='bg-bbg text-third border-0 hover:bg-bbg/90'>Submit</Button>
                      </FormItem>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            <Form onFinish={handleSubmitpaper} >
              <div className="mt-5">
                <div className="rounded-lg  text-center bg-third text-[#000]  ">
                  <div className='border border-solid border-b-2 border-t-0 border-r-0 border-l-0'>
                    <div className='flex justify-between  items-center px-3 py-2'>
                      <div className="text-left font-bold text-xl ">
                        Paper Trade
                      </div>
                      <img src={PaperTradeImage} className='w-10 animate-pulse' />
                    </div>
                  </div>

                  <div className="p-2">
                    <div className="grid grid-cols-3 mt-2">
                      <div>
                        <label className="block text-slate-50" htmlFor="mobile">
                          API Name
                        </label>
                        <FormItem className='mx-2' name="api_name">
                          <Input
                            className="mb-0"
                            type="text"
                            placeholder="Enter your api name"
                            required
                          />
                        </FormItem>
                      </div>
                      <div>
                        <label className="block text-slate-50" htmlFor="mobile">
                          Balance
                        </label>
                        <FormItem className='mx-2' name="balance">
                          <InputNumber
                            className="mb-0 w-full"
                            type="number"
                            placeholder="Enter your balance"
                            required

                          />
                        </FormItem>
                      </div>
                      <div>
                        <label className="block text-slate-50" htmlFor="mobile">
                          Trade type
                        </label>
                        <FormItem className='mx-1' name="exchange">
                          <Select
                            placeholder="Select a option and change input text above"
                            // onChange={onGenderChange}
                            allowClear
                          >
                            <Option value="BINANCESPOT">SPOT</Option>
                            <Option value="BINANCEFUTURES">FUTURES</Option>
                          </Select>
                        </FormItem>
                      </div>
                    </div>
                    <div className="flex justify-center mt-2">
                      <FormItem >
                        <Button htmlType="submit" className='bg-bbg text-third border-0 hover:bg-bbg/90'>Submit</Button>
                      </FormItem>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <Button className="border-none bg-second mb-5" onClick={() => handlerefresh()} >
            Refresh
          </Button>
        </div>
        <div className="">

          <Table size='small' dataSource={dataSource} columns={columns} />
        </div>
      </div>


    </>
  )
}

export default Addapi
