import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "antd";
import logo from "../../src/img/binanceSpot.png";
import ReactApexChart from "react-apexcharts";
import { Doughnut } from "react-chartjs-2";
import "../assets/report.css";
import moment from "moment/moment";
function ReportSpot() {
  const [reportdata, setreportdata] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [moadlData, setModalData] = useState();
  const [shouldRenderChart, setShouldRenderChart] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [profitData, setProfitData] = useState([]);
  const [activtabledata, setactivtabledata] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectCoin, setSelectCoin] = useState("");
  const [showLong, setShowLong] = useState(false);
  const [socket, setSocket] = useState(null);
  const [sortedReportData, setSortedReportData] = useState(reportdata);
  const [modalApexOption, setMoadAPexOption] = useState({
    chart: {
      type: "bar",
      height: 440,
      stacked: true,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 2000,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    colors: ["#008FFB", "#FF4560"],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      min: -100,
      max: 100,
      title: {
        // text: 'Age',
      },
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return val;
        },
      },
      y: {
        formatter: function (val) {
          return Math.abs(val) + "%";
        },
      },
    },

    xaxis: {
      categories: ["Balance", "Position", "Profit", "Brokerage"],

      labels: {
        formatter: function (val) {
        },
      },
    },
  });

  const getallroleTwo = (e) => {
    let username = localStorage.getItem("username");
    let postData = { username: username };
    axios
      .post(process.env.REACT_APP_API_PATH_BOT + "bot_list", postData)
      .then((res) => {
        setactivtabledata(res.data.Data);

      })
      .catch((err) => {
        alert(err.response.data);
      });
  };

  const showModalTwo = (id) => {
    setModalOpen(true);
    const filterData = activtabledata.filter((d) => d.VarBotID.includes(id))
    setFilterData(filterData)
  };
  const handleOkTwo = () => {
    setModalOpen(false);
  };
  const handleCancelTwo = () => {
    setModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getallrole = (e) => {
    let username = localStorage.getItem("username");
    let postData = { username: username };
    axios
      .post(process.env.REACT_APP_API_PATH_BOT + "report", postData)
      .then((res) => {
        const mainresponse = res?.data?.Data;
        const binanceSpotRecords = mainresponse.filter(record => record?.Exchange === "BINANCESPOT");
        setreportdata(binanceSpotRecords);
        const sortedData = [...binanceSpotRecords]; // Make a copy of the filtered data to avoid mutating it
        sortedData?.sort((a, b) => {
          const profitA = calculatePROFIT(a);
          const profitB = calculatePROFIT(b);
          return profitB - profitA;
        });
        setSortedReportData(sortedData);
        console.log(sortedData)
      })
      .catch((err) => {
        alert(err.response.data);
      });
  };

  const colors = [];
  const options = {
    chart: {
      type: "bar",
      height: 440,
      stacked: true,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 2000,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    tooltip: {
      custom: function ({ dataPointIndex }) {
        return (
          "<div class='custom-tooltip' style='font-size: 10px;'>" +
          ["A", "B", "C", "D", "E"][dataPointIndex] +
          "</div>"
        );
      },
      style: {
        fontSize: "11px",
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%",
        colors: {
          ranges: colors,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      min: -100,
      max: 100,
      labels: {
        show: false, // Set this to false to hide y-axis labels
        formatter: function (val) {
        },
      },
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return val;
        },
      },
      y: {
        formatter: function (val) {
          return Math.abs(val)?.toFixed(2) + "%";
        },
      },
    },
    xaxis: {
      categories: ["", "", "", ""],

      labels: {
        show: false,
        formatter: function (val) {
        },
      },
    },
  };
  const donutOptions = {
    plugins: {
      tooltip: {
        enabled: true, // Show tooltip on hover
        callbacks: {
          label: (context) => {
            const value = context.parsed || 0;
            return `${value}`;
          },
        },
      },
      legend: {
        display: false, // Hide legend
      },
    },
    animation: {
      duration: 2000, // Animation duration in milliseconds
      easing: "easeInOutQuad", // Animation easing function
    },
  };

  const ClosPrftLong =
    ((profitData?.p - filterData[0]?.AverageBuy) *
      filterData[0]?.TotalQuantity)?.toFixed(2);

  const ClosPrftShort =
    ((filterData[0]?.AverageBuy - profitData?.p) *
      filterData[0]?.TotalQuantity)?.toFixed(2);

  
 
  const PnLL = (
    (profitData?.p)
    -
    (filterData[0]?.AverageBuy)
  )
  const PnLLper =
    (
      ((profitData?.p) - (filterData[0]?.AverageBuy)) / ((filterData[0]?.AverageBuy))
    )


  function calculateChartSeries(item) {
    const series =
      [
        {
          name: "",
          data: [
            item?.Balance > 0
              ? (
                ((item?.Balance) /
                  (item?.Invested)) *
                100
              )?.toFixed(2)
              : 0,
            item?.Invested +
              (item?.TotalProfit) -
              (item?.Balance) >
              0
              ? (
                ((item?.Invested +
                  (item?.TotalProfit) -
                  (item?.Balance)) /
                  (item?.Invested)) *
                100
              )?.toFixed(2)
              : 0,
            item?.TotalProfit > 0
              ? (
                ((item?.TotalProfit) /
                  (item?.Invested)) *
                100
              )?.toFixed(2)
              : 0,
            item?.TotalBrokerage >
              0
              ? (
                ((item?.TotalBrokerage) /
                  (item?.Invested)) *
                100
              )?.toFixed(2)
              : 0,
          ],
        },
        {
          name: "",
          data: [
            item?.Balance < 0
              ? -(
                ((item?.Balance) /
                  (item?.Invested)) *
                100
              )?.toFixed(2)
              : 0,
            item?.Invested < 0
              ? -(
                ((item?.Invested) /
                  (item?.Invested)) *
                100
              )?.toFixed(2)
              : 0,
            item?.TotalProfit < 0
              ? -(
                ((item?.TotalProfit) /
                  (item?.Invested)) *
                100
              )?.toFixed(2)
              : 0,
            item?.TotalBrokerage <
              0
              ? -(
                ((item?.TotalBrokerage) /
                  (item?.Invested)) *
                100
              )?.toFixed(2)
              : 0,
          ],
        },
      ]
    return series;
  }
  function calculatePROFIT(item) {
    return (
      ((item?.TotalProfit) /
        (item?.Invested)) *
      100
    )?.toFixed(2);
  }
  const sortReportData = (order) => {
    const sortedData = [...reportdata]; // Make a copy of the original data to avoid mutating it
    sortedData.sort((a, b) => {
      const profitA = calculatePROFIT(a);
      const profitB = calculatePROFIT(b);
      return order === 'asc' ? profitA - profitB : profitB - profitA;
    });
    setSortedReportData(sortedData);
  };
  // JSX for the High to Low and Low to High buttons
  useEffect(() => {
    sortReportData('desc');
    getallrole();
    getallroleTwo();
  }, []);
  useEffect(() => {
    if (moadlData) {
      const OptionData = {
        ...modalApexOption,
      };
      setMoadAPexOption(OptionData);
      setShouldRenderChart(true); // Add this line
    }
  }, [moadlData]);
  return (
    <>
      <div className="">
        <div className=" ">
          <h2 className="text-second text-center">Category</h2>
          <div className="flex items-center justify-center gap-3 pt-2">
            <button
              className="p-1 px-2 border-dashed border rounded-md border-second text-[#fff]"
              onClick={() => sortReportData('asc')}
            >
              Low to High
            </button>
            <button
              className="p-1 px-2 border-dashed border rounded-md border-second text-[#fff]"
              onClick={() => sortReportData('desc')}
            >
              High to Low
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3  mx-2 my-0 py-3">
          {sortedReportData && sortedReportData?.map((item) => (
            <div className="">
              <div className="bg-[#e2b200] rounded-lg">
                <div className="mx-2">
                  <div className="pt-2">
                    <div className="flex justify-center gap-10 bg-[#191320] text-[#fff] py-[10px]  rounded-[10px]">
                      <div className="flex flex-col justify-around w-1/2">
                        <h1 className=" text-md font-semibold text-center">
                          API : {item?.API_Name}
                        </h1>
                        <div className="flex px-2 gap-2  justify-between  w-full items-center">
                          <p className=" text-xs xl:text-base">
                            {item?.VarBotID}{" "}
                          </p>
                          <p className="text-xs xl:text-base">{item?.Coin}</p>
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <img
                          src={logo}
                          alt="logo"
                          className="w-[150px] h-auto mr-5"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-around bg-[#fff] shadow-md text-[#212121] items-center rounded-[10px] py-[10px] my-2">
                    <div>
                      <p className=" text-lg flex flex-col ">
                        {" "}
                        <span className="text-center  font-medium">
                          PROFIT
                        </span>{" "}
                        <div>
                          <span
                            className={`text-center text-3xl font-semibold 
                            ${parseFloat(

                              item?.TotalProfitPercent
                            )?.toFixed(2)
                                < 0
                                ? "text-[#f00]"
                                : "text-[#38913a]"
                              }`}
                          >
                            {item?.TotalProfitPercent}
                            %
                          </span>
                        </div>
                      </p>
                    </div>
                    <div className="w-32">
                      <p className="text-center font-bold text-base">Trades</p>
                      <p className="text-xs leading-3 flex items-center justify-between ">
                        <div className="text-center">Total: </div>
                        <div className=" font-bold text-xs text-center">

                          {item?.TotalTrades? parseInt(item?.TotalTrades):"0"}
                        </div>
                      </p>
                      <p className="text-xs leading-3 text-center flex justify-between items-center">
                        Winning:
                        <div className="font-bold text-xs text-center">
                          {item?.WinningTrades ? parseInt(item.WinningTrades) : "0"}
                        </div>
                      </p>

                      <p className="text-xs leading-3 text-center  flex justify-between items-center">
                        Losing
                        <div className=" font-bold text-xs text-center">
                          {item?.LosingTrades? parseInt(item?.LosingTrades):"0"}
                        </div>
                      </p>
                      <p className="text-xs leading-3 text-center  flex justify-between items-center">
                        Hit rate:
                        <div className=" font-bold text-xs text-c">
                          {item?.HitRate? parseInt(item?.HitRate):"0"}
                        </div>
                      </p>
                    </div>
                    <div className="w-[50] h-[50] flex justify-center">
                      <Doughnut
                        data={{
                          labels: ["WinningTrades", "LoosingTrades"],
                          datasets: [
                            {
                              data:
                                [
                                  item?.WinningTrades, item?.LosingTrades
                                ],
                              backgroundColor: [
                                "rgba(14, 220, 83, 0.6)",
                                "rgba(255, 99, 132, 0.6)",
                              ],
                              borderColor: [
                                "rgba(14, 220, 83, 0.6)",
                                "rgba(255, 99, 132, 0.6)",
                              ],
                              borderWidth: 1,
                            },
                          ],
                        }}
                        options={donutOptions}
                        style={{ width: " 75px" }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 bg-[#fff] text-[#212121] shadow-lg rounded-md px-1 my-2">
                    <div className=" w-full ">
                      <div className="-mb-4 text-left text-sm">
                        Bot Alloted Fund	<b>${item?.Invested}</b>
                      </div>
                      <div className="flex justify-center gap-2 items-center ">
                        <div className=" w-[40%]">
                          <div className="-ml-5 -mt-2">
                            <ReactApexChart
                              options={options}
                              series={calculateChartSeries(item)} // Use the calculated series
                              type="bar"
                              height={117}
                            />
                          </div>
                        </div>
                        <div className="text-[13px]  flex gap-1 justify-between items-center  flex-col w-2/5">
                          <p className="-mt-1 flex gap-5 justify-between w-full">
                            <div className="text-xs">Balance:</div>
                            <span className=" font-bold">
                              ${(item?.Balance || 0)?.toFixed(2)}
                            </span>
                          </p>
                          <p className="-mt-1 flex gap-5 justify-between w-full">
                            <div className="text-xs">Position:</div>
                            <span className=" font-bold">
                              $
                              {(
                                (item?.Invested || 0) +
                                (item?.TotalProfit || 0) -
                                (item?.Balance || 0)
                              )?.toFixed(2)}
                            </span>
                          </p>
                          <p className="-mt-1 flex gap-5 justify-between w-full">
                            <div className="text-xs">Profit:</div>
                            <span className=" font-bold">
                              $
                              {(item?.TotalProfit || 0)?.toFixed(
                                2
                              )}
                            </span>
                          </p>
                          <p className="-mt-1 flex gap-5 justify-between w-full">
                            <div className="text-xs">Brokerage:</div>
                            <span className=" font-bold">
                              $
                              {(
                                item?.TotalBrokerage || 0
                              )?.toFixed(2)}
                            </span>
                          </p>
                          
                         <p className="-mt-1 flex gap-5 justify-between w-full">
                            <div className="text-xs"> No of Days:</div>
                            <span className=" font-bold">
                              {
                                reportdata
                                  .map((d) => {
                                    if (d?.StartDate) {
                                      const startDateObj = moment(d?.StartDate, 'DD-MM-YYYY');
                                      const today = moment();
                                      const daysDifference = today.diff(startDateObj, 'day');
                                      return (daysDifference);
                                    }
                                    return null;
                                  })
                                  .slice(0, 1)
                              }
                            </span>

                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    {/* <button
                      className=" bg-wht hover:bg-[#191320] transition-all transition-500 w-20 my-2 px-2 py-1 rounded-md text-second "
                      onClick={() => showModal(item)}
                    >
                      More
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Modal
          title={
            <div className="flex items-center justify-between mx-[20px] bg-[#14161f] p-4 rounded-md">
              <div className=" flex items-center gap-2">
                <p className="text-[#fff] ">
                  {moadlData?.Coin}
                </p>
              </div>
              <div>
                <h1 className=" text-[25px] text-[#fff]">
                  API Name: {moadlData?.API_Name}
                </h1>
                <p className=" text-[14px] text-[#fff]">
                  Bot ID: {moadlData?.VarBotID}
                </p>
              </div>
              <div>
                <img src={logo} alt="" className="w-[150px] text-[#fff]" />
              </div>
            </div>
          }
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          className="reportModal "
        >
          <div className=" grid grid-cols-1  md:grid-cols-1 gap-3">
            <div>
              <div className="mx-3">
                <div className="pt-3">
                  <div className="flex justify-center gap-10 bg-[#191320] text-[#fff] py-[10px]  rounded-[10px]">
                    <div>
                      <p className="text-[20px]">Long</p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-10  bg-[#fff] shadow-lg rounded-md my-2">
                  <div className=" w-1/2 ">
                    {shouldRenderChart ? (
                      <ReactApexChart
                        options={modalApexOption}
                        series={calculateChartSeries(moadlData)}
                        type="bar"
                        height={150}
                      />
                    ) : null}
                  </div>
                  <div className="text-[13px]  flex flex-col justify-center  w-1/3">
                    <p className="flex justify-between text-base">
                      
                      <span>Bot Alloted Fund	</span>
                      <span className=" font-bold">
                        {" "}
                        ${moadlData?.Invested?.toFixed(2)}
                      </span>{" "}
                    </p>
                    <p className="flex justify-between text-base">
                      <span>Balance:</span>{" "}
                      <span className=" font-bold">
                        ${moadlData?.Balance?.toFixed(2)}
                      </span>
                    </p>
                    <p className="flex justify-between text-base">
                      <span>Position:</span>{" "}
                      <span className=" font-bold">
                        $
                        {(
                          moadlData?.Invested -
                          moadlData?.Balance
                        )?.toFixed(2)}
                      </span>
                    </p>
                    <p className="flex justify-between text-base">
                      <span>Profit:</span>{" "}
                      <span className=" font-bold">
                        ${moadlData?.TotalProfit?.toFixed(2)}
                      </span>
                    </p>
                    <p className="flex justify-between text-base">
                      <span>Brokerage:</span>{" "}
                      <span className=" font-bold">
                        ${moadlData?.TotalBrokerage?.toFixed(2)}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex justify-around bg-[#fff] shadow-md text-[#212121] items-center rounded-[10px] py-[10px] ">
                  <div>
                    <p className=" text-2xl flex flex-col">
                      {" "}
                      <span className="text-center underline font-medium">
                        PROFIT
                      </span>{" "}
                      <div>
                        <span
                          className={`text-center text-5xl font-semibold leading-9 ${parseFloat(
                            (moadlData?.TotalProfit / moadlData?.Invested) *
                            100
                          )?.toFixed(2) < 0
                            ? "text-[#f00]"
                            : "text-[#38913a]"
                            }`}
                        >
                          {parseFloat(
                            (moadlData?.TotalProfit / moadlData?.Invested) *
                            100
                          )?.toFixed(2)}
                          %
                        </span>
                      </div>
                    </p>
                  </div>
                  <div>
                    <div className="flex items-center gap-12">
                      <div>
                        <p className="text-sm my-1 font-semibold">TRADES</p>
                        <p className="text-sm ">
                          <span>Total: </span>
                          {moadlData?.WinningTrades + moadlData?.LosingTrade}
                        </p>
                        <p className="text-sm ">
                          Winning: {moadlData?.WinningTrades}
                        </p>
                        <p className="text-sm ">
                          Losing: {moadlData?.LosingTrades}
                        </p>
                        <div>
                          <p className="text-base font-semibold">
                            Hit rate:{" "}
                            {parseFloat(moadlData?.HitRate).toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <div className="w-[125px] h-[125px] flex justify-center">
                        <Doughnut
                          data={{
                            labels: ["WinningTrades", "LoosingTrades"],
                            datasets: [
                              {
                                data: [
                                  moadlData?.WinningTrades,
                                  moadlData?.LosingTrades,
                                ],
                                backgroundColor: [
                                  "rgba(14, 220, 83, 0.6)",
                                  "rgba(255, 99, 132, 0.6)",
                                ],
                                borderColor: [
                                  "rgba(14, 220, 83, 0.6)",
                                  "rgba(255, 99, 132, 0.6)",
                                ],
                                borderWidth: 1,
                              },
                            ],
                          }}
                          options={donutOptions}
                          style={{ width: " 200px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    className=" bg-second hover:bg-second/75 transition-all transition-500  my-4 px-2 py-1 rounded-md text-wht w-auto "
                    onClick={() => {
                      showModalTwo(moadlData?.VarBotID);
                      setShowLong(true);
                      setSelectCoin(moadlData?.VarBotID);
                    }}
                  >
                    More Detail
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          title={<div>{<div> <span className="bg-[#191320] p-2.5 text-[#fff]  border-black rounded-md">Open Positions (Long)</span></div>
          }</div>}
          open={ModalOpen}
          onOk={handleOkTwo}
          onCancel={handleCancelTwo}
          className=""
        >
          <div className="  flex flex-col gap-3">
            <div className=" grid grid-cols-2 bg-[#d67600]/30 gap-2 py-2 rounded-lg shadow-lg mt-3 ">
              <div className="text-center">Bot ID : <span className="font-bold">{moadlData?.VarBotID}</span> </div>
              <div className="text-center"> COIN : <span className="font-bold">{moadlData?.Coin}</span></div>
              <div className="text-center">
                API Name : <span className=" font-bold">  {moadlData?.API_Name}</span>
              </div>
            </div>
            <div className=" grid grid-cols-2 bg-[#d67600]/30 gap-2 py-2 rounded-lg shadow-lg  ">
              <div className="text-center">
                Average Buy Price : <span className=" font-bold">
                  $ {(filterData[0]?.AverageBuy)?.toFixed(4)}
                </span>
              </div>
              <div className="text-center">
                Total Qualtity :  <span className=" font-bold">
                  {(filterData[0]?.TotalQuantity)?.toFixed(2)}
                </span>
              </div>
              <div className="text-center">
                Position : <span className=" font-bold">  ${filterData[0]?.AverageBuy * filterData[0]?.TotalQuantity}
                </span>
              </div>
              <div className="text-center">
                {" "}
                Margin :  <span className=" font-bold">
                  $ {((filterData[0]?.AverageBuy * filterData[0]?.TotalQuantity))?.toFixed(2)
                  }
                </span>

              </div>
            </div>
            <div className="grid grid-cols-2 bg-[#d67600]/30 gap-2 py-2 rounded-lg shadow-lg">
              <div className="text-center">
                Current Price : <span className="font-bold"> $ {parseFloat(profitData?.p)}</span>
              </div>

              <div className="text-center">

                <div className="text-center">
                  Profit & Loss :
                  <span className="font-bold" style={{ color: (PnLL >= 0 ? "green" : "red") }}>
                    {(PnLL ? PnLL : "")}
                  </span>
                </div>
              </div>
              <div className="text-center">
                Profit & Loss % :
                {(PnLLper ? PnLLper : "")}

              </div>
            </div>
          </div>
        </Modal>

      </div>
    </>
  );
}
export default ReportSpot;
