import React from 'react'

const Logout = () => {

    const logout = (() => {
        console.log('hello logout')
        localStorage.clear();
        window.location.href = '/'
    })
    return (
        <>
            <a
                onClick={logout}
                className="container m-1 px-8 py-2 rounded-lg border-2 w-max cursor-pointer font_primary  hover:border-second font_secondary text-second hover:text-second bg-main hover:bg-[white] focus:outline-0 text-right"
            >
                Logout
            </a>
        </>
    )
}

export default Logout