import { Button, Modal, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom"; // Make sure to import Link
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import moment from "moment/moment";

ChartJS.register(ArcElement, Tooltip, Legend);

function SelectBot() {
  const [botdata, setBotdata] = useState([]);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportdata, setreportdata] = useState([]);
  const [maindata, setmaindata] = useState([]);
  const [botname, setbotname] = useState([]);
  const [selectedTradeType, setSelectedTradeType] = useState('');
  const [selectedBot, setSelectedBot] = useState('ALL');
  const [symbols, setSymbols] = useState([]);
  const [Symbolscoin, setSymbolscoin] = useState([]);
  const [botdataformapping, setbotdataformapping] = useState(botdata);
  const [sortPreference, setSortPreference] = useState('high'); // 'high' or 'low'
  const [SelectedBotTT, setSelectedBotTT] = useState('SPOT'); // 'high' or 'low'
  const [categoery, setcategoery] = useState(); // 'high' or 'low'

  // Extract unique Trade Types from the API response
  const data = {
    labels: ['Winning trade', '  Losing Trade'],
    datasets: [
      {
        label: 'No. of Trades',
        data: [maindata.WinningTradesL + maindata.WinningTradesS, maindata.LosingTradesL + maindata.LosingTradesS],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const getallcoin = () => {
    axios
      .post(process.env.REACT_APP_API_PATH_BOT + "view_bot")
      .then((res) => {
        setBotdata(res.data.Data);
        console.log(botdata)
        console.log(botdataformapping)
        setbotdataformapping(res.data.Data)
        const response = res.data.Data
        const mainSymbols = [...new Set(response
          ?.map(item => item.Symbol))];
        const maincategoery = [...new Set(response
          ?.map(item => item.Name))];
        setSymbols(mainSymbols)
        setcategoery(maincategoery)
      })
      .catch((err) => {
        if (err.message !== "") {
          // toast.error("Something Went Wrong");
        }
      });
  };
  const uniqueTradeTypes = Array.from(
    new Set(botdata.map((item) => item.Trade_Type))
  );

  const handleTradeTypeChange = (value) => {
    setSelectedTradeType(value);
  };
  const showModal = (id, Name) => {

    setbotname(Name)
    setIsModalOpen(true);
    for (let i = 0; i < reportdata.length; i++) {
      if (id === reportdata[i].VarBotID) {
        if (reportdata[i].API_Name === "testfuture" || reportdata[i].API_Name === "testspot") {
          setmaindata(reportdata[i]);
          break; // Assuming you only want to set `maindata` once when the conditions are met
        }
      }
    }

  };
  const getallrole = (e) => {
    let postData = { "username": "main@main.com" }
    axios
      .post(process.env.REACT_APP_API_PATH_BOT + "report", postData)
      .then((res) => {
        // console.log(res)
        setreportdata(res.data.Data);
      })
      .catch((err) => {
        alert(err.response.data)

      });
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const convertToDate = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("-");
    const [hours, minutes] = timePart.split(":");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:00`;
    return formattedDate;
  };
  const getDaysUntilToday = (StartDate) => {
    const formattedStartDate = convertToDate(StartDate); // Assuming you have a function that converts the input date
    const startDateObj = new Date(formattedStartDate);
    const today = new Date();
    const timeDifference = today.getTime() - startDateObj.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference; // Return the calculated days
  };

  const handleBotSelectTT = (values) => {

    setSelectedBotTT(values);
    if (values) {
      const filteredSymbols = botdata.filter((item) => item.Trade_Type.includes(values))
        .map((item) => item.Symbol);
      const filteredBots = botdata.filter((item) => item.Trade_Type.includes(values));
      setbotdataformapping(filteredBots);
      setSymbols(filteredSymbols);
    }
  }

  const handlecategoerychange = (e) => {
    setSymbolscoin(e.target.value)
    if (e.target.name == 'categoery') {
      if (e.target.value == "All") {
        console.log(botdata)

        setbotdataformapping(botdata);
      } else {
        console.log(e.target.value)
        const filteredBots = botdata.filter((item) => item.Name.includes(e.target.value));
        console.log(filteredBots)
        setbotdataformapping(filteredBots);
      }

    }
    if (e.target.name == 'symbolchange') {
      if (e.target.value == "All") {
        console.log(botdata)

        setbotdataformapping(botdata);
      } else {
        const selectedSymbolname = e.target.value
        const filteredBots = botdata.filter((item) => item.Symbol.includes(selectedSymbolname));
        setbotdataformapping(filteredBots);
      }
    }
  }
  useEffect(() => {
    getallcoin();
    getallrole();
    handleSortButtonClick()
  }, []);

  useEffect(() => {
    // Sort the botdataformapping array based on the selected sort preference
    const sortedData = [...botdataformapping];
    sortedData.sort((a, b) => {
      const profitA = ((reportdata.find((d) => d.VarBotID === a.VarBotID)?.TotalProfitL + reportdata.find((d) => d.VarBotID === a.VarBotID)?.TotalProfitS) * 100) / (reportdata.find((d) => d.VarBotID === a.VarBotID)?.InvestedL + reportdata.find((d) => d.VarBotID === a.VarBotID)?.InvestedS);
      const profitB = ((reportdata.find((d) => d.VarBotID === b.VarBotID)?.TotalProfitL + reportdata.find((d) => d.VarBotID === b.VarBotID)?.TotalProfitS) * 100) / (reportdata.find((d) => d.VarBotID === b.VarBotID)?.InvestedL + reportdata.find((d) => d.VarBotID === b.VarBotID)?.InvestedS);
      return profitB - profitA
    });
    setbotdataformapping(sortedData);
  }, [reportdata]);

  const handleSortButtonClick = (preference) => {
    setSortPreference(preference ? preference : 'high');
    const sortedData = [...botdataformapping];
    sortedData.sort((a, b) => {
      const profitA = ((reportdata.find((d) => d.VarBotID === a.VarBotID)?.TotalProfitL + reportdata.find((d) => d.VarBotID === a.VarBotID)?.TotalProfitS) * 100) / (reportdata.find((d) => d.VarBotID === a.VarBotID)?.InvestedL + reportdata.find((d) => d.VarBotID === a.VarBotID)?.InvestedS);
      const profitB = ((reportdata.find((d) => d.VarBotID === b.VarBotID)?.TotalProfitL + reportdata.find((d) => d.VarBotID === b.VarBotID)?.TotalProfitS) * 100) / (reportdata.find((d) => d.VarBotID === b.VarBotID)?.InvestedL + reportdata.find((d) => d.VarBotID === b.VarBotID)?.InvestedS);
      return preference === 'high' ? profitB - profitA : profitA - profitB;
    });
    setbotdataformapping(sortedData);
  };
  return (

    <div className="">
      <div className=" lg:flex md:flex-col justify-center items-center">
        <h1 className="mb-28 text-4xl text-[#fff] leading-none tracking-tight md:text-5xl lg:text-6xl  mt-20">
          Select your
          <mark className="px-2 font-bold text-[#fff] bg-second mx-2 rounded dark:bg-blue-500">
            BOT
          </mark>{" "}
        </h1>
      </div>
      <div>
        <Select
          style={{ width: 200 }}
          placeholder="Select Trade Type"
          value={selectedTradeType}
          onChange={handleTradeTypeChange}
          className="hidden"
        >
          {uniqueTradeTypes.map((tradeType) => (
            <Select.Option key={tradeType} value={tradeType}>
              {tradeType}
            </Select.Option>
          ))}
        </Select>

      </div>
      <div className="bg-wht mb-2 grid grid-cols-4 sticky top-1 rounded-md p-3">
        <div className="border-r-2 border-dashed border-b-0 border-t-0 border-l-0 border-second ">
          <h2 className="text-second text-center">From</h2>
          <div className="flex items-center justify-center gap-3 pt-2">

            <button
              className={sortPreference === 'high' ? 'selected bg-second p-1 px-2 border-dashed border rounded-md mb-0' : 'p-1 px-2 border-dashed border rounded-md mb-0 border-second'}
              onClick={() => handleSortButtonClick('high')}
            >
              High to Low
            </button>
            <button
              className={sortPreference === 'low' ? 'selected bg-second p-1 px-2 border-dashed border rounded-md mb-0' : 'p-1 px-2 border-dashed border rounded-md mb-0 border-second'}
              onClick={() => handleSortButtonClick('low')}
            >
              Low to high
            </button>
          </div>
        </div>
        <div className="border-r-2 border-dashed border-b-0 border-t-0 border-l-0 border-second">
          <div className="flex justify-center mt-2 ">
            {/* <select name="categoery" onChange={handlecategoerychange}>
              
              <option value="All">All</option> 
              {categoery?.map((Name, index) => (
                <option key={index} value={Name}>
                  {Name}
                </option>
              ))}
            </select> */}

            {symbols.length > 0 ? (
              <div>
                <h2 className="text-second mb-2">Category</h2>
                <select defaultValue={"All"} name="categoery" onChange={handlecategoerychange} className="px-3 rounded-full py-1">
                  <option value={"All"}>ALL</option>
                  {categoery?.map((Name, index) => (
                    <option key={index} value={Name}>

                      {Name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div>
                <h2 className="text-second">Symbols for ALL:</h2>
                <select name="categoery" onChange={handlecategoerychange}>
                  {categoery?.map((Name, index) => (
                    <option key={index} value={Name}>

                      {Name}
                    </option>
                  ))}
                </select>
              </div>
            )}

          </div>
        </div>

        <div className="border-r-2 border-dashed border-b-0 border-t-0 border-l-0 border-second">
          <h2 className="text-second text-center">Exchange</h2>
          <div className="flex items-center justify-center gap-3 pt-2">
            <button
              className={SelectedBotTT === 'SPOT' ? 'selected  bg-second p-1 px-2 border-dashed border rounded-md' : 'p-1 px-2 border-dashed border rounded-md border-second'}
              onClick={() => handleBotSelectTT('SPOT')}
            >
              SPOT
            </button>
            <button
              className={SelectedBotTT === 'FUTURES' ? 'selected  bg-second p-1 px-2 border-dashed border rounded-md' : 'p-1 px-2 border-dashed border rounded-md border-second'}
              onClick={() => handleBotSelectTT('FUTURES')}
            >
              FUTURES
            </button>

          </div>
        </div>
        <div className="flex-col flex items-center justify-center gap-3 pt-2">
          {symbols.length > 0 ? (
            <div>
              <h2 className="text-second">Symbols for {selectedBot}:</h2>
              <select defaultValue={"All"} name="symbolchange" onChange={handlecategoerychange}>
                <option value={"All"}>ALL</option>
                {symbols?.map((symbol, index) => (
                  <option key={index} value={symbol}>{symbol}</option>
                ))}
              </select>
            </div>
          ) : (
            <div>
              <h2 className="text-second">Symbols for ALL:</h2>
              <select name="symbolchange" onChange={handlecategoerychange}>
                {botdata?.map((bot, index) => (
                  <option key={index} value={bot?.Symbol}>{bot?.Symbol}</option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>

      <div className="grid gap-10 lg:grid-cols-4">
        {botdataformapping?.map((bot) => (
          <div
            key={bot?.VarBotID}
            className="bg-second p-2"
          >
            <div className="bg-[#fffefc] font-bold p-1 rounded text-center">{bot?.Name}</div>
            <div className="">
              <div className=" font-bold text-sm p-3">
                <div className="flex justify-between">
                  <div className="text-start">Bot ID: </div>
                  <div className="text-end">{bot?.VarBotID}</div>
                </div>
                {/* <div className="flex justify-between">
                  <div> Exchange:</div><div>{bot?.Exchange}</div>
                </div> */}
                <div className="flex justify-between">
                  <div>Trade Type:</div><div>{bot?.Trade_Type}</div>
                </div>
                <div className="flex justify-between">
                  <div>Ticker:</div><div> {bot?.Symbol}</div>
                </div>
                {/* <div className="flex justify-between">
                  <div>Take Profit (%): </div><div>{bot?.Take_Profit_L}</div>
                </div> */}
                <div className="flex justify-between">
                  <div> Profit (%): </div>  <div>
                    {/* {
                    bot?.VarBotID === "testfuture"
                      ? (reportdata
                          .filter((d) => (d?.VarBotID === bot?.VarBotID && d?.API_Name === "testfuture"))
                          .map((d) => ((d?.TotalProfitL + d?.TotalProfitS) * 100 / (d?.InvestedL + d?.InvestedS)).toFixed(4)))
                      : bot?.VarBotID === "testspot"
                      ? (reportdata
                          .filter((d) => (d?.VarBotID === bot?.VarBotID && d?.API_Name === "testspot"))
                          .map((d) => ((d?.TotalProfit) * 100 / (d?.Invested)).toFixed(4)))
                      : ""
                  } */}


                    {reportdata
                      .filter((d) => (d?.VarBotID === bot?.VarBotID && d?.API_Name == "testfuture"))
                      .map((d) =>
                        ((d?.TotalProfitL + d?.TotalProfitS) * 100 / (d?.InvestedL + d?.InvestedS)).toFixed(4))}
                    {reportdata
                      .filter((d) => (d?.VarBotID === bot?.VarBotID && d?.API_Name == "testspot"))
                      .map((d) =>
                        ((d?.TotalProfit) * 100 / (d?.Invested)).toFixed(4))}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div> No of Days: </div>  <div>

                    {
                      reportdata
                        .filter((d) => (
                          d.VarBotID === bot?.VarBotID && (d?.API_Name == "testfuture" || d?.API_Name == "testspot")
                        ))
                        .map((d) => {
                          if (d?.StartDate) {
                            const startDateObj = moment(d?.StartDate, 'DD-MM-YYYY');
                            const today = moment();
                            const daysDifference = today.diff(startDateObj, 'day');
                            return (daysDifference);
                          }
                          return null;
                        })
                        .slice(0, 1)
                    }

                    {/* {reportdata
                      .filter((d) => (d.VarBotID === bot?.VarBotID && (d?.API_Name == "testfuture" || d?.API_Name == "testspot")))
                      .map((d) => {
                        if (d?.StartDate) {
                          const startDateObj = moment(d?.StartDate, 'DD-MM-YYYY');
                          const today = moment();
                          const daysDifference = today.diff(startDateObj, 'day');
                          return (daysDifference);
                        }
                        return null;
                      })
                      .slice(0, 1)} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center font-bold">
              <button
                type="button"
                className="p-2 mb-2 rounded-full  bg-[#fff] "
              >
                <Link to={`/main/basic-bot/${bot?.VarBotID}/${bot?.Trade_Type}`} className="text-blue-600 ">
                  <span className="text-second hover:text-[#3b3b3b]">Copy Bot</span>
                </Link>{" "}
              </button>
              {/* <button
                type="button"
                className="p-2 mx-2 mb-2 rounded-full  bg-[#fff] "
                onClick={() => showModal(bot?.VarBotID, bot?.Name)}
              >
                <Link className="text-blue-600 ">
                  <span className="text-second hover:text-[#3b3b3b]">Details</span>
                </Link>{" "}
              </button> */}
            </div>
          </div>
        ))}
      </div>
      <Modal
        title={
          <div className="bg-second p-3 rounded-md -mx-6 -mt-2">{botname}
            <div className="flex justify-between">
              <div className=" text-sm text-[#464646] border border-dashed border-[#eee] p-3 leading-[3px] mb-0 rounded-md">Coin <div className="font-bold text-lg -mb-2 text-[#000]">{maindata?.Symbol && maindata?.Symbol}</div></div>
              <div className=" text-sm text-[#464646] border border-dashed border-[#eee] p-3 leading-[3px] mb-0 rounded-md">BOT ID <div className="font-bold text-lg -mb-2 text-[#000]">{maindata?.VarBotID}</div>
              </div>
            </div>
          </div>
        } open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>
        <div className="border border-l-2 p-2 -mt-2 border-t-0 border-r-0 border-b-0 border-dashed border-second">
          <div className="flex justify-between border border-solid border-second shadow-third shadow-sm rounded-md p-3">
            <div className=" text-sm text-[#464646] text-center">Profit (%) <div className="font-bold text-lg -mb-2 text-[#000]">{((maindata?.TotalProfitL + maindata?.TotalProfitS) * 100 / (maindata?.InvestedL + maindata?.InvestedS)).toFixed(4)}</div></div>
            <div className=" text-sm text-[#464646] text-center">No of Days <div className="font-bold text-lg -mb-2 text-[#000]">
              {maindata?.StartDate && getDaysUntilToday(maindata?.StartDate)}
            </div></div>
          </div>
          <div className="bg-second/10 border border-l-2 p-2  border-t-0 border-r-0 border-b-0 border-solid ml-2 rounded-br-md shadow-md rounded-bl-md border-second ">
            <div className="flex justify-between items-center ">
              <div className="leading-10">
                <p className="font-bold">Total Trades - {maindata?.TotalTradesL + maindata?.TotalTradesS}</p>
                <p className="font-bold">Winning Trade - {maindata?.WinningTradesL + maindata?.WinningTradesS}</p>
                <p className="font-bold">Losing Trade - {maindata?.LosingTradesL + maindata?.LosingTradesS}</p>
                <p className="font-bold">Hit Rate - {(maindata?.TotalTradesL + maindata?.TotalTradesS / maindata?.TotalTradesL + maindata?.TotalTradesS).toFixed(4)}</p>
              </div>
              <div >
                <Doughnut data={data} className="h-2/5" />
              </div>
            </div>
          </div>
        </div>
      </Modal >
    </div >

  );
}
export default SelectBot;
