import React, { useEffect, useState } from "react";
import { Tabs } from 'antd';
import ReportFuture from "./ReportFuture";
import ReportSpot from "./ReportSpot";


function Report() {
 const items = [
    {
      key: '1',
      className:"text-white p-0 m-0",
      label: `Future Report`,
      children: <ReportFuture />,
    },
    {
      key: '2',
      className:"text-white",
      label: `Spot Report`,
      children: <ReportSpot/>,
    }
  ];
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <>
    <div className="">
    <div className="mx-10 text-white">
        <Tabs className="text-white" defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
      
    </>
  );
}

export default Report;
