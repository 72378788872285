import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Input, Select, Space, Table, message } from 'antd';
import FormItem from "antd/es/form/FormItem";

function Orderopen() {
  const [data, setdata] = useState([]);
  const username = localStorage.getItem('username');
  const [selectedBotID, setSelectedBotID] = useState('');
  const [selectedCoin, setSelectedCoin] = useState('');
  const [selectedAPI, setSelectedAPI] = useState('');
  const [selectedTradeDirection, setSelectedTradeDirection] = useState('');
  const [selectedSide, setSelectedSide] = useState('');
  const getlivebot = () => {
    const postdata = { "username": username };
    axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: process.env.REACT_APP_API_PATH_BOT + 'open_position',
      data: postdata,
    }).then(async function (response) {
      const res = await response?.data?.Data;
      console.log(res)
      const filteredData = res?.filter((transaction) => transaction?.Exchange === "BINANCEFUTURES");
    
      setdata(filteredData);
      console.log(filteredData);
    }).catch((err) => {
      // alert(err.response.data)
    });
  };

  console.log(selectedTradeDirection)
  const handleclose = (e, record) => {
    console.log(record)
    const postdata = {
      username: username,
      coin: record.Symbol,
      bot_id: record.VarBotID,
      api_name: record.API_Name,
      trade_type: record.trade_type,
    };
    axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: process.env.REACT_APP_API_PATH_BOT_API + 'close_all_position',
      data: postdata,
    }).then(async function (response) {
      console.log(response);
      const res = await response.data?.Data;
      console.log("res");
      console.log(res);
      setdata(res);
      getlivebot()
    }).catch((err) => {
      // alert(err.response.data)
      // message.error(err)
      message.error(err.response.data?.Error)
      console.log(err.response.data?.Error)
    });
  };

  const uniqueBotIDs = [...new Set(data?.map(item => item.VarBotID))];
  const uniqueCoins = [...new Set(data?.map(item => item.Symbol))];
  const uniqueAPIs = [...new Set(data?.map(item => item.API_Name))];
  //   const uniqueTradeDirections = [...new Set(data?.map(item => item.Trade_Direction))];
  const uniqueTradeDirections = ["SHORT", "LONG"];
  const uniqueSides = [...new Set(data?.map(item => item.Side))];

  const filteredDataSource = data?.filter(item =>
    (!selectedBotID || item.VarBotID === selectedBotID) &&
    (!selectedCoin || item.Symbol === selectedCoin) &&
    (!selectedAPI || item.API_Name === selectedAPI) &&
    (!selectedTradeDirection || (item.AverageBuyL ? "LONG" : "SHORT") === selectedTradeDirection) &&
    (!selectedSide || item.Side === selectedSide)
  );

  const dataSource = filteredDataSource?.map((d, index) => {
    const date = new Date(d?.DateTime);
    return {
      key: index,
      VarBotID: d.VarBotID,
      API_Name: d.API_Name,
      Symbol: d.Symbol,
      trade_type: d.AverageBuyL ? "LONG" : "SHORT",
      AverageBuy: d.AverageBuyL ? (parseFloat(d.AverageBuyL)?.toFixed(2)) : (parseFloat(d.AverageBuyS)).toFixed(2),
      TotalQuantity: (d.TotalQuantityL ? d.TotalQuantityL : d.TotalQuantityS)?.toFixed(2),
      Price: d.Price,
      Quantity: d.Quantity,
      Side: d.Side,
    };
  });
  const columns = [
    {
      title: 'Bot ID',
      dataIndex: 'VarBotID',
      key: 'VarBotID',
      className: 'text-center',
      filters: uniqueBotIDs?.map(id => ({ text: id, value: id })),
      onFilter: (value, record) => record?.VarBotID.includes(value),
      filterMode: 'tree',
      filterSearch: false,
    },
    {
      title: 'API Name',
      dataIndex: 'API_Name',
      key: 'API_Name',
      className: 'text-center',
      filters: uniqueAPIs.map(apiname => ({ text: apiname, value: apiname })),
      onFilter: (value, record) => record?.API_Name.includes(value),
    },
    {
      title: 'Coin',
      dataIndex: 'Symbol',
      key: 'Symbol',
      className: 'text-center',
      filters: uniqueCoins.map(coin => ({ text: coin, value: coin })),
      onFilter: (value, record) => record?.Symbol.includes(value),
    },
    {
      title: 'Total Quantity',
      dataIndex: 'TotalQuantity',
      key: 'TotalQuantity',
      className: 'text-center'
    },
    {
      title: 'Average Buy',
      dataIndex: 'AverageBuy',
      key: 'AverageBuy',
      className: 'text-center'
    },
    {
      title: 'Trade Type',
      dataIndex: 'trade_type',
      key: 'trade_type',
      className: 'text-center',
      filters: uniqueTradeDirections.map(direction => ({ text: direction, value: direction })),
      onFilter: (value, record) => record?.trade_type.includes(value),
    },
    {
      title: "Action",
      dataIndex: "Action",
      className: "text-center",
      render: (text, record) => (
        <>
          <div className='justify-center items-center flex'>
            <div>
              <FormItem name="api_name" >
                <Button size="small" onClick={(e) => handleclose(e, record)}>Close Trade</Button>
              </FormItem>
            </div>
          </div>
        </>)
    },

  ];
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  useEffect(() => {
    getlivebot();
  }, []);
  useEffect(() => {
    const apiResponse = {/* Your API response here */ };
    if (apiResponse.TotalQuantityL) {
      setSelectedTradeDirection("LONG");
    } else if (apiResponse.TotalQuantityS) {
      setSelectedTradeDirection("SHORT");
    } else {
      setSelectedTradeDirection(""); // Default value
    }
  }, [data]);
  return (
    <div>
      <Table
        size="small"
        dataSource={dataSource?dataSource:""}
        columns={columns}
        className="text-[#fff]"
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ["30", "50", "100"],
        }}
        onChange={onChange}
      />
    </div>
  );
}

export default Orderopen;
