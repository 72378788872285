import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

import FutureBasicBot from "./FutureBasicBot";
import SpotBasicbot from "./SpotBasicbot";


function BasicBot() {
  const { coinparams } = useParams();
  const { tradetypeparams } = useParams();

 
  return (
    <>
   { tradetypeparams == "FUTURES"?<FutureBasicBot cparams={coinparams?coinparams:""} ttypeparams={tradetypeparams}/>:<SpotBasicbot coinparams={coinparams?coinparams:""} tradetypeparams={tradetypeparams?tradetypeparams:""}/>}
    </>
  );
}

export default BasicBot;
