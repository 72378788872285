import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Typography,
  message,
} from "antd";
import bg_img from '../img/bg_img.webp'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
const { div } = "Typography";
function ApplyForm() {
  const navigate = useNavigate();
  const [input, setInput] = useState({
    username: "",
    password: "",
  });
  const [hasScrolledDisclaimer, setHasScrolledDisclaimer] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getFinish = async () => {
    if (!isCheckboxChecked) {
      message.error("Please accept the Terms & Conditions before submitting.");
      return;
    }
    const postdata = {
      username: input.username,
      password: input.password,
      portal: 1
    };
    try {
      setIsLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_API_PATH + "login",
        postdata,
        { headers: { "Content-Type": "application/json" } }
      );
      const res = response.data;
      message.success(res.Success);
      localStorage.setItem("username", res.Username);
      navigate("/main");
    } catch (err) {
      console.log(err);
      message.error(err.response.data.Error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleScroll = (e) => {
    const scrollableDiv = e.target;
    const scrollBottom =
      scrollableDiv.scrollHeight - scrollableDiv.clientHeight - scrollableDiv.scrollTop;
    const threshold = 20;
    if (scrollBottom <= threshold) {
      setHasScrolledDisclaimer(true);
    }
  };

  const onFinishFailed = (errorInfo) => { };
  return (
    <>
      <div className=" flex justify-center bg-[#fff]">
        <div className="max-w-xl  rounded-lg shadow-lg p-6 mt-44 bg-main">
          <div className=" mx-auto">
            <h1 className="text-center font-bold text-[#fff] text-3xl mb-5">Login</h1>
            <Form
              layout="vertical"
              name="basic"
              autoComplete="off"
              size="large"
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Typography label={3} style={{ color: "#fff" }} className="text-center">
                  User Name :
                </Typography>
                <Input
                  className="my-0"
                  prefix={<UserOutlined />}
                  placeholder="User Name"
                  style={{ width: "100%" }}
                  value={input.username}
                  onChange={(e) =>
                    setInput({ ...input, username: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <div className="my-4">
                  <Typography label={3} style={{ color: "#fff" }} className="text-center">
                    Password :
                  </Typography>
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Password"
                    style={{ width: "100%" }}
                    value={input.password}
                    onChange={(e) =>
                      setInput({ ...input, password: e.target.value })
                    }
                  />
                </div>
              </Form.Item>
              <Form.Item className="mt-2">
                <div
                  className="scrollable-div text-[10px] "
                  onScroll={handleScroll}
                  style={{
                    maxHeight: "120px", // Set a max height for the scrollable div
                    overflowY: "scroll",
                    color: "#fff"
                  }}
                >
                  {/* Disclaimer text goes here */}
                  <h4 className="text-center ">Disclaimer</h4>
                  <h4 className="text-center mb-2"> Use of Data and Information from bot.profitsla</h4>
                  <p>The information and data provided by bot.profitsla are intended for informational purposes only and should not be considered as financial advice. While we strive to provide accurate and up-to-date information, we cannot guarantee the accuracy, completeness, or reliability of the data provided by the bot.</p>
                  <p className="mt-2">It's important to note that the data and details presented by bot.profitsla may differ from the actual information provided by exchanges such as Binance or other platforms. Market conditions, prices, and other variables can change rapidly, and reliance solely on the information provided by the bot may lead to incorrect trading decisions.</p>
                  <p className="mt-2">Additionally, bot.profitsla may not have the capability to manage or respond to trade blocks or other actions taken by exchanges like Binance. The execution of trades and management of accounts involve risks that extend beyond the control of the bot.</p>
                  <p className="mt-2">Trading in the cryptocurrency and financial markets involves substantial risk. Prices can be highly volatile, and there is a risk of loss of capital. Before making any trading decisions, it's important to conduct thorough research, consider your risk tolerance, and consult with a qualified financial advisor.</p>
                  <p className="mt-2">In no event shall bot.profitsla or its developers be liable for any direct, indirect, incidental, consequential, or any other damages arising out of or in connection with the use of the information provided by the bot. Users are advised to trade at their own risk and to exercise caution when using automated trading tools.</p>
                  <p className="mt-2">By using bot.profitsla, you acknowledge and accept the risks associated with trading and the limitations of the information and capabilities of the bot. You agree that you are solely responsible for your trading decisions and their outcomes.</p>
                  <p className="mt-2">This disclaimer is subject to change without notice, and users are advised to review it periodically for any updates.</p>
                  {/* ... */}
                </div>
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" className="text-center">
                  <Checkbox
                    style={{ color: "#fff" }}
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                    disabled={!hasScrolledDisclaimer} // Disable the checkbox if user hasn't scrolled
                  >
                    Please Accept Terms & Conditions
                  </Checkbox>
                </Form.Item>
              </Form.Item>
              <Button
                className="bg-second text-center w-full mt-5 text-xl h-full text-[#fff]"
                htmlType="submit"
                onClick={getFinish}
                onFinishFailed={onFinishFailed}
                disabled={!isCheckboxChecked || isLoading}
                style={{ color: "white" }}
              >
                {isLoading ? "Login..." : "Login"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplyForm;
