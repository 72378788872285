import React from 'react'
import { Col, Input, Row, Typography } from 'antd';
import axios from "axios";
import { useState } from 'react';
import { useEffect } from 'react';
import Chart from "../Components/Chart"
import { Form, Select, InputNumber, Card } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import FormItemLabel from 'antd/es/form/FormItemLabel';

const { Title } = Typography;

function Calculatornew() {
    const [coinnn, setCoinnn] = useState("");
    const [coins, setCoins] = useState([]);
    const [currentcoin, setcurrentcoin] = useState('BTCUSDT');
    const [CurrentPrice, setCurrentPrice] = useState([]);
    const [Prevprice, setPrevprice] = useState(0);
    const [Price, setPrice] = useState();
    const [Quantity, setQuantity] = useState();
    const [Amount, setAmount] = useState();
    const [PriceB, setPriceB] = useState();
    const [PriceC, setPriceC] = useState();
    const [QuantityB, setQuantityB] = useState();
    const [QuantityC, setQuantityC] = useState();
    const [AmountB, setAmountB] = useState();
    const [AmountC, setAmountC] = useState();
    const [NAP, setNAP] = useState();
    const [NAPB, setNAPB] = useState();
    const [PnL, setPnL] = useState();
    const [pricePnL, setpricePnL] = useState();
    const [PnLC, setPnLC] = useState();
    const [pricePnLC, setpricePnLC] = useState();
    const [Balance, setBalance] = useState();
    const [BalanceLimit, setBalanceLimit] = useState();
    const [BalanceMarket, setBalanceMarket] = useState();
    const getallcoin = (e) => {
        const formData = new FormData();
        axios
            .post(process.env.REACT_APP_API_PATH + 'get_coins')
            .then((res) => {
                // console.log(res.data.data);
                setCoins(res.data.data);
                if (res.data.message === " Created successfully") {
                    console.log(" Created successfully");
                }
            })
            .catch((err) => {
                if (err.message !== "") {
                    console.log("Something Went Wrong");
                }
            });
    };
    const optionscoins = coins?.map((coin) => {
        return {
            value: coin.Coin,
            label: coin.Coin,
        };
    });
    useEffect(() => {
        const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${currentcoin == "" ? "BTCUSDT" : currentcoin.toLowerCase()}@ticker`);

        ws.addEventListener("message", (event) => {
            const data = JSON.parse(event.data);
            // console.log(data)
            setCurrentPrice(data);
            setPrevprice(CurrentPrice.c)
        });

        return () => {
            ws.close();
        };

    }, [currentcoin, CurrentPrice]);
    const handleSubmit = (values) => {
        console.log(values)
    };
    const handleChange = (values) => {
        console.log(values)
        setcurrentcoin(values)
        // const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${values.toLowerCase()}@ticker`);

        // ws.addEventListener("message", (event) => {
        //     const data = JSON.parse(event.data);
        //     // console.log(data)
        //     setCurrentPrice(data);
        //     setPrevprice(CurrentPrice.c)
        // });

        // return () => {
        //     ws.close();
        // };
    };
    const onSearch = (value) => {
        console.log("search:", value);
    };

    useEffect(() => {
        getallcoin();
    }, []);
    const handlechangefitstcalc = (e) => {
        aaa(e.target.name, e.target.value)
    };


    function aaa(name, value) {
        let prices = CurrentPrice.c




        if (name === 'price') {

            setPrice(value);


        }
        if (name === 'quantity') {
            setQuantity(value);
            setAmount(value * Price);
        }
        if (name === 'amount') {
            setAmount(value);

            setQuantity(value / Price);
        }
        if (name === 'amountb') {
            setAmountB((value));
            setPriceB(prices)
            setQuantityB(value / prices);

        }
        if (name === 'quantityb') {
            setQuantityB(value);
            setPriceB(prices)

            setAmountB(value * prices);

            let abc = ((Number(Amount) + Number(value) * prices) / (Number(Quantity) + Number(value)));

            setNAP(abc)
        }


        if ((Quantity && QuantityB !== "") && (Amount && AmountB !== "")) {
            // console.log((Number(Amount) + Number(AmountB)) / (Number(Quantity) + Number(QuantityB)))
            // console.log(Number(Quantity) + Number(QuantityB))

            setNAP((Number(Amount) + Number(AmountB)) / (Number(Quantity) + Number(QuantityB)))
        }
        if (name === 'PnL') {
            setpricePnL(NAP * (Number(value) + 100) / 100);
            console.log(NAP * (Number(value) + 100) / 100);
        }
        if (name === 'pricePnL') {
            setPnL((value - Number(NAP)) * 100 / Number(NAP));
            console.log((value - Number(NAP)) * 100 / Number(NAP));
        }

        if (name === 'PriceC') {

            setPriceC(value);
        }
        if (name === 'AmountC') {
            setAmountC(value);
            setQuantityC(value / PriceC);

        }
        if (name === 'QuantityC') {
            setQuantityC(value);

            setAmountC(value * PriceC);
            setNAPB((Amount + (value * PriceC)) / (Number(Quantity) + Number(value)))
        }

        if ((Quantity && QuantityC !== "") && (Amount && AmountC !== "")) {

            // setNAPB((Number(Amount) + Number(AmountC))/(Number(Quantity) + Number(QuantityC)))
            console.log((Amount + AmountC))
            console.log((Number(Quantity) + Number(QuantityC)))
            console.log((Amount + AmountC) / (Number(Quantity) + Number(QuantityC)))
            setNAPB((Amount + AmountC) / (Number(Quantity) + Number(QuantityC)))

        }
        if (name === 'PnLC') {
            setpricePnLC(NAPB * (Number(value) + 100) / 100);
            console.log(NAPB * (Number(value) + 100) / 100);
        }
        if (name === 'pricePnLc') {
            setPnLC((value - Number(NAPB)) * 100 / Number(NAPB));
            console.log((value - Number(NAPB)) * 100 / Number(NAPB));
        }
        if (name === 'Balance') {
            console.log('Balance')
            console.log(value)
            setBalanceLimit(Number(value) - Number(AmountC))
            setBalanceMarket(Number(value) - Number(AmountB))

        }
    }


    return (
        <>
            <div className="m-4 text-left text-4xl">
                <div className='d-flex align-center text-center '>
                    <Title level={4} className='text-[#e7b30a]'>Calculator</Title>
                </div>


                <Form onFinish={handleSubmit} className="">
                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={8}>
                            <Card className="rounded-lg  text-center text-[#000]  shadow-xl mt-2 ">
                                <Row>
                                    <Col xs={24} >
                                        <div className="m-4 text-center">

                                            {Number(CurrentPrice.c) > Number(Prevprice) ? <span className="text-success text-lg font-bold">{Number(CurrentPrice.c)} <ArrowUpOutlined /></span> : Number(CurrentPrice.c) < Number(Prevprice) ? <span className="text-danger text-lg font-bold">{Number(CurrentPrice.c)} <ArrowDownOutlined /></span> : <span className="text-lg font-bold">{Number(CurrentPrice.c) ? Number(CurrentPrice.c) : 0}</span>}

                                            <p className="text-sm text-green-500 font-bold">{CurrentPrice && CurrentPrice.p ? `${Number(CurrentPrice.p).toFixed(2)}` : 0}({CurrentPrice && CurrentPrice.P ? `${Number(CurrentPrice.P).toFixed(2)}%` : '0%'})</p>

                                        </div>
                                        <FormItem name="coin" label="Select Coin:" className="w-full">
                                            <Select
                                                onChange={handleChange}
                                                showSearch
                                                onSearch={onSearch}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "")
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                }
                                                className="p-1 rounded-md mb-0 w-full"
                                                id="option"
                                                name="option"
                                                required
                                                options={optionscoins}
                                                defaultValue="BTCUSDT"
                                            ></Select>
                                        </FormItem>
                                        <FormItem
                                            name="Balance"
                                            label="AVG Balance"
                                            className="m-0"
                                            onChange={handlechangefitstcalc}
                                        >
                                            <p hidden>{Balance}</p>
                                            <InputNumber className="w-full mt-3 " value={Balance} name="Balance" type="number" placeholder="Balance" />
                                        </FormItem>
                                        <FormItem
                                            name="price"
                                            label="AVG Price"
                                            className="m-0"
                                            onChange={handlechangefitstcalc}
                                        >
                                            <p hidden>{Price}</p>
                                            <InputNumber className="w-full mt-3 " value={Price} name="price" type="number" placeholder="Price" />
                                        </FormItem>
                                        <FormItem
                                            name="quantity"
                                            label="Available Quantity"
                                            className="m-0 "
                                            onChange={handlechangefitstcalc}

                                        >
                                            <p hidden>{Quantity}</p>
                                            <InputNumber className="w-full mt-3 " value={Quantity} name="quantity" type="number" placeholder="Quantity" />
                                        </FormItem>



                                        {/* </div> */}
                                        <FormItem
                                            name="amount"
                                            label="Amount ($)"
                                            className="m-0 "
                                            onChange={handlechangefitstcalc}
                                            defaultValue={Amount}

                                        >
                                            <p hidden>{Amount}</p>
                                            <InputNumber className="w-full mt-3" value={Amount} name="amount" type="number" placeholder="amount" />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col xs={24} md={12} lg={12}>
                            <Card className="rounded-lg text-center text-[#000]  shadow-xl mt-2 ">
                                <div className='text-center text-[#e7b30a]'>
                                    <Title level={5}>New AVG</Title>
                                </div>
                                <Row>

                                    <Col lg={12} >
                                        <p className='font-bold'>Market</p>
                                        <div className="m-3">
                                            <FormItem
                                                name="PriceB"
                                                label="Price"
                                                className="m-0"
                                                onChange={handlechangefitstcalc}
                                            >
                                                <p hidden>{PriceB}</p>
                                                <div className='flex mr-2'>
                                                    <InputNumber className="w-full mt-3 inline-block" value={PriceB} name="PriceB" type="number" placeholder="Price" />
                                                </div>
                                            </FormItem>

                                            <FormItem
                                                name="quantityb"
                                                label="Quantity"
                                                className="m-0"
                                                onChange={handlechangefitstcalc}
                                            >
                                                <p hidden>{QuantityB}</p>
                                                <div className='flex mr-2'>
                                                    <InputNumber className="w-full mt-3 inline-block" value={QuantityB} name="quantityb" type="number" placeholder="Quantity" />
                                                </div>
                                            </FormItem>

                                            <FormItem
                                                name="amountb"
                                                label="Amount ($)"
                                                className="m-0"
                                                onChange={handlechangefitstcalc}
                                                defaultValue={AmountB}
                                            >
                                                <p hidden>{AmountB}</p>
                                                <InputNumber className="w-full mt-3" value={AmountB} name="amountb" type="number" placeholder="amount" />
                                            </FormItem>

                                            <FormItem
                                                name="NAP"
                                                label="NAP"
                                                className="m-0"
                                                onChange={handlechangefitstcalc}
                                                defaultValue={NAP}
                                            >
                                                <p hidden>{NAP}</p>
                                                <InputNumber className="w-full mt-3" value={NAP} name="NAP" type="number" placeholder="NAP" />
                                            </FormItem>
                                        </div>
                                        <p>Balance Market:{BalanceMarket}</p>


                                    </Col>
                                    <Col lg={12} >
                                        <p className='font-bold'>Limit</p>
                                        <div className="m-3">
                                            <FormItem
                                                name="PriceC"
                                                label="Price"
                                                className="m-0"
                                                onChange={handlechangefitstcalc}
                                            >
                                                <p hidden>{PriceC}</p>
                                                <div className='flex mr-2'>
                                                    <InputNumber className="w-full mt-3 inline-block" value={PriceC} name="PriceC" type="number" placeholder="Price" />
                                                </div>
                                            </FormItem>
                                            <FormItem
                                                name="QuantityC"
                                                label="Quantity"
                                                className="m-0"
                                                onChange={handlechangefitstcalc}
                                            >
                                                <p hidden>{QuantityC}</p>
                                                <div className='flex mr-2'>
                                                    <InputNumber className="w-full mt-3 inline-block" value={QuantityC} name="QuantityC" type="number" placeholder="Quantity" />
                                                </div>
                                            </FormItem>
                                            <FormItem
                                                name="AmountC"
                                                label="Amount ($)"
                                                className="m-0"
                                                onChange={handlechangefitstcalc}
                                                defaultValue={AmountC}
                                            >
                                                <p hidden>{AmountC}</p>
                                                <InputNumber className="w-full mt-3" value={AmountC} name="AmountC" type="number" placeholder="amount" />
                                            </FormItem>

                                            <FormItem
                                                name="NAPB"
                                                label="NAPB"
                                                className="m-0"
                                                onChange={handlechangefitstcalc}
                                                defaultValue={NAPB}
                                            >
                                                <p hidden>{NAPB}</p>
                                                <InputNumber className="w-full mt-3" value={NAPB} name="NAPB" type="number" placeholder="NAP" />
                                            </FormItem>
                                        </div>

                                        <p>Balance Limit:{BalanceLimit}</p>

                                    </Col>
                                    <Col lg={24}>
                                        <hr></hr>
                                        <p className='text-center font-bold p-2'>P&L Price</p>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="m-3">
                                            <FormItem
                                                name="PnL"
                                                label="PnL"
                                                className="m-0"
                                                onChange={handlechangefitstcalc}
                                                defaultValue={PnL}
                                            >
                                                <p hidden>{PnL}</p>
                                                <InputNumber className="w-full mt-3" value={PnL} name="PnL" type="number" placeholder="PnL" />
                                            </FormItem>
                                            <FormItem
                                                name="pricePnL"
                                                label="PricePnL"
                                                className="m-0"
                                                onChange={handlechangefitstcalc}
                                                defaultValue={pricePnL}
                                            >
                                                <p hidden>{pricePnL}</p>
                                                <InputNumber className="w-full mt-3" value={pricePnL} name="pricePnL" type="number" placeholder="pricePnL" />
                                            </FormItem>
                                        </div>

                                    </Col>
                                    <Col lg={12} >
                                        <div className="m-3">
                                            <FormItem
                                                name="PnLC"
                                                label="PnLC"
                                                className="m-0"
                                                onChange={handlechangefitstcalc}
                                                defaultValue={PnLC}
                                            >
                                                <p hidden>{PnLC}</p>
                                                <InputNumber className="w-full mt-3" value={PnLC} name="PnLC" type="number" placeholder="PnLC" />
                                            </FormItem>
                                            <FormItem
                                                name="pricePnLC"
                                                label="pricePnLC"
                                                className="m-0"
                                                onChange={handlechangefitstcalc}
                                                defaultValue={pricePnLC}
                                            >
                                                <p hidden>{pricePnLC}</p>
                                                <InputNumber className="w-full mt-3" value={pricePnLC} name="pricePnLC" type="number" placeholder="pricePnLC" />
                                            </FormItem>
                                        </div>
                                    </Col>

                                </Row>
                                <Row>


                                </Row>

                            </Card>
                        </Col>
                        {/* <Col xs={24} md={12} lg={8}>
                            <Card className="rounded-lg text-center text-[#000]  shadow-xl mt-2 ">
                                <div className='text-center text-[#e7b30a]'>
                                    <Title level={5}>P&L</Title>
                                </div>
                                <Col xs={24} md={12} lg={8}>

                                  
                                </Col>
                                <Col xs={24} md={12} lg={8}>

                                  
                                </Col>
                            </Card>
                        </Col> */}
                    </Row>

                </Form>

                {/* <Chart /> */}

            </div>




        </>
    )
}

export default Calculatornew
