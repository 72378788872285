import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "antd";
import logo from "../../src/img/binanceLogo.jpg";
import ReactApexChart from "react-apexcharts";
import { Doughnut } from "react-chartjs-2";
import "../assets/report.css";
import moment from "moment/moment";


function ReportFuture() {
    const [reportdata, setreportdata] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [moadlData, setModalData] = useState();
    const [shouldRenderChart, setShouldRenderChart] = useState(false);
    const [ModalOpen, setModalOpen] = useState(false);
    const [ModalOpenthree, setModalOpenthree] = useState(false);
    const [profitData, setProfitData] = useState([]);
    const [activtabledata, setactivtabledata] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selectCoin, setSelectCoin] = useState("");
    const [showLong, setShowLong] = useState(false);
    const [socket, setSocket] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' for ascending, 'desc' for descending
    const [sortedReportData, setSortedReportData] = useState(reportdata);
    const [modalApexOption, setMoadAPexOption] = useState({
        chart: {
            type: "bar",
            height: 440,
            stacked: true,
            animations: {
                enabled: true,
                easing: "easeinout",
                speed: 2000,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
        },
        colors: ["#008FFB", "#FF4560"],
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: "80%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 1,
            colors: ["#fff"],
        },
        grid: {
            xaxis: {
                lines: {
                    show: false,
                },
            },
        },
        yaxis: {
            min: -100,
            max: 100,
            title: {
                // text: 'Age',
            },
        },
        tooltip: {
            shared: false,
            x: {
                formatter: function (val) {
                    return val;
                },
            },
            y: {
                formatter: function (val) {
                    return Math.abs(val) + "%";
                },
            },
        },
        xaxis: {
            categories: ["Balance", "Position", "Profit", "Brokerage"],

            labels: {
                formatter: function (val) {
                },
            },
        },
    });
    const getallroleTwo = (e) => {
        let username = localStorage.getItem("username");
        let postData = { username: username };
        axios
            .post(process.env.REACT_APP_API_PATH_BOT + "bot_list", postData)
            .then((res) => {
                setactivtabledata(res.data.Data);

            })
            .catch((err) => {
                alert(err.response.data);
            });
    };
    const showModalTwo = (id) => {
        setModalOpen(true);
        const filterData = activtabledata.filter((d) => d.VarBotID.includes(id))

        setFilterData(filterData)
    };

    const showModalThree = (id) => {
        setModalOpenthree(true);
        const filterData = activtabledata.filter((d) => d.VarBotID.includes(id))
        setFilterData(filterData)
    };
    const handleOkTwo = () => {
        setModalOpen(false);
    };

    const handleCancelTwo = () => {
        setModalOpen(false);
    };

    const handleOkThree = () => {
        setModalOpenthree(false);
    };

    const handleCancelThree = () => {
        setModalOpenthree(false);
    };
    const showModal = (item) => {
        setModalData(item);

        setIsModalOpen(true);
        const socket = new WebSocket(`wss://fstream.binance.com/ws/${item?.Coin?.toLowerCase()}@trade`);
        const handleSocketMessage = (event) => {
            const tradeData = JSON.parse(event.data);
            setProfitData(tradeData)
            socket.close();
        };
        socket.onmessage = handleSocketMessage;
        return () => {
            socket.removeEventListener('message', handleSocketMessage);
            socket.close();
        };
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const getallrole = (e) => {
        let username = localStorage.getItem("username");
        let postData = { username: username };
        axios
            .post(process.env.REACT_APP_API_PATH_BOT + "report", postData)
            .then((res) => {
                const mainresponse = res.data.Data
                const binanceSpotRecords = mainresponse.filter(record => record.Exchange === "BINANCEFUTURES");

                setreportdata(binanceSpotRecords);
                const sortedData = [...binanceSpotRecords]; // Make a copy of the original data to avoid mutating it
                sortedData.sort((a, b) => {
                    const profitA = calculatePROFIT(a);
                    const profitB = calculatePROFIT(b);
                    return profitB - profitA;
                });
                setSortedReportData(sortedData);
            })
            .catch((err) => {
                alert(err.response.data);
            });
    };
    const colors = [];
    const options = {
        chart: {
            type: "bar",
            height: 440,
            stacked: true,
            animations: {
                enabled: true,
                easing: "easeinout",
                speed: 2000,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                    "<div class='custom-tooltip' style='font-size: 10px;'>" +
                    ["A", "B", "C", "D", "E"][dataPointIndex] +
                    "</div>"
                );
            },
            style: {
                fontSize: "11px",
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: "80%",
                colors: {
                    ranges: colors,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 1,
            colors: ["#fff"],
        },

        grid: {
            xaxis: {
                lines: {
                    show: false,
                },
            },
        },

        yaxis: {
            min: -100,
            max: 100,

            labels: {
                show: false, // Set this to false to hide y-axis labels
                formatter: function (val) {

                },
            },
        },
        tooltip: {
            shared: false,
            x: {
                formatter: function (val) {
                    return val;
                },
            },
            y: {
                formatter: function (val) {
                    return Math.abs(val).toFixed(2) + "%";
                },
            },
        },

        xaxis: {
            categories: ["", "", "", ""],

            labels: {
                show: false,
                formatter: function (val) {
                },
            },
        },
    };

    const donutOptions = {
        plugins: {
            tooltip: {
                enabled: true, // Show tooltip on hover
                callbacks: {
                    label: (context) => {
                        const value = context.parsed || 0;
                        return `${value}`;
                    },
                },
            },
            legend: {
                display: false, // Hide legend
            },
        },
        animation: {
            duration: 2000, // Animation duration in milliseconds
            easing: "easeInOutQuad", // Animation easing function
        },
    };

    const ClosPrftLong =
        ((profitData?.p - filterData[0]?.AverageBuyL) *
            filterData[0]?.TotalQuantityL)?.toFixed(2);

    const ClosPrftShort =
        ((filterData[0]?.AverageBuyS - profitData?.p) *
            filterData[0]?.TotalQuantityS)?.toFixed(2);

    const CloPrftPrsentL =
        (ClosPrftLong * 100 / (filterData[0]?.AverageBuyL * filterData[0]?.TotalQuantityL))?.toFixed(2)

    const CloPrftPrsentS =
        (ClosPrftShort * 100 / (filterData[0]?.AverageBuyS * filterData[0]?.TotalQuantityS))?.toFixed(2)

    const PnLL = (((
        (
            (profitData?.p * filterData[0]?.TotalQuantityL) /
            filterData[0]?.Leverage)
            ?.toFixed(2))
        -
        (
            ((filterData[0]?.AverageBuyL *
                filterData[0]?.TotalQuantityL) /
                filterData[0]?.Leverage)
                ?.toFixed(2))).toFixed(2))

    const PnLS = ((((filterData[0]?.AverageBuyS *
        filterData[0]?.TotalQuantityS) /
        filterData[0]?.Leverage)?.toFixed(2))
        -
        (((profitData?.p * filterData[0]?.TotalQuantityS) /
            filterData[0]?.Leverage)?.toFixed(2))).toFixed(2)

    // ****************************rani****************

    const chartdatainsidemodal = [
        {
            name: "",
            data: [
                moadlData?.BalanceL > 0
                    ? (
                        (moadlData?.BalanceL /
                            moadlData?.InvestedL) *
                        100
                    ).toFixed(2)
                    : 0,
                moadlData?.InvestedL +
                    moadlData?.TotalProfitL -
                    moadlData?.BalanceL >
                    0
                    ? (((moadlData?.InvestedL +
                        moadlData?.TotalProfitL -
                        moadlData?.BalanceL) /
                        moadlData?.InvestedL) *
                        100
                    ).toFixed(2)
                    : 0,
                moadlData?.TotalProfitL > 0
                    ? (
                        (moadlData?.TotalProfitL /
                            moadlData?.InvestedL) *
                        100
                    ).toFixed(2)
                    : 0,
                moadlData?.TotalBrokerageL > 0
                    ? (
                        (moadlData?.TotalBrokerageL /
                            moadlData?.InvestedL) *
                        100
                    ).toFixed(2)
                    : 0,
            ],
        },
        {
            name: "",
            data: [
                moadlData?.BalanceL < 0
                    ? -(
                        (moadlData?.BalanceL /
                            moadlData?.InvestedL) *
                        100
                    ).toFixed(2)
                    : 0,
                moadlData?.InvestedL < 0
                    ? -(
                        (moadlData?.InvestedL /
                            moadlData?.InvestedL) *
                        100
                    ).toFixed(2)
                    : 0,
                moadlData?.TotalProfitL < 0
                    ? -(
                        (moadlData?.TotalProfitL /
                            moadlData?.InvestedL) *
                        100
                    ).toFixed(2)
                    : 0,
                moadlData?.TotalBrokerageL < 0
                    ? -(
                        (moadlData?.TotalBrokerageL /
                            moadlData?.InvestedL) *
                        100
                    ).toFixed(2)
                    : 0,
            ],
        },
    ]
    const ReactApexmodaldata =
        [
            {
                name: "",
                data: [
                    moadlData?.BalanceS > 0
                        ? (
                            (moadlData?.BalanceS /
                                moadlData?.InvestedS) *
                            100
                        ).toFixed(2)
                        : 0,
                    moadlData?.InvestedS +
                        moadlData?.TotalProfitS -
                        moadlData?.BalanceS >
                        0
                        ? (
                            ((moadlData?.InvestedS +
                                moadlData?.TotalProfitS -
                                moadlData?.BalanceS) /
                                moadlData?.InvestedS) *
                            100
                        ).toFixed(2)
                        : 0,

                    moadlData?.TotalProfitS > 0
                        ? (
                            (moadlData?.TotalProfitS /
                                moadlData?.InvestedS) *
                            100
                        ).toFixed(2)
                        : 0,

                    moadlData?.TotalBrokerageS > 0
                        ? (
                            (moadlData?.TotalBrokerageS /
                                moadlData?.InvestedS) *
                            100
                        ).toFixed(2)
                        : 0,
                ],
            },
            {
                name: "",
                data: [
                    moadlData?.BalanceS < 0
                        ? - (
                            (moadlData?.BalanceS /
                                moadlData?.InvestedS) *
                            100
                        ).toFixed(2)
                        : 0,
                    moadlData?.InvestedS < 0
                        ? -(
                            (moadlData?.InvestedS /
                                moadlData?.InvestedS) *
                            100
                        ).toFixed(2)
                        : 0,
                    moadlData?.TotalProfitS < 0
                        ? -(
                            (moadlData?.TotalProfitS /
                                moadlData?.InvestedS) *
                            100
                        ).toFixed(2)
                        : 0,
                    moadlData?.TotalBrokerageS < 0
                        ? -(
                            (moadlData?.TotalBrokerageS /
                                moadlData?.InvestedS) *
                            100
                        ).toFixed(2)
                        : 0,
                ],
            },
        ]
    function calculateChartSeries(item) {
        var balance = item.BalanceL + item.BalanceS
        var Invested = item.InvestedL + item.InvestedS
        var TotalProfit = item.TotalProfitL + item.TotalProfitS
        var TotalBrokerage = item.TotalBrokerageL + item.TotalBrokerageS
        const series =
            [
                {
                    name: "",
                    data: [
                        balance > 0 ? ((balance / Invested) * 100).toFixed(2) :
                            0,
                        Invested + (TotalProfit) - (balance) > 0 ? (((Invested + TotalProfit - balance) / Invested) * 100).toFixed(2)
                            : 0,
                        TotalProfit > 0 ? ((TotalProfit / Invested) * 100).toFixed(2)
                            : 0,
                        TotalBrokerage > 0 ? ((TotalBrokerage / Invested) * 100).toFixed(2)
                            : 0,
                    ],
                },
                {
                    name: "",
                    data: [
                        balance < 0 ? -((balance / Invested) * 100).toFixed(2) : 0,
                        Invested < 0 ? -((Invested / Invested) * 100).toFixed(2) : 0,
                        TotalProfit < 0
                            ? -((TotalProfit / Invested) * 100).toFixed(2)
                            : 0, TotalBrokerage < 0 ? -((TotalBrokerage / Invested) * 100).toFixed(2) : 0,
                    ],
                },
            ]
        return series;
        // const series =
        //     [
        //         {
        //             name: "",
        //             data: [
        //                 item.BalanceL + item.BalanceS > 0
        //                     ? (
        //                         ((item.BalanceL + item.BalanceS) /
        //                             (item.InvestedL + item.InvestedS)) *
        //                         100
        //                     ).toFixed(2)
        //                     : 0,
        //                 item.InvestedL +
        //                     item.InvestedS +
        //                     (item.TotalProfitL + item.TotalProfitS) -
        //                     (item.BalanceL + item.BalanceS) >
        //                     0
        //                     ? (
        //                         ((item.InvestedL +
        //                             item.InvestedS +
        //                             (item.TotalProfitL +
        //                                 item.TotalProfitS) -
        //                             (item.BalanceL + item.BalanceS)) /
        //                             (item.InvestedL + item.InvestedS)) *
        //                         100
        //                     ).toFixed(2)
        //                     : 0,
        //                 item.TotalProfitL + item.TotalProfitS > 0
        //                     ? (
        //                         ((item.TotalProfitL +
        //                             item.TotalProfitS) /
        //                             (item.InvestedL + item.InvestedS)) *
        //                         100
        //                     ).toFixed(2)
        //                     : 0,
        //                 item.TotalBrokerageL +
        //                     item.TotalBrokerageS >
        //                     0
        //                     ? (
        //                         ((item.TotalBrokerageL +
        //                             item.TotalBrokerageS) /
        //                             (item.InvestedL + item.InvestedS)) *
        //                         100
        //                     ).toFixed(2)
        //                     : 0,
        //             ],
        //         },
        //         {
        //             name: "",
        //             data: [
        //                 item.BalanceL + item.BalanceS < 0
        //                     ? -(
        //                         ((item.BalanceL + item.BalanceS) /
        //                             (item.InvestedL + item.InvestedS)) *
        //                         100
        //                     ).toFixed(2)
        //                     : 0,
        //                 item.InvestedL + item.InvestedS < 0
        //                     ? -(
        //                         ((item.InvestedL + item.InvestedS) /
        //                             (item.InvestedL + item.InvestedS)) *
        //                         100
        //                     ).toFixed(2)
        //                     : 0,
        //                 item.TotalProfitL + item.TotalProfitS < 0
        //                     ? -(
        //                         ((item.TotalProfitL +
        //                             item.TotalProfitS) /
        //                             (item.InvestedL + item.InvestedS)) *
        //                         100
        //                     ).toFixed(2)
        //                     : 0,
        //                 item.TotalBrokerageL +
        //                     item.TotalBrokerageS <
        //                     0
        //                     ? -(
        //                         ((item.TotalBrokerageL +
        //                             item.TotalBrokerageS) /
        //                             (item.InvestedL + item.InvestedS)) *
        //                         100
        //                     ).toFixed(2)
        //                     : 0,
        //             ],
        //         },
        //     ]
        // return series;
    }
    function calculatePROFIT(item) {
        return (
            ((item.TotalProfitL + item.TotalProfitS) /
                (item.InvestedL + item.InvestedS)) *
            100
        ).toFixed(2);
    }
    function calculatetotaltrade(item) {
        return (
            item.WinningTradesL +
            item.WinningTradesS +
            item.LosingTradesL +
            item.LosingTradesS
        ).toFixed(0);
    }
    function calculatewinningtrade(item) {

        return (
            item.WinningTradesL + item.WinningTradesS
        ).toFixed(0);
    }
    function calculateHitRate(item) {
        return (
            (item.WinningTradesL + item.WinningTradesS) /
            (item.WinningTradesL + item.WinningTradesS + item.LosingTradesL + item.LosingTradesS)
        ).toFixed(0);
    }
    const sortReportData = (order) => {
        const sortedData = [...reportdata]; // Make a copy of the original data to avoid mutating it
        sortedData.sort((a, b) => {
            const profitA = calculatePROFIT(a);
            const profitB = calculatePROFIT(b);
            return order === 'asc' ? profitA - profitB : profitB - profitA;
        });
        setSortedReportData(sortedData);
    };
    useEffect(() => {
        sortReportData('desc');
        getallrole();
        getallroleTwo();
    }, []);
    useEffect(() => {
        if (moadlData) {
            const OptionData = {
                ...modalApexOption,
            };
            setMoadAPexOption(OptionData);
            setShouldRenderChart(true); // Add this line
        }
    }, [moadlData]);

    // **********************************************************************************************
    return (
        <>
            <div className="">
                <div className="">
                    <h2 className="text-second text-center">Category</h2>
                    <div className="flex items-center justify-center gap-3 pt-2">
                        <button
                            className="p-1 px-2 border-dashed border rounded-md border-second text-[#fff]"
                            onClick={() => sortReportData('asc')}
                        >
                            Low to High
                        </button>
                        <button
                            className="p-1 px-2 border-dashed border rounded-md border-second text-[#fff]"
                            onClick={() => sortReportData('desc')}
                        >
                            High to Low
                        </button>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3  mx-2 my-0 py-3">
                    {sortedReportData && sortedReportData?.map((item) => (
                        <div className="">
                            <div className="bg-[#e2b200] rounded-lg">
                                <div className="mx-2">
                                    <div className="pt-2">
                                        <div className="flex justify-center gap-10 bg-[#191320] text-[#fff] py-[10px]  rounded-[10px]">
                                            <div className="flex flex-col justify-around w-1/2">
                                                <h1 className=" text-md font-semibold text-center">
                                                    API : {item.API_Name}
                                                </h1>
                                                <div className="flex px-2 gap-2  justify-between  w-full items-center">
                                                    <p className=" text-xs xl:text-base">
                                                        {item.VarBotID}{" "}
                                                    </p>
                                                    <p className="text-xs xl:text-base">{item.Symbol}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <img
                                                    src={logo}
                                                    alt="logo"
                                                    className="w-[150px] h-auto"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-around bg-[#fff] shadow-md text-[#212121] items-center rounded-[10px] py-[10px] my-2">
                                        <div>
                                            <p className=" text-lg flex flex-col ">
                                                {" "}
                                                <span className="text-center  font-medium">
                                                    PROFIT
                                                </span>{" "}
                                                <div>
                                                    <span
                                                        className={`text-center text-3xl font-semibold 
                                                         ${parseFloat(

                                                            calculatePROFIT(item)
                                                        ).toFixed(2)
                                                                < 0
                                                                ? "text-[#f00]"
                                                                : "text-[#38913a]"
                                                            }`}
                                                    >
                                                        {calculatePROFIT(item)}
                                                        %
                                                    </span>
                                                </div>
                                            </p>
                                        </div>
                                        <div className="w-1/5">
                                            <p className="text-center font-bold text-base">Trades</p>
                                            <p className="text-xs leading-3 flex items-center justify-between ">
                                                <div className="text-center">Total: </div>
                                                <div className=" font-bold text-xs text-center">
                                                    {calculatetotaltrade(item)!== ""?calculatetotaltrade(item):"0.00"}
                                                </div>
                                            </p>
                                            <p className="text-xs leading-3 text-center flex justify-between items-center">
                                                Winning:{" "}
                                                <div className=" font-bold text-xs text-center">
                                                    {calculatewinningtrade(item)}
                                                </div>
                                            </p>

                                            <p className="text-xs leading-3 text-center  flex justify-between items-center">
                                                Losing
                                                <div className=" font-bold text-xs text-center">
                                                    {item.LosingTradesL + item.LosingTradesS}
                                                </div>
                                            </p>
                                            <p className="text-xs leading-3 text-center  flex justify-between items-center">
                                                Hit rate:{" "}
                                                <div className=" font-bold text-xs text-c">
                                                    {calculateHitRate(item)}
                                                </div>
                                            </p>
                                        </div>
                                        <div className="w-[50] h-[50] flex justify-center">
                                            <Doughnut
                                                data={{
                                                    labels: ["WinningTrades", "LoosingTrades"],
                                                    datasets: [
                                                        {
                                                            data:
                                                                [
                                                                    item.WinningTradesL + item.WinningTradesS,
                                                                    item.LosingTradesL + item.LosingTradesS,
                                                                ],
                                                            backgroundColor: [
                                                                "rgba(14, 220, 83, 0.6)",
                                                                "rgba(255, 99, 132, 0.6)",
                                                            ],
                                                            borderColor: [
                                                                "rgba(14, 220, 83, 0.6)",
                                                                "rgba(255, 99, 132, 0.6)",
                                                            ],
                                                            borderWidth: 1,
                                                        },
                                                    ],
                                                }}
                                                options={donutOptions}
                                                style={{ width: " 75px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 bg-[#fff] text-[#000] shadow-lg rounded-md px-1 my-2">
                                        <div className=" w-full ">
                                            <div className="-mb-4 text-left text-sm text-[#000]">
                                                Bot Alloted Fund	<b>${item.InvestedL + item.InvestedS}</b>
                                            </div>
                                            <div className="flex justify-center gap-2 items-center ">
                                                <div className=" w-[40%]">
                                                    <div className="-ml-5 -mt-2">
                                                        <ReactApexChart
                                                            options={options}
                                                            series={calculateChartSeries(item)} // Use the calculated series
                                                            type="bar"
                                                            height={117}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-[13px]  flex gap-1 justify-between items-center  flex-col w-2/5">
                                                    <p className="-mt-1 flex gap-5 justify-between w-full">
                                                        <div className="text-xs">Balance:</div>{" "}
                                                        <span className=" font-bold">
                                                            ${((item.BalanceL || 0) + (item.BalanceS || 0)).toFixed(2)}
                                                        </span>
                                                    </p>
                                                    <p className="-mt-1 flex gap-5 justify-between w-full">
                                                        <div className="text-xs">Position:</div>{" "}
                                                        <span className=" font-bold">
                                                            $
                                                            {(
                                                                item.InvestedL +
                                                                item.InvestedS +
                                                                -
                                                                ((item.BalanceL || 0) + (item.BalanceS || 0))
                                                            ).toFixed(2)}
                                                        </span>
                                                    </p>
                                                    <p className="-mt-1 flex gap-5 justify-between w-full">
                                                        <div className="text-xs">Profit:</div>{" "}
                                                        <span className=" font-bold">
                                                            $
                                                            {((item.TotalProfitL || 0) + (item.TotalProfitS || 0)).toFixed(
                                                                2
                                                            )}
                                                        </span>
                                                    </p>
                                                    <p className="-mt-1 flex gap-5 justify-between w-full">
                                                        <div className="text-xs">Brokerage:</div>{" "}
                                                        <span className=" font-bold">
                                                            $
                                                            {(
                                                                (item.TotalBrokerageL || 0) + (item.TotalBrokerageS || 0)
                                                            ).toFixed(2)}
                                                        </span>
                                                    </p>
                                                    <p className="-mt-1 flex gap-5 justify-between w-full">
                                                        <div className="text-xs"> No of Days:</div>
                                                        <span className=" font-bold">
                                                        {
                                                            reportdata
                                                            .map((d) => {
                                                                if (d?.StartDate) {
                                                                const startDateObj = moment(d?.StartDate, 'DD-MM-YYYY');
                                                                const today = moment();
                                                                const daysDifference = today.diff(startDateObj, 'day');
                                                                return (daysDifference);
                                                                }
                                                                return null;
                                                            })
                                                            .slice(0, 1)
                                                        }
                                                        </span>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        {/* <button
                                            className=" bg-wht hover:bg-[#191320] transition-all transition-500 w-20 my-2 px-2 py-1 rounded-md text-second "
                                            onClick={() => showModal(item)}
                                        >
                                            More
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <Modal
                    title={
                        <div className="flex items-center justify-between mx-[20px] bg-[#14161f] p-4 rounded-md">
                            <div className=" flex items-center gap-2">
                                <p className="text-[#fff] ">
                                    {moadlData?.Coin}
                                </p>
                            </div>
                            <div>
                                <h1 className=" text-[25px] text-[#fff]">
                                    API Name: {moadlData?.API_Name}
                                </h1>
                                <p className=" text-[14px] text-[#fff]">
                                    Bot ID: {moadlData?.VarBotID}
                                </p>
                            </div>
                            <div>
                                <img src={logo} alt="" className="w-[150px] text-[#fff]" />
                            </div>
                        </div>
                    }
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className="reportModal "
                >
                    <div className=" grid grid-cols-1  md:grid-cols-2 gap-3">
                        <div>
                            <div className="mx-3">
                                <div className="pt-3">
                                    <div className="flex justify-center gap-10 bg-[#191320] text-[#fff] py-[10px]  rounded-[10px]">
                                        <div>
                                            <p className="text-[20px]">Long</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-10  bg-[#fff] shadow-lg rounded-md my-2">
                                    <div className=" w-1/2 ">
                                        {shouldRenderChart ? (
                                            <ReactApexChart
                                                options={modalApexOption}
                                                series={chartdatainsidemodal}
                                                type="bar"
                                                height={150}
                                            />
                                        ) : null}
                                    </div>
                                    <div className="text-[13px]  flex flex-col justify-center  w-1/3">
                                        <p className="flex justify-between text-base">
                                            {" "}
                                            <span>Bot Alloted Fund	</span>{" "}
                                            <span className=" font-bold">
                                                {" "}
                                                ${moadlData?.InvestedL?.toFixed(2)}
                                            </span>{" "}
                                        </p>
                                        <p className="flex justify-between text-base">
                                            <span>Balance:</span>{" "}
                                            <span className=" font-bold">
                                                ${moadlData?.BalanceL?.toFixed(2)}
                                            </span>
                                        </p>
                                        <p className="flex justify-between text-base">
                                            <span>Position:</span>{" "}
                                            <span className=" font-bold">
                                                $
                                                {(
                                                    moadlData?.InvestedL
                                                     -
                                                    moadlData?.BalanceL
                                                )?.toFixed(2)}
                                            </span>
                                        </p>
                                        <p className="flex justify-between text-base">
                                            <span>Profit:</span>{" "}
                                            <span className=" font-bold">
                                                ${moadlData?.TotalProfitL?.toFixed(2)}
                                            </span>
                                        </p>
                                        <p className="flex justify-between text-base">
                                            <span>Brokerage:</span>{" "}
                                            <span className=" font-bold">
                                                ${moadlData?.TotalBrokerageL?.toFixed(2)}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-around bg-[#fff] shadow-md text-[#212121] items-center rounded-[10px] py-[10px] ">
                                    <div>
                                        <p className=" text-2xl flex flex-col">
                                            {" "}
                                            <span className="text-center underline font-medium">
                                                PROFIT
                                            </span>{" "}
                                            <div>
                                                <span
                                                    className={`text-center text-5xl font-semibold leading-9 ${parseFloat(
                                                        (moadlData?.TotalProfitL / moadlData?.InvestedL) *
                                                        100
                                                    )?.toFixed(2) < 0
                                                        ? "text-[#f00]"
                                                        : "text-[#38913a]"
                                                        }`}
                                                >
                                                    {parseFloat(
                                                        (moadlData?.TotalProfitL / moadlData?.InvestedL) *
                                                        100
                                                    )?.toFixed(2)}
                                                    %
                                                </span>
                                            </div>
                                        </p>
                                    </div>
                                    <div>
                                        <div className="flex items-center gap-12">
                                            <div>
                                                <p className="text-sm my-1 font-semibold">TRADES</p>
                                                <p className="text-sm ">
                                                    <span>Total: </span>
                                                    {moadlData?.WinningTradesL + moadlData?.LosingTradesL}
                                                </p>
                                                <p className="text-sm ">
                                                    Winning: {moadlData?.WinningTradesL}
                                                </p>
                                                <p className="text-sm ">
                                                    Losing: {moadlData?.LosingTradesL}
                                                </p>
                                                <div>
                                                    <p className="text-base font-semibold">
                                                        Hit rate:{" "}
                                                        {parseFloat(
                                                            moadlData?.WinningTradesL /
                                                            (moadlData?.WinningTradesL +
                                                                moadlData?.LosingTradesL)
                                                        ).toFixed(2)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="w-[125px] h-[125px] flex justify-center">
                                                <Doughnut
                                                    data={{
                                                        labels: ["WinningTrades", "LoosingTrades"],
                                                        datasets: [
                                                            {
                                                                data: [
                                                                    moadlData?.WinningTradesL,
                                                                    moadlData?.LosingTradesL,
                                                                ],
                                                                backgroundColor: [
                                                                    "rgba(14, 220, 83, 0.6)",
                                                                    "rgba(255, 99, 132, 0.6)",
                                                                ],
                                                                borderColor: [
                                                                    "rgba(14, 220, 83, 0.6)",
                                                                    "rgba(255, 99, 132, 0.6)",
                                                                ],
                                                                borderWidth: 1,
                                                            },
                                                        ],
                                                    }}
                                                    options={donutOptions}
                                                    style={{ width: " 200px" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button
                                        className=" bg-second hover:bg-second/75 transition-all transition-500  my-4 px-2 py-1 rounded-md text-wht w-auto "
                                        onClick={() => {
                                            showModalTwo(moadlData?.VarBotID);
                                            setShowLong(true);
                                            setSelectCoin(moadlData?.VarBotID);
                                        }}
                                    >
                                        More Detail
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="mx-3">
                                <div className="pt-3">
                                    <div className="flex justify-center gap-10 bg-[#191320] text-[#fff] py-[10px]  rounded-[10px]">
                                        <div>
                                            <p className="text-[20px]">SHORT</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex gap-10  bg-[#fff] shadow-lg rounded-md my-2">
                                    <div className=" w-1/2 ">
                                        {shouldRenderChart ? (
                                            <ReactApexChart
                                                options={modalApexOption}
                                                series={ReactApexmodaldata}
                                                type="bar"
                                                height={150}
                                            />
                                        ) : null}
                                    </div>
                                    <div className="text-[13px]  flex flex-col justify-center w-1/3">
                                        <p className="flex justify-between  text-base">
                                            {" "}
                                            <span>Bot Alloted Fund	</span>{" "}
                                            <span className="font-bold">
                                                ${moadlData?.InvestedS?.toFixed(2)}
                                            </span>{" "}
                                        </p>
                                        <p className="flex justify-between text-base">
                                            <span>Balance:</span>{" "}
                                            <span className="font-bold">
                                                ${moadlData?.BalanceS?.toFixed(2)}
                                            </span>
                                        </p>
                                        <p className="flex justify-between text-base">
                                            <span>Position:</span>{" "}
                                            <span className="font-bold">
                                                $
                                                {(
                                                    moadlData?.InvestedS  -
                                                    moadlData?.BalanceS
                                                )?.toFixed(2)}
                                            </span>
                                        </p>
                                        <p className="flex justify-between text-base">
                                            <span>Profit:</span>{" "}
                                            <span className="font-bold">
                                                ${moadlData?.TotalProfitS?.toFixed(2)}
                                            </span>
                                        </p>
                                        <p className="flex justify-between text-base">
                                            <span>Brokerage:</span>{" "}
                                            <span className="font-bold">
                                                ${moadlData?.TotalBrokerageS?.toFixed(2)}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-around bg-[#fff] shadow-md text-[#212121] items-center rounded-[10px] py-[10px]">
                                    <div>
                                        <p className=" text-2xl flex flex-col">
                                            {" "}
                                            <span className="text-center underline font-medium">
                                                PROFIT
                                            </span>{" "}
                                            <div>
                                                <span
                                                    className={`text-center text-5xl font-semibold leading-9  ${parseFloat(
                                                        (moadlData?.TotalProfitS / moadlData?.InvestedS) *
                                                        100
                                                    )?.toFixed(2) < 0
                                                        ? "text-[#f00]"
                                                        : "text-[#38913a]"
                                                        }`}
                                                >
                                                    {parseFloat(
                                                        (moadlData?.TotalProfitS / moadlData?.InvestedS) *
                                                        100
                                                    )?.toFixed(2)}
                                                    %
                                                </span>
                                            </div>
                                        </p>
                                    </div>
                                    <div>
                                        <div className="flex items-center gap-12">
                                            <div>
                                                <p className=" text-sm my-1 font-semibold">TRADES</p>
                                                <p className="text-sm ">
                                                    <span>Total: </span>
                                                    {moadlData?.WinningTradesS + moadlData?.LosingTradesS}
                                                </p>
                                                <p className="text-sm ">
                                                    Winning: {moadlData?.WinningTradesS}
                                                </p>
                                                <p className="text-sm">
                                                    Losing: {moadlData?.LosingTradesS}
                                                </p>
                                                <div>
                                                    <p className=" text-base font-semibold">
                                                        Hit rate:{" "}
                                                        {parseFloat(
                                                            moadlData?.WinningTradesS /
                                                            (moadlData?.WinningTradesS +
                                                                moadlData?.LosingTradesS)
                                                        ).toFixed(2)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="w-[125px] h-[125px] flex justify-center">
                                                <Doughnut
                                                    data={{
                                                        labels: ["WinningTrades", "LoosingTrades"],
                                                        datasets: [
                                                            {
                                                                data: [
                                                                    moadlData?.WinningTradesS,
                                                                    moadlData?.LosingTradesS,
                                                                ],
                                                                backgroundColor: [
                                                                    "rgba(14, 220, 83, 0.6)",
                                                                    "rgba(255, 99, 132, 0.6)",
                                                                ],
                                                                borderColor: [
                                                                    "rgba(14, 220, 83, 0.6)",
                                                                    "rgba(255, 99, 132, 0.6)",
                                                                ],
                                                                borderWidth: 1,
                                                            },
                                                        ],
                                                    }}
                                                    options={donutOptions}
                                                    style={{ width: " 200px" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button
                                        className=" bg-second hover:bg-second/75 transition-all transition-500 w-auto my-4 px-2 py-1 rounded-md text-wht  "
                                        onClick={() => {
                                            showModalThree(moadlData?.VarBotID);
                                            setSelectCoin(moadlData?.VarBotID);
                                            setShowLong(false);
                                        }}
                                    >
                                        More Detail
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title={<div>{<div> <span className="bg-[#191320] p-2.5 text-[#fff]  border-black rounded-md">Open Positions (Long)</span></div>
                    }</div>}
                    open={ModalOpen}
                    onOk={handleOkTwo}
                    onCancel={handleCancelTwo}
                    className=""
                >
                    <div className="  flex flex-col gap-3">

                        <div className=" grid grid-cols-2 bg-[#d67600]/30 gap-2 py-2 rounded-lg shadow-lg mt-3 ">
                            <div className="text-center">Bot ID : <span className="font-bold">{moadlData?.VarBotID}</span> </div>
                            <div className="text-center">
                                {" "}
                                Leverage : <span className=" font-bold"> {filterData[0]?.Leverage}{" "}</span>
                            </div>
                            <div className="text-center"> COIN : <span className="font-bold">{moadlData?.Coin}</span></div>
                            <div className="text-center">
                                API Name : <span className=" font-bold">  {moadlData?.API_Name}</span>
                            </div>
                        </div>

                        <div className=" grid grid-cols-2 bg-[#d67600]/30 gap-2 py-2 rounded-lg shadow-lg  ">
                            <div className="text-center">
                                Average Buy Price : <span className=" font-bold">
                                    $ {(filterData[0]?.AverageBuyL)?.toFixed(4)}
                                </span>
                            </div>
                            <div className="text-center">
                                Total Qualtity :  <span className=" font-bold">
                                    {(filterData[0]?.TotalQuantityL)?.toFixed(2)}
                                </span>

                            </div>
                            <div className="text-center">
                                Position : <span className=" font-bold">  ${(filterData[0]?.AverageBuyL * filterData[0]?.TotalQuantityL).toFixed(2)}
                                </span>

                            </div>
                            <div className="text-center">
                                {" "}
                                Margin :  <span className=" font-bold">
                                    $ {((filterData[0]?.AverageBuyL *
                                        filterData[0]?.TotalQuantityL) /
                                        filterData[0]?.Leverage)?.toFixed(2)
                                    }
                                </span>

                            </div>
                        </div>
                        <div className="grid grid-cols-2 bg-[#d67600]/30 gap-2 py-2 rounded-lg shadow-lg">
                            <div className="text-center">
                                Current Price : <span className="font-bold"> $ {parseFloat(profitData?.p)?.toFixed(4)}</span>
                            </div>
                            <div className="text-center">
                                Margin : <span className="font-bold">$ {
                                    ((profitData?.p * filterData[0]?.TotalQuantityL) /
                                        filterData[0]?.Leverage)?.toFixed(2)
                                }
                                </span>
                            </div>
                            <div className="text-center">

                                <div className="text-center">
                                    Profit & Loss :
                                    <span className="font-bold" style={{ color: (PnLL * filterData[0]?.Leverage >= 0 ? "green" : "red") }}>
                                        {(PnLL ? PnLL * filterData[0]?.Leverage : "").toFixed(2)}
                                    </span>
                                </div>
                            </div>
                            <div className="text-center">
                                Profit & Loss % :
                                <span className="font-bold">
                                    {CloPrftPrsentL && CloPrftPrsentL !== null ? (
                                        <span style={{ color: `${CloPrftPrsentL < 0 ? "red" : "black"}` }}>{((CloPrftPrsentL) * filterData[0]?.Leverage).toFixed(2)}</span>
                                    ) : (
                                        "loading..."
                                    )
                                    }
                                </span>
                            </div>
                        </div>
                    </div>

                </Modal>
                <Modal
                    title={<div> <span className="bg-[#191320] p-2.5 text-[#fff]  border-black rounded-md">Open Positions (Short)</span> </div>}
                    open={ModalOpenthree}
                    onOk={handleOkThree}
                    onCancel={handleCancelThree}
                    className=""
                >
                    <div className="  flex flex-col gap-3">

                        <div className=" grid grid-cols-2 bg-[#d67600]/30 gap-2 py-2 rounded-lg shadow-lg mt-3 ">
                            <div className="text-center">Bot ID : <span className="font-bold">{moadlData?.VarBotID}</span> </div>
                            <div className="text-center">
                                {" "}
                                Leverage : <span className=" font-bold"> {filterData[0]?.Leverage}{" "}</span>
                            </div>
                            <div className="text-center"> COIN : <span className="font-bold">{moadlData?.Coin}</span></div>
                            <div className="text-center">
                                API Name : <span className=" font-bold">  {moadlData?.API_Name}</span>
                            </div>
                        </div>

                        <div className=" grid grid-cols-2 bg-[#d67600]/30 gap-2 py-2 rounded-lg shadow-lg  ">
                            <div className="text-center">
                                Average Buy Price : <span className=" font-bold">{ }
                                    $ {
                                        (filterData[0]?.AverageBuyS)?.toFixed(4)}
                                </span>
                            </div>
                            <div className="text-center">
                                Total Qualtity :  <span className=" font-bold">
                                    {(filterData[0]?.TotalQuantityS)?.toFixed(2)}
                                </span>

                            </div>
                            <div className="text-center">
                                Position : <span className=" font-bold">  ${(
                                    filterData[0]?.AverageBuyS * filterData[0]?.TotalQuantityS
                                )?.toFixed(2)}</span>
                            </div>
                            <div className="text-center">
                                {" "}
                                Margin :  <span className=" font-bold">
                                    $ {((filterData[0]?.AverageBuyS *
                                        filterData[0]?.TotalQuantityS) /
                                        filterData[0]?.Leverage)?.toFixed(2)}
                                </span>

                            </div>
                        </div>
                        <div className="grid grid-cols-2 bg-[#d67600]/30 gap-2 py-2 rounded-lg shadow-lg">
                            <div className="text-center">
                                Current Price : <span className="font-bold"> $ {parseFloat(profitData?.p)?.toFixed(4)}</span>
                            </div>

                            <div className="text-center">
                                Margin : <span className="font-bold">$ {
                                    ((profitData?.p * filterData[0]?.TotalQuantityS) /
                                        filterData[0]?.Leverage)?.toFixed(2)
                                }
                                </span>
                            </div>
                            <div className="text-center">

                                <div className="text-center">
                                    Profit & Loss :
                                    <span className="font-bold" style={{ color: (PnLS * filterData[0]?.Leverage >= 0 ? "green" : "red") }}>
                                        {(PnLS ? PnLS * filterData[0]?.Leverage : "").toFixed(2)}
                                    </span>
                                </div>
                            </div>

                            <div className="text-center">
                                Profit & Loss % :
                                <span className="font-bold">
                                    {CloPrftPrsentS && CloPrftPrsentS !== null ? (
                                        <span style={{ color: `${CloPrftPrsentS < 0 ? "red" : "black"}` }}>{((CloPrftPrsentS) * filterData[0]?.Leverage).toFixed(2)}</span>
                                    ) : (
                                        "loading..."
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default ReportFuture;
